<template>
  <div
      class="w-full h-full bg-d2d3c overflow-hidden flex flex-col items-center justify-start gap-[4px] tracking-[normal] text-left text-xl text-white-back font-helvetica mq450:h-auto"
  >
    <div class="title-block">
      <div class="title-block-text">
        <h2 class="title-block-text__title">AUTONOMOUS AP</h2>
        <p class="title-block-text__subtitle">AI-first invoice processing, from inbox to ‘OK to pay’
          Streamline invoice processing with AI-powered automatio</p>
        <p class="title-block-text__desc">Streamline invoice processing with AI-powered automation that begins as soon as you receive
          an email. Boost efficiency, enforce compliance, eliminate duplicates, and gain a comprehensive
          view of all your spend.</p>
        <button class="title-block-text__btn">GET A FREE TRIAL<img class="arrow-img" src="/arrow-1.svg" alt=""></button>
      </div>
      <div class="title-block-img">
        <img class="title-block-img__image" src="/img-title-block.jpg" alt="img-title-block" />
      </div>
    </div>

    <div class="mb-[110px] mq750:mb-[0]">
      <div class="tab-item">
          <div class="tab-item__img">
            <img src="/slide-1.jpg" alt="img-item" />
            <a class="tab-item-desc__link hidden mq750:flex" href="#">Learn more about App Inbox<img class="arrow-img" src="/arrow.png" alt="" /></a>
          </div>
          <div class="tab-item-desc">
            <div class="tab-item-desc__main-title">
              XENdeck AP Inbox
            </div>
            <div class="tab-item-desc__title">
              3X your AP team’s productivity with the power of AI
            </div>
            <div class="tab-item-desc__text">
              <ul>
                <li>Focus only on high-priority emails, automatically labeled and categorized for you</li>
                <li>Find key invoice data, due dates, bank account information, and more without ever leaving your email account</li>
                <li>Let generative AI craft detailed, individualized responses to vendors</li>
              </ul>
            </div>
            <a class="tab-item-desc__link mq750:hidden" href="#">Learn more about App Inbox<img class="arrow-img" src="/arrow.png" alt="" /></a>
          </div>
        </div>

      <div class="mq450:hidden flex flex-row items-start justify-start relative max-w-full mt-[90px] mb-[70px]">
        <img
            class="h-[79.5px] w-[1161px] absolute my-0 mx-[!important] left-[-87px] bottom-[-56.5px] object-contain mq750:w-[450px]"
            loading="eager"
            alt=""
            src="/line-19-1@2x.png"
        />
      </div>

      <div class="tab-item tab-item-right">
        <div class="tab-item__img">
          <img src="/annoc-img-2.jpg" alt="img-item" />
          <a class="tab-item-desc__link hidden mq750:flex" href="#">Learn more about App Inbox<img class="arrow-img" src="/arrow.png" alt="" /></a>
        </div>
        <div class="tab-item-desc tab-item-desc-right">
          <div class="tab-item-desc__main-title">
            Accurate invoice data capture
          </div>
          <div class="tab-item-desc__title">
            Improve AP processing efficiency up to 80% with autonomous invoice processing
          </div>
          <div class="tab-item-desc__text">
            <ul>
              <li>Guarantee invoice entry accuracy, automated GL accounting, and PO matching</li>
              <li>Deliver ‘OK to pay’ in less than a day with zero manual touch</li>
              <li>Ensure spend policy compliance and eliminate duplicate invoice payments</li>
            </ul>
          </div>
          <a class="tab-item-desc__link mq750:hidden" href="#">Learn more about App Inbox<img class="arrow-img" src="/arrow.png" alt="" /></a>
        </div>
      </div>

      <div class="mt-[65px] mq450:hidden mq750:mt-[37px] self-stretch flex flex-row items-start justify-start pt-0 pb-[3px] pr-[5px] pl-0 box-border max-w-full shrink-0 text-left text-23xl text-white-back font-helvetica">
        <img class="w-full h-full max-w-full overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
             loading="eager" alt="" src="/line-1@2x.svg"/>
      </div>

      <div class="tab-item">
        <div class="tab-item__img">
          <img src="/slide-1.jpg" alt="img-item" />
          <a class="tab-item-desc__link hidden mq750:flex" href="#">Learn more about App Inbox<img class="arrow-img" src="/arrow.png" alt="" /></a>
        </div>
        <div class="tab-item-desc">
          <div class="tab-item-desc__main-title">
            Multi-line PO matching
          </div>
          <div class="tab-item-desc__title">
            Match complex invoices and PO lines without rules, with 100% confidence
          </div>
          <div class="tab-item-desc__text">
            <ul>
              <li>Accurately match invoices and POs with AI that understands document content and context</li>
              <li>Fully automate complex processes, like combining invoice lines to match POs and reading fuzzy line item descriptions</li>
              <li>Claim back your time – when a PO is out of funds, Autonomous AP confirms matching line items and flags the appropriate buyer</li>
            </ul>
          </div>
          <a class="tab-item-desc__link mq750:hidden" href="#">Learn more about App Inbox<img class="arrow-img" src="/arrow.png" alt="" /></a>
        </div>
      </div>

      <div class="mq450:hidden flex flex-row items-start justify-start relative max-w-full mt-[90px] mb-[70px]">
        <img
            class="h-[79.5px] w-[1161px] absolute my-0 mx-[!important] left-[-87px] bottom-[-56.5px] object-contain mq750:w-[450px]"
            loading="eager"
            alt=""
            src="/line-19-1@2x.png"
        />
      </div>

      <div class="tab-item tab-item-right">
        <div class="tab-item__img">
          <img src="/annoc-img-flex-2.jpg" alt="img-item" />
          <a class="tab-item-desc__link hidden mq750:flex" href="#">Learn more about App Inbox<img class="arrow-img" src="/arrow.png" alt="" /></a>
        </div>
        <div class="tab-item-desc tab-item-desc-right">
          <div class="tab-item-desc__main-title">
            Non-PO-backed invoices
          </div>
          <div class="tab-item-desc__title">
            Let AI assign your GL codes and allocations accurately
          </div>
          <div class="tab-item-desc__text">
            <ul>
              <li>Process non-PO-backed invoices and autonomously assign GL codes in minutes</li>
              <li>Assign entities, business units, cost centers, account codes, and allocations automatically</li>
              <li>Eliminate the need for human review</li>
            </ul>
          </div>
          <a class="tab-item-desc__link mq750:hidden" href="#">Learn more about App Inbox<img class="arrow-img" src="/arrow.png" alt="" /></a>
        </div>
      </div>

      <div class="mt-[65px] mq450:hidden mq750:mt-[37px] self-stretch flex flex-row items-start justify-start pt-0 pb-[3px] pr-[5px] pl-0 box-border max-w-full shrink-0 text-left text-23xl text-white-back font-helvetica">
        <img class="w-full h-full max-w-full overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
             loading="eager" alt="" src="/line-1@2x.svg"/>
      </div>

      <div class="tab-item">
        <div class="tab-item__img">
          <img src="/annoc-img-1.jpg" alt="img-item" />
          <a class="tab-item-desc__link hidden mq750:flex" href="#">Learn more about App Inbox<img class="arrow-img" src="/arrow.png" alt="" /></a>
        </div>
        <div class="tab-item-desc">
          <div class="tab-item-desc__main-title">
            VAT and taxes
          </div>
          <div class="tab-item-desc__title">
            Global VAT and tax compliance made easy
          </div>
          <div class="tab-item-desc__text">
            <ul>
              <li>Decipher and validate QR code-encrypted information issued by tax authorities</li>
              <li>Ensure compliance with country-specific VAT and tax rules</li>
              <li>Map and enter tax codes into ERP and P2P systems quickly and accurately with AI</li>
            </ul>
          </div>
          <a class="tab-item-desc__link mq750:hidden" href="#">Learn more about App Inbox<img class="arrow-img" src="/arrow.png" alt="" /></a>
        </div>
      </div>
    </div>

    <div class="flex flex-row items-start justify-start relative max-w-full">
      <img
          class="h-[79.5px] w-[1161px] absolute my-0 mx-[!important] right-[-206px] bottom-[-56.5px] object-contain mq750:w-[450px]"
          loading="eager"
          alt=""
          src="/line-19-1@2x.png"
      />
    </div>
    <div
        class="flex flex-row items-start justify-start pt-5 px-5 pb-[72px] box-border max-w-full text-23xl mq750:pb-[20px]"
    >
      <h1
          class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1050:text-15xl mq750:h-[25px]"
      >
        Related Resources
      </h1>
    </div>
    <div
        class="w-[1240px] grid flex-row items-start justify-start pt-0 px-5 pb-[106px] box-border gap-[20px] max-w-full shrink-0 grid-cols-[repeat(3,_minmax(300px,_1fr))] mq750:pb-[50px] mq750:box-border mq750:grid-cols-[minmax(300px,_1fr)] mq1050:justify-center mq1050:grid-cols-[repeat(2,_minmax(300px,_520px))]"
    >
      <div class="flex flex-col items-start justify-start max-w-full">
        <img
            class="self-stretch h-[229px] relative rounded-8xs max-w-full overflow-hidden shrink-0 object-cover"
            alt=""
            src="/annoc-img-flex-1.jpg"
        />
        <div
            class="self-stretch flex flex-col items-center mq450:justify-start py-[23px] px-3.5 box-border relative gap-[55px] mq450:gap-[30px] mt-3 bg-gray min-h-[96px] h-[270px] justify-between mq450:h-auto"
        >
          <b
              class="self-stretch h-[26px] relative inline-block z-[2] mq450:text-base"
          >Platform brief: Autonomous spend-to-pay processing</b
          >
          <p class="font-normal text-[16px] leading-[16px] m-0 mq450:text-[12px] mq450:leading-[14px]">
            Here`s how our AI- driven autonomous
            spend- to- pay platform touchlessly controls enterprise spend to reduce risk, cost,
            and payment friction.
          </p>
          <div
              class="self-stretch flex flex-row items-start justify-between py-0 px-px gap-[20px] text-base text-grey mq450:flex-wrap mq450:text-[14px]"
          >
            <div class="relative z-[2]">RESOURCES</div>
            <div class="relative z-[2]">DOWNLOAD NOW</div>
          </div>
          <div
              class="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px]"
          >
          </div>
        </div>
      </div>
      <div class="flex flex-col items-start justify-start max-w-full">
        <img
            class="self-stretch h-[229px] relative rounded-8xs max-w-full overflow-hidden shrink-0 object-cover"
            alt=""
            src="/annoc-img-flex-2.jpg"
        />
        <div
            class="self-stretch flex flex-col items-center mq450:justify-start py-[23px] px-3.5 box-border relative gap-[55px] mq450:gap-[30px] mt-3 bg-gray min-h-[96px] h-[270px] justify-between mq450:h-auto"
        >
          <b
              class="self-stretch h-[26px] relative inline-block z-[2] mq450:text-base"
          >Guide to Autonomous AP Finance solutions</b
          >
          <p class="font-normal text-[16px] leading-[16px] m-0 mq450:text-[12px] mq450:leading-[14px]">
            Finance automation can’t fix the problems inherent to traditional AP processes. Discover the strategic benefits of autonomous solutions and 7 requirements for deployment success.
          </p>
          <div
              class="self-stretch flex flex-row items-start justify-between py-0 px-px gap-[20px] text-base text-grey mq450:flex-wrap mq450:text-[14px]"
          >
            <div class="relative z-[2]">RESOURCES</div>
            <div class="relative z-[2]">DOWNLOAD NOW</div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-start justify-start max-w-full">
        <img
            class="self-stretch h-[229px] relative rounded-8xs max-w-full overflow-hidden shrink-0 object-cover"
            alt=""
            src="/annoc-img-flex-3.jpg"
        />
        <div
            class="self-stretch flex flex-col items-center mq450:justify-start py-[23px] px-3.5 box-border relative gap-[55px] mq450:gap-[30px] mt-3 bg-gray min-h-[96px] h-[270px] justify-between mq450:h-auto"
        >
          <b class="self-stretch relative z-[2] mq450:text-base"
          >Why Georgetown’s AP team adopted an AI solution</b
          >
          <p class="font-normal text-[16px] leading-[16px] m-0 mq450:text-[12px] mq450:leading-[14px]">
            Georgrtown University’s nine-person AP team needed an invoice processing solution that could adapt to ...
          </p>
          <div
              class="self-stretch flex flex-row items-start justify-between py-0 px-px gap-[20px] text-base text-grey mq450:flex-wrap mq450:text-[14px]"
          >
            <div class="relative z-[2]">RESOURCES</div>
            <div class="relative z-[2]">DOWNLOAD NOW</div>
          </div>
        </div>
      </div>
    </div>

    <div class="marketplace">
      <img class="marketplace__img" src="/annoc-mpl-img.jpg" alt="" />
      <div class="marketplace-desc">
        <p class="marketplace-desc__text">Next up: Xendeck ai ap inbox</p>
        <p class="marketplace-desc__link">Generative AI for your AP emails<img class="arrow-img" src="/arrow-1.svg" alt="" /></p>
      </div>
    </div>

    <div class="mq750:mt-[37px] self-stretch flex flex-row items-start justify-start pt-0 pb-[3px] pr-[5px] pl-0 box-border max-w-full shrink-0 text-left text-23xl text-white-back font-helvetica">
      <div class="flex-1 flex flex-col items-center justify-end mq750:p-0 pt-[42px] pb-[15px] pr-5 pl-[39px] box-border relative max-w-[101%] shrink-0">
        <h1 class="m-0 h-[55px] relative top-[35px] mq450:top-[0] text-inherit font-bold font-inherit inline-block max-w-full mq450:text-6xl mq1050:text-15xl leading-normal mq750:text-[24px] mq750:mt-[32px] text-center">
          Empowering over 1,000 finance teams</h1>
        <div class="mq750:mt-[-32px]  w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] z-[1] flex items-center justify-center">
          <img class="w-full h-full max-w-full overflow-hidden max-h-full z-[1] object-contain absolute left-[0px] top-[4px] [transform:scalex(1.13)]"
               loading="eager" alt="" src="/line-1@2x.svg"/>
        </div>
      </div>
    </div>
    <button class="brands-block-text__btn">More case studies<img class="arrow-img" src="/arrow-1.svg" alt="" /></button>
    <div class="flex flex-row items-start justify-start relative max-w-full mt-[64px] mq750:hidden">
      <div class="w-[1440px] flex flex-row items-start justify-end pt-0 pb-36 px-0 box-border max-w-full mq750:pb-[94px] mq750:box-border">
        <div class="w-[2823px] flex flex-row items-start justify-start py-0 px-0 box-border max-w-[196%] shrink-0">
          <div class="h-[468px] w-[3120px] relative max-w-[111%] shrink-0">
            <div class="absolute top-[44px] left-[1354px] w-[1765.5px] h-[314.5px] flex items-center justify-center">
              <img class="w-full h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.045)]"
                   alt="" src="/line-6.svg"/>
            </div>
            <div class="absolute top-[0px] left-[1580.3px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
              <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-437.png"/>
            </div>
            <div class="absolute top-[0px] left-[1865.5px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
              <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-557.png"/>
            </div>
            <div class="absolute top-[0px] left-[2151.5px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
              <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-438.png"/>
            </div>
            <div class="absolute top-[0px] left-[2437.3px] w-[188.8px] h-[150px] z-[1] flex items-center justify-center">
              <img class="w-full h-full z-[1] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-439.png"/>
            </div>
            <div class="absolute top-[95px] left-[0px] w-[2217px] h-[373px]">
              <div class="absolute top-[0px] left-[0px] w-full h-full z-[1] flex items-center justify-center">
                <img class="w-full h-full z-[1] object-contain absolute left-[2px] top-[4px] [transform:scale(1.038)]"
                     alt="" src="/line-17.svg"/>
              </div>
              <div class="absolute top-[155px] left-[1580px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
                <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                     loading="eager" alt="" src="/group-438.png"/>
              </div>
              <div class="absolute top-[155px] left-[1865.3px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
                <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                     loading="eager" alt="" src="/group-439.png"/>
              </div>
            </div>
            <div class="absolute top-[250px] left-[2151.5px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
              <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-557.png"/>
            </div>
            <div class="absolute top-[250px] left-[2437.3px] w-[188.8px] h-[150px] z-[1] flex items-center justify-center">
              <img class="w-full h-full z-[1] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-437.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden flex-row items-start justify-start relative max-w-full mt-[64px] mq750:flex mq750:mt-[25px]">
      <div class="w-full flex flex-row items-start justify-end pt-0 pb-36 px-0 box-border max-w-full mq750:pb-[50px] mq750:box-border">
        <div class="w-full flex flex-row items-start justify-start py-0 px-0 box-border max-w-[196%] shrink-0">
          <div class="h-[468px] w-full relative max-w-[111%] shrink-0 grid-cols-[repeat(3,_minmax(300px,_1fr))]"
               style="
        display: grid;
    grid-template-columns: repeat(3, minmax(80px, auto));
    height: auto;
    gap: 26px;
    margin: auto;
    padding: 0 25px;
    box-sizing: border-box;
">
            <div class="top-[95px] left-[0px] w-[2217px] h-[373px]" style="
         position: absolute;
    width: 100%;
    height: 45%;
    left: 0;
    bottom: 0;
    top: auto;
">
              <div class="top-[0px] left-[0px] w-full h-full z-[1] flex items-center justify-center"><img style="    object-fit: cover;
    object-position: 150% 0px;"
                                                                                                          class="w-full h-full z-[1] object-contain left-[2px] top-[4px] [transform:scale(1.038)]"
                                                                                                          alt="" src="/line-17.svg"></div>
            </div>
            <div class="top-[44px] left-[1354px] w-[1765.5px] h-[314.5px] flex items-center justify-center"
                 style="    position: absolute;
    width: 100%;
    height: 45%;
    left: 0;
"><img class="w-full h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.045)]" style="    object-fit: cover;
    object-position: 59% 0px;" alt=""
       src="/line-6.svg"></div>
            <div class="top-[0px] left-[1580.3px] w-auto h-auto z-[2] flex items-center justify-center"
                 style="
    left: 0;
    width: 100%;
"><img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]" loading="eager" alt=""
       src="/group-437.png"></div>
            <div class="top-[0px] left-[1865.5px] w-auto h-auto z-[2] flex items-center justify-center">
              <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-557.png"></div>
            <div class="top-[0px] left-[2151.5px] w-auto h-auto z-[2] flex items-center justify-center">
              <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-438.png"></div>
            <div class="top-[0px] left-[2437.3px] w-auto h-auto z-[1] flex items-center justify-center">
              <img class="w-full h-full z-[1] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-439.png"></div>
            <div class="top-[250px] left-[2151.5px] w-auto h-auto z-[2] flex items-center justify-center">
              <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-437.png"></div>
            <div class="top-[250px] left-[2437.3px] w-auto h-auto z-[1] flex items-center justify-center">
              <img class="w-full h-full z-[1] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-557.png"></div>
            <div class="top-[155px] left-[1580px] w-auto h-auto z-[2] flex items-center justify-center">
              <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-437.png"></div>
            <div class="top-[155px] left-[1865.3px] w-auto h-auto z-[2] flex items-center justify-center">
              <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-557.png"></div>
            <div class="top-[155px] left-[1865.3px] w-auto h-auto z-[2] flex items-center justify-center">
              <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                   loading="eager" alt="" src="/group-439.png"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row items-start justify-start relative max-w-full">
      <img
          class="h-[79.5px] w-[1161px] absolute my-0 mx-[!important] right-[-206px] bottom-[-56.5px] object-contain mq750:w-[450px]"
          loading="eager"
          alt=""
          src="/line-19-1@2x.png"
      />
    </div>
    <div
        class="flex flex-row items-start justify-start pt-5 px-5 pb-[72px] box-border max-w-full text-23xl mq750:pb-[20px]"
    >
      <h1
          class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1050:text-15xl mq750:h-[25px]"
      >
        Take the next step
      </h1>
    </div>
    <div
        class="w-[1240px] flex flex-row flex-wrap items-start justify-start pt-0 px-5 pb-[76px] box-border gap-[50px] max-w-full shrink-0 text-base mq750:gap-[50px] mq750:pb-[45px]"
    >
      <FrameAboutUsNews
          contactSales="Contact Sales"
          learnHowOurIndustryLeadin="Learn how our industry-leading XENdeck AI software products can help your organization."
      />
      <div
          class="flex-1 flex flex-col items-start justify-start gap-[15px] min-w-[285px] max-w-full"
      >
        <button
            class="cursor-pointer py-[22px] px-5 bg-[transparent] self-stretch rounded-12xs box-border flex flex-row items-center justify-start gap-[15px] max-w-full border-[1px] border-solid border-white-back hover:bg-gainsboro-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-gainsboro-100"
        >
          <div
              class="h-[67px] w-[380px] relative rounded-12xs box-border hidden max-w-full border-[1px] border-solid border-white-back"
          />
          <b
              class="relative text-lg uppercase font-helvetica text-white-back text-left z-[1]"
          >Go to Investor Relations</b
          >
          <img
              class="h-[8.8px] w-[16.3px] relative z-[1]"
              alt=""
              src="/arrow-1-1.svg"
          />
        </button>
        <div class="self-stretch h-12 relative font-light inline-block">
          <p class="[margin-block-start:0] [margin-block-end:4px]">
            Contact us at support@xendeck.ai to learn more
          </p>
          <p class="m-0">about investing at XENdeck.</p>
        </div>
      </div>
      <FrameAboutUsNews
          contactSales="request information"
          learnHowOurIndustryLeadin="For all other questions, please contact us here."
      />
    </div>

<!--    <div-->
<!--        class="self-stretch flex flex-row items-start justify-start pt-0 pb-1.5 pr-[5px] pl-0 box-border max-w-full"-->
<!--    >-->
<!--      <div-->
<!--          class="h-28 flex-1 relative shrink-0 flex items-center justify-center"-->
<!--      >-->
<!--        <img-->
<!--            class="h-full flex-1 max-w-[101%] overflow-hidden shrink-0 object-contain absolute left-[0px] top-[4px] w-full [transform:scale(1.125)]"-->
<!--            loading="eager"-->
<!--            alt=""-->
<!--            src="/line-2-1@2x.png"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
    <div class="self-stretch bg-[#2d2d3c] flex flex-row items-start justify-start pt-0 pb-1.5 pr-[5px] pl-0 box-border max-w-full">
      <div class="h-28 flex-1 relative shrink-0 flex items-center justify-center">
        <img class="h-full flex-1 max-w-[101%] left-positioning overflow-hidden shrink-0 object-contain absolute left-[0px] top-[4px] w-full [transform:scale(1.125)]"
             loading="eager" alt="" src="/line-2-1@2x.svg" style="
    width: 1440px;
    transform: scalex(2)"/>
      </div>
    </div>
    <main class="mx-auto self-stretch flex flex-row items-start justify-start pt-0 px-0 box-border max-w-full lg:box-border ">
      <footer style="padding: 15px;width:100%;flex-wrap: nowrap;"
              class="mq750:flex flex-1 mq750:gap-0 mq750:w-full hidden flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
        <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"></div>
        <div class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
          <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]" style="        height: auto;
    width: 100px;
    position: absolute;"
               loading="eager" alt="" src="/group-32@2x.png">
<!--          <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>-->
        </div>
        <!--                &lt;div class=&quot;flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
        <!--                    --><!--                &lt;/div&gt;-->
        <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
          <div class="flex flex-row items-start justify-start gap-[12px] flex-wrap" style="
    width: 100%;
">
            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
            <!--            <div class="relative z-[1]" style="flex-grow: 1;-->
<!--  width: 33%;">About Us</div>-->
            <a href="/news" class="relative z-[1] text-white">News</a>
             <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>

            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
          </div>
        </div>
        <!--                &lt;div class=&quot;hidden mq750:flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
        <!--                    &lt;div class=&quot;relative z-[1]&quot;&gt;Contact Us&lt;/div&gt;-->
        <!--                    &lt;div class=&quot;w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full&quot;&gt;-->
        <!--                        &lt;div class=&quot;self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap&quot;&gt;-->
        <!--                            &lt;div class=&quot;flex flex-col items-start justify-start gap-[12px]&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;About Us&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;News&lt;/div&gt;-->
        <!--                            &lt;/div&gt;-->
        <!--                            &lt;div class=&quot;flex-[0.8557] flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;AI Software&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Resourses&lt;/div&gt;-->
        <!--                            &lt;/div&gt;-->
        <!--                            &lt;div class=&quot;flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Privacy Policy&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Terms of Use&lt;/div&gt;-->
        <!--                            &lt;/div&gt;--><!--                        &lt;/div&gt;-->
        <!--                    &lt;/div&gt;--><!--                &lt;/div&gt;--></footer>
      <footer style="    padding-left: 0;
    width: 1290px;"
              class="flex-1 mq750:hidden flex flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:gap-[89px] mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
        <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"/>
        <div class="w-[226px] ml-[100px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
          <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
               loading="eager" alt="" src="/group-32@2x.png"/>
<!--          <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>-->
        </div>
        <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
                    <div class="self-stretch gap-[40px] flex flex-row items-start justify-between mq450:flex-wrap">
                        <div class="flex flex-col items-start justify-start px-0 pb-0">
                            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
                        </div>
                        <div class="flex flex-col items-start justify-start gap-[12px]">
                            <a href="/news" class="relative z-[1] text-white">News</a>
                        </div>
                        <div class="flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1">
                            <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>
<!--                            <div class="relative z-[1]">Resourses</div>-->
                        </div>
                        <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]">
                            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
                            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
                        </div>
                    </div>
                </div>
      </footer>
    </main>
  </div>
</template>
<script>
import {defineComponent, reactive, toRefs} from "vue";
import FrameAboutUsNews from "@/components/FrameAboutUsNews.vue";

const tabs = ['Organize', 'Process', 'Respond'];
export default defineComponent({
  name: "AiAppInbox",
  components: { FrameAboutUsNews },
  setup() {
    const state = reactive({
      selectedTab: tabs[1]
    });
    return {
      tabs,
      ...toRefs(state)
    };
  }
});
</script>

<style lang="scss" scoped>
.tab-item {
  display: flex;
  margin: 25px 100px 25px;
  justify-content: center;

  @media (max-width: 950px) {
    flex-direction: column-reverse;
    margin: 0 15px;
  }
  &__img {
    img {
      max-height: 510px;
      max-width: 610px;
      border-radius: 5px;

      @media (max-width: 950px) {
        width: 100%;
        height: auto;
        max-width: 100%;
      }
    }
  }

  &-right {
    flex-direction: row-reverse;

    @media (max-width: 950px) {
      flex-direction: column-reverse;
    }
  }

  &-desc {
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 950px) {
      padding-left: 0;
      margin-top: 15px;
    }

    &-right {
      padding-left: 0 !important;
      padding-right: 100px !important;

      @media (max-width: 950px) {
        padding-right: 0 !important;
        margin-top: 15px;
      }
    }

    &__main-title {
      line-height: 58px;
      font-size: 42px;
      font-weight: 700;
      margin-bottom: 10px;

      @media (max-width: 750px) {
        line-height: 18px;
        font-size: 16px;
        margin-bottom: 5px;
      }
    }

    &__title {
      line-height: 37px;
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 10px;

      @media (max-width: 750px) {
        line-height: 18px;
        font-size: 16px;
        margin-bottom: 5px;
      }
    }

    &__text {
      margin: 0 0 30px;
      line-height: 27px;
      font-size: 20px;
      font-weight: 400;

      @media (max-width: 750px) {
        margin: 0;
        line-height: 16px;
        font-size: 12px;
      }
    }

    &__link {
      margin: 0;
      line-height: 23px;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      text-decoration: none;
      color: #A2A2A2;

      @media (max-width: 750px) {
        margin: 5px 0 35px;
        line-height: 18px;
        font-size: 16px;
      }
    }

    &__list {
      list-style-type: disc;
      li {
        margin-bottom: 10px;
      }
      li span {
        font-weight: 700;
      }
    }
  }
}

.tabs {
  justify-content: center;
}

.tab-panels {
  margin: 0 100px;

  @media (max-width: 950px) {
    margin: 0 15px;
  }
}

.tab-news {
  padding: 0 5px !important;
  color: #A2A2A2;
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 750px) {
    padding: 0 !important;
    border-width: 0;
    border-bottom-width: 2px;
    border-style: solid;
    border-color: #A2A2A2;
    font-size: 12px;
    line-height: 1px;
  }

  div {
    padding: 0;
  }
}

.active {
  border-width: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #FFFFFF !important;
  color: #FFFFFF !important;
}

.title-block {
  width: 100%;
  display: flex;
  padding: 60px 0 60px 100px;
  margin-bottom: 60px;
  background-color: rgba(0, 0, 0, 0.23);
  box-sizing: border-box;

  @media (max-width: 950px) {
    flex-direction: column-reverse;
    padding: 30px 15px;
    margin-bottom: 20px;
  }
  &-text {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-right: 55px;
    max-width: 478px;

    @media (max-width: 950px) {
      margin-top: 30px;
      margin-right: 0;
      max-width: 100%;
    }
    &__title {
      color: #1C57E0;
      font-weight: 400;
      font-size: 52px;
      line-height: 60px;
      margin: 0;
      text-transform: uppercase;

      @media (max-width: 750px) {
        font-size: 32px;
        line-height: 30px;
      }
    }
    &__subtitle {
      margin-top: 10px;
      color: #ffffff;
      font-weight: 700;
      font-size: 23px;
      line-height: 30px;
      text-transform: uppercase;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    &__desc {
      margin-top: 20px;
      color: #ffffff;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;

      @media (max-width: 750px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &__btn {
      margin-top: 30px;
      text-transform: uppercase;
      border: 1px solid #ffffff;
      color: #ffffff;
      background: transparent;
      padding: 20px 32px;
      max-width: 285px;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;

      @media (max-width: 750px) {
        padding: 14px 20px;
        max-width: 220px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &-img {
    &__image {
      border-radius: 5px;
      width: 100%;
      height: 100%;
    }
  }
}

.organize-process-response {
  padding: 0 274px;
  text-align: center;

  @media (max-width: 950px) {
    padding: 0 15px;
  }
  &__title {
    h1 {
      margin: 0 0 22px;
      font-weight: 700;
      font-size: 42px;
      line-height: 41px;

      @media (max-width: 750px) {
        margin: 0 0 10px;
        font-size: 24px;
        line-height: 31px;
      }
    }
  }

  &__desc {
    p {
      margin: 0 0 40px;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;

      @media (max-width: 750px) {
        margin: 0 0 15px;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  &__img {
    margin: 0 0 40px;

    @media (max-width: 750px) {
      margin: 0;
    }
    img {
      margin: 0 0 30px;
      border-radius: 5px;
      width: 100%;
    }
  }
}

.marketplace {
  height: 190px;
  width: 65%;
  display: flex;
  margin: 34px 65px 80px;
  border: 6px solid #212135;
  @media (max-width: 750px) {
    height: 85px;
    margin: 0 35px 40px;
    width: auto;
    border: 3px solid #212135;
  }

  &-desc {
    padding: 50px 41px;
    border-left: 6px solid #212135;
    @media (max-width: 750px) {
      padding: 12px 10px;
      border-left: 3px solid #212135;
    }

    &__text {
      margin: 0 0 17px;
      color: #A2A2A2;
      line-height: 23px;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      @media (max-width: 750px) {
        line-height: 14px;
        font-size: 12px;
      }
    }

    &__link {
      margin: 0;
      line-height: 50px;
      font-size: 36px;
      font-weight: 700;
      @media (max-width: 750px) {
        line-height: 21px;
        font-size: 16px;
      }
    }
  }

  &__img {
    width: 220px;
    height: 190px;
    @media (max-width: 750px) {
      width: 82px;
      height: 100%;
    }
  }
}

.brands-block-text__btn {
    margin-top: 50px;
    text-transform: uppercase;
    border: 1px solid #ffffff;
    color: #ffffff;
    background: transparent;
    padding: 20px 30px;
    max-width: 311px;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;

    @media (max-width: 750px) {
      margin-top: 25px;
      padding: 14px 20px;
      max-width: 305px;
      font-size: 16px;
      line-height: 20px;
    }
}

.arrow-img {
  margin-left: 15px;
  margin-bottom: 3px;
  @media (max-width: 750px) {
    margin-left: 10px;
    margin-bottom: 1px;
    width: 19px !important;
    height: 10px !important;
  }
}
</style>