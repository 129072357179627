<template>
  <div
    class="flex-1 flex flex-col items-start justify-start gap-[15px_0px] min-w-[285px] max-w-full text-left text-lg text-white-back font-helvetica"
  >
    <div
      class="self-stretch rounded-12xs box-border flex flex-row items-start justify-start py-[22px] px-5 gap-[15px] max-w-full border-[1px] border-solid border-white-back"
      :style="groupDiv2Style"
    >
      <div
        class="h-[67px] w-[380px] relative rounded-12xs box-border hidden max-w-full border-[1px] border-solid border-white-back"
      />
      <b class="relative uppercase z-[1]">{{contactSales}}</b>
      <div
        class="h-3 flex flex-col items-start justify-start pt-3 px-0 pb-0 box-border"
      >
        <img
          class="w-[15px] h-[8.8px] relative z-[1]"
          alt=""
          src="/aisoftware/arrow-1.svg"
        />
      </div>
    </div>
    <div class="self-stretch relative text-base font-light pr-3">
      {{learnHowOurIndustryLeadin}}
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "ShadowNodes",
    props: {
      contactSales: { type: String },
      learnHowOurIndustryLeadin: { type: String },
      propGap: { type: String },
    },
    computed: {
      groupDiv2Style() {
        return {
          gap: this.propGap,
        };
      },
    },
  });
</script>
