<template>
  <div
    class="flex flex-col items-start justify-start pt-[41px] px-[29px] pb-12 relative gap-[30px] text-center text-xl text-white-back font-helvetica"
    :style="groupDiv3Style"
  >
    <div
      class="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-center justify-center z-[0]"
    >
      <img
        class="w-full h-full max-w-full overflow-hidden max-h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.05)]"
        alt=""
        src="/aisoftware/group-427-1.svg"
      />
    </div>
    <div
      class="self-stretch flex flex-row items-start justify-start py-0 px-[34px]"
    >
      <img
        class="h-[185px] flex-1 relative rounded-mini max-w-full overflow-hidden object-cover z-[1]"
        loading="lazy"
        alt=""
        src="/aisoftware/googlecloudpartneroftheyearbadge768x574@2x.png"
        :style="googleCloudPartneroftheYearIconStyle"
      />
    </div>
    <div class="relative uppercase font-light z-[1] mq450:text-base">
      <p class="m-0">{{googleCloud}}</p>
      <p class="m-0">{{partnerOfTheYEar}}</p>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "GroupFrame3",
    props: {
      googleCloudPartneroftheYe: { type: String },
      googleCloud: { type: String },
      partnerOfTheYEar: { type: String },
      propGap: { type: String },
      propHeight: { type: [Object, Array, String, Number, Boolean] },
      propBorderRadius: { type: String },
    },
    computed: {
      groupDiv3Style() {
        return {
          gap: this.propGap,
        };
      },
      googleCloudPartneroftheYearIconStyle() {
        return {
          height: this.propHeight,
          borderRadius: this.propBorderRadius,
        };
      },
    },
  });
</script>
