<template>
  <div
    class="self-stretch bg-gray-600 flex flex-col items-start justify-start pt-[57px] px-[102px] pb-[134px] box-border gap-[94px_0px] max-w-full z-[1] text-left text-5xl text-white-back font-helvetica mq450:gap-[23px_0px] mq450:pl-5 mq450:pr-5 mq450:box-border mq750:gap-[47px_0px] mq750:pl-[51px] mq750:pr-[51px] mq750:box-border"
  >
    <div class="w-[1440px] h-[728px] relative bg-gray-600 hidden max-w-full" />
    <div
      class="self-stretch flex flex-row items-start justify-center pt-0 pb-[11px] pr-0 pl-[361px] mq450:pl-5 mq450:mt-[30px] mq450:ml-[5%] mq450:box-border mq750:pl-[180px] mq750:box-border"
    >
      <b class="h-[31px] relative uppercase inline-block z-[2] mq450:text-lgi"
        >Ai and machine learning</b
      >
    </div>
      <div
              class="self-stretch flex flex-row items-start justify-center py-0 pr-0 pl-[620px] mq750:pl-[300px] mq450:pl-5 mq450:mt-[30px] mq450:ml-[20%] mq450:box-border mq1025:pl-[310px] mq1025:box-border"
      >
          <b
                  class="h-[31px] relative uppercase inline-block z-[2] mq450:text-lgi"
          >platform services</b>
      </div>
      <div
              class="self-stretch flex flex-row items-start justify-center py-0 pr-0 pl-[620px] mq750:pl-[0px] mq450:pl-5 mq450:mt-[30px] mq450:ml-[5%] mq450:box-border mq1025:pl-[310px] mq1025:box-border"
      >
          <b
                  class="h-[31px] relative uppercase inline-block max-w-full z-[2] mq450:text-lgi"
          >Application development</b
          >
      </div>
    <div
      class="self-stretch flex flex-row items-start justify-center py-0 pr-0 pl-[620px] mq750:pl-[250px] mq450:pl-5 mq450:mt-[30px] mq450:ml-[20%] mq450:box-border mq1025:pl-[310px] mq1025:box-border"
    >
      <b class="h-[31px] relative uppercase inline-block z-[2] mq450:text-lgi"
        >Data unification</b
      >
    </div>


    <div
      class="w-[1073px] flex flex-col items-start justify-start gap-[22px_0px] max-w-full absolute top-[300px] mq750:top-[450px] mq450:gap-0"
    >
      <div
        class="w-[1035px] flex flex-row items-start justify-start py-0 px-0.5 box-border max-w-full mq450:ml-[-60px] mq450:[transform:scale(.7)]"
      >
        <div class="flex-1 flex flex-col items-start justify-start max-w-full">
          <div
            class="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-px box-border max-w-full"
          >
            <div
              class="flex-1 flex flex-row items-start justify-between max-w-full gap-[20px] mq1025:flex-wrap"
            >
              <div
                class="w-[355px] flex flex-col items-start justify-start pt-6 px-0 pb-0 box-border max-w-full"
              >
                <div
                  class="self-stretch flex flex-row items-end justify-start gap-[0px_5.4px] mq450:flex-wrap"
                >
                  <img
                    class="h-[90.1px] w-[99.6px] relative z-[2]"
                    loading="lazy"
                    alt=""
                    src="/aisoftware/group-12.svg"
                  />
                  <img
                    class="h-[62.1px] w-[250px] relative z-[3]"
                    loading="lazy"
                    alt=""
                    src="/aisoftware/changeme.svg"
                  />
                </div>
              </div>
<!--              <b-->
<!--                class="h-[31px] relative uppercase inline-block max-w-full z-[2] mq450:text-lgi"-->
<!--                >Application development</b-->
<!--              >-->
            </div>
          </div>
          <h1
            class="m-0 w-[358px] mt-[10px] h-[60.2px] text-[52px] text-[#1C57E0] relative text-33xl uppercase font-normal font-inherit text-royalblue whitespace-pre-wrap text-center inline-block shrink-0 max-w-full z-[2] mt-[-0.3px] mq450:text-12xl mq1025:text-23xl"
          >
            <span class="mr-[20px]">AI</span>Platform
          </h1>
        </div>
      </div>
      <div
        class="self-stretch flex flex-row items-start justify-between max-w-full gap-[20px] mq450:gap-0 text-4xl mq1025:flex-wrap"
      >
        <b
          class="h-[60px] w-[395px] relative uppercase inline-block shrink-0 max-w-full z-[2] mq450:text-lg"
          >Build, deploy, and operate Enterprise AI applications</b
        >
<!--        <div-->
<!--          class="flex flex-col items-start justify-start pt-5 px-0 pb-0 text-5xl"-->
<!--        >-->
<!--          <b-->
<!--            class="h-[31px] relative uppercase inline-block z-[2] mq450:text-lgi"-->
<!--            >platform services</b-->
<!--          >-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "CousinQueue",
  });
</script>
