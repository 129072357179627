<template>
  <div
    class="flex-1 flex flex-col items-start justify-start gap-[15px] min-w-[285px] max-w-full text-left text-lg text-white-back font-helvetica mq750:flex-[0_0_100%]"
  >
      <a class="w-full" href="mailto:support@xendeck.ai">
          <button
                  class="w-full cursor-pointer py-[22px] px-5 bg-[transparent] self-stretch rounded-12xs box-border flex flex-row items-center justify-start gap-[15px] max-w-full border-[1px] border-solid border-white-back hover:bg-gainsboro-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-gainsboro-100"
          >
              <div
                      class="h-[67px] w-[380px] relative rounded-12xs box-border hidden max-w-full border-[1px] border-solid border-white-back"
              />
              <b class="relative text-lg uppercase font-helvetica text-white-back text-left z-[1]">{{contactSales}}</b>
              <img
                      class="h-[8.8px] w-[15px] relative z-[1]"
                      loading="eager"
                      alt=""
                      src="/arrow-1.svg"
              />
          </button>
      </a>
    <div
      class="w-[368px] relative text-base font-light inline-block max-w-full"
    >
      {{learnHowOurIndustryLeadin}}
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "FrameAboutUsNews",
    props: {
      contactSales: { type: String },
      learnHowOurIndustryLeadin: { type: String },
    },
  });
</script>
