import Home from "@/pages/Home.vue";
import NewsPage from "@/pages/NewsPage.vue";
import AiAppInbox from "@/pages/AiAppInbox.vue";
import AiSoftware from "@/pages/AiSoftware.vue";
import AutonomousAp from "@/pages/AutonomousAp.vue";
import ContactUs from "@/pages/ContactUs.vue";
import PrivacyPolicy from "@/pages/PrivacyPolicy.vue";
import TermsOfUse from "@/pages/TermsOfUse.vue";
import itForEnterpriseAI from "@/pages/itForEnterpriseAI.vue";
import enterprise from "@/pages/enterprise-ai-buyers-guide.vue";
import whatEnterprice from "@/pages/whatEnterprice.vue";

export const routes = [
    { path: '/', component: Home },
    { path: '/news', component: NewsPage },
    { path: '/ai-app-inbox', component: AiAppInbox },
    { path: '/ai-software', component: AiSoftware },
    { path: '/autonomous-ap', component: AutonomousAp },
    { path: '/contact', component: ContactUs },
    { path: '/privacy-policy', component: PrivacyPolicy },
    { path: '/terms-of-use', component: TermsOfUse },
    { path: '/it-for-enterprise-ai', component: itForEnterpriseAI },
    { path: '/enterprise-ai-buyers-guide', component: enterprise },
    { path: '/what-is-enterprise-ai', component: whatEnterprice },
]