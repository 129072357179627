<template>
    <Header></Header>
    <router-view></router-view>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import "./index.scss"
import Header from "@/components/Header.vue";

export default {
    name: 'App',
    components: {
        Header
        // HelloWorld
    },
    mounted() {

    }
}
</script>

<style>
#app {
    /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
    /*text-align: center;*/
    /*color: #2c3e50;*/
    /*margin-top: 60px;*/
}

</style>
