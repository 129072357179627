/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx,vue}"],
  theme: {
    extend: {
      colors: {
        d2d3c: "#2d2d3c",
        "white-back": "#fff",
        midnightblue: "#2c2c8f",
        gainsboro: {
          "100": "#e6e6e6",
          "200": "rgba(230, 230, 230, 0.09)",
        },
        grey: "#a2a2a2",
        gray: "#1f1f2b",
      },
      spacing: {},
      fontFamily: {
        "noto-sans": "'Noto Sans'",
        helvetica: "Helvetica",
        inherit: "inherit",
      },
      borderRadius: {
        "12xs": "1px",
        "8xs": "5px",
      },
      animation: {
        'burns': 'burns 1s linear 1 normal forwards',
        'burnsf': 'burnsf .8s linear 1 normal forwards',
      },
      keyframes: {
        burnsf: {
          'from': {
            transform: "translate3d(0,0,0) scale(1.25)",
            filter:"brightness(.4)"
            // transition: "all 1s",

          },
          'to': {
            transform: "translate3d(0,0,0) scale(1)",
            filter:"brightness(1)"
          }
          // '50%': { transform: 'rotate(3deg)' },
        },
        burns: {
          'from': {
            transform: "translate3d(0,0,0) scale(1)",
            filter:"brightness(1)"
            // transition: "all 1s",

          },
          'to': {
            transform: "translate3d(0,0,0) scale(1.25)",
            filter:"brightness(.4)"
          }
          // '50%': { transform: 'rotate(3deg)' },
        }
      }
    },
    fontSize: {
      base: "16px",
      lg: "18px",
      "23xl": "42px",
      "6xl": "25px",
      "15xl": "34px",
      xl: "20px",
      inherit: "inherit",
    },
    screens: {
      lg: {
        max: "1200px",
      },
      mq1050: {
        raw: "screen and (max-width: 1050px)",
      },
      mq750: {
        raw: "screen and (max-width: 750px)",
      },
      mq450: {
        raw: "screen and (max-width: 450px)",
      },
    },
  },
  plugins: [
    require('tailwindcss-animated'),
  ],
  corePlugins: {
    preflight: false,
  },
};
