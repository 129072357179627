<template>
    <div class="w-full h-full max-w[1920px] bg-d2d3c overflow-hidden flex flex-col items-center justify-start gap-[4px] tracking-[normal] text-left text-xl text-white-back font-helvetica mq450:h-auto">
        <div class="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[136px] box-border max-w-full shrink-0 text-center text-[89px]  mq750:box-border mq750:pb-0 mq1050:pb-[88px] mq1050:box-border">
            <div class="h-auto flex-1 flex flex-col items-center justify-between pt-[175px] px-0 pb-0 box-border relative gap-0 bg-cover bg-no-repeat bg-[top] max-w-full mq1050:box-border mq750:h-[524px] mq750:pt-[83px] mq750:px-[15px] ">
                <video autoplay muted
                       class="w-full mq750:brightness-[.67] mq750:object-cover mq750:object-right object-fill h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px]">
                    <source src="Technology_Background4.mp4" type="video/mp4"/>
                </video>
                <!--                <img src="/technology-background4@3x.gif" class="w-full h-full absolute top-[0px] right-[0px] bottom-[0px] left-[0px]"/>-->
                <img class="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-cover z-[1]"
                     loading="eager" alt="" src="/ezgif-2@2x.png"/>
                <div class="pb-[241px] mq750:pb-[67px] w-[1255px] mq450:gap-[56px] flex flex-col items-center justify-start py-0 px-5 box-border gap-[78px] max-w-full shrink-0 mq450:px-0 ">
                    <h1 class="m-0 w-[1094px] h-auto relative text-inherit leading-normal font-bold font-inherit inline-block max-w-full z-[2] mq750:leading-[1.4] mq750:text-[40px]">
                        <p class="m-0">Enterprise</p>
                        <p class="m-0">fintech and AI solutions</p>
                    </h1>
                    <h3 class=" mq750:leading-normal m-0 self-stretch h-[66px] relative text-[24px] uppercase font-light font-inherit text-left inline-block z-[2] mq750:text-[18px] mq750:text-center">
                        XENdeck delivers a comprehensive Enterprise AI and fintech
                        application development platform and growing family of turnkey
                        enterprise AI and fintech applications. </h3>
                </div>
                <div class="self-stretch relative shrink-0 flex items-center justify-center z-[3]">
                    <img class="self-stretch h-full max-w-full overflow-hidden shrink-0 object-cover absolute left-[0px] w-full [transform:scalex(1.13)]"
                         loading="eager" alt="" src="/line-3@2x.svg" style="    height: 7px;
    object-fit: cover;
    object-position: top;"/>
                </div>
            </div>
        </div>
        <TurnkeyFrames xENdeckApplications="XENdeck applications"/>
        <div class="w-[1104px] flex flex-row items-start justify-start pt-0 px-5 pb-[60px] box-border max-w-full shrink-0 text-center mq750:pb-[20px]">
            <div class="h-[46px] mq750:h-auto mq750:p-0 flex-1 relative inline-block max-w-full mq450:text-base mq750:text-[14px]">
                <p class="m-0">
                    XENdeck provides over 20 turnkey Enterprise fintech and AI
                    applications that meet </p>
                <p class="m-0">
                    the business-critical needs of global enterprises in manufacturing,
                    financial services, utilities, oil and gas. </p>
            </div>
        </div>
        <div class="w-[1280px] grid flex-row items-start justify-start pt-0 px-5 pb-[106px] box-border gap-[20px] max-w-full shrink-0 grid-cols-[repeat(3,_minmax(300px,_1fr))] mq750:pb-[69px] mq750:box-border mq750:grid-cols-[minmax(200px,400px)] mq1050:justify-center mq1050:grid-cols-[repeat(2,_minmax(300px,_520px))] mq750:mt-[20px] mq450:pb-0">
            <div class="overflow-hidden image-card  flex flex-col items-start justify-start max-w-full">
                <img class="image self-stretch h-[313px] relative rounded-8xs max-w-full overflow-hidden shrink-0 object-cover"
                     alt="" src="/copyright-x-e-ndeck@2x.png"/>
                <div class="self-stretch flex flex-col items-center justify-end py-[23px] px-3.5 box-border relative gap-[55px] min-h-[180px] mt-[-48px] mq450:gap-[55px]">
                    <b class="self-stretch h-[26px] relative inline-block z-[2] mq450:text-base">Shell Chooses XENdeck
                        to Digitally Transform Shell</b>
                    <div class="self-stretch flex flex-row items-start justify-between py-0 px-px gap-[20px] text-base text-grey mq450:flex-wrap">
                        <div class="relative z-[2]">Customer review</div>
                        <div class="relative z-[2]">Case Study</div>
                    </div>
                    <div class="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px]">
                        <img class="absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-8xs max-w-full overflow-hidden max-h-full object-cover z-[1]"
                             alt="" src="/rectangle-3@2x.png"/>
                        <div class="absolute w-full h-9 top-[-4px]">
                            <img class="image-rectangle absolute top-[14px] left-[12px] w-[93px] h-[15px] overflow-hidden object-cover z-[2]"
                                 style="    width: 94px;
    height: 25px;
    object-fit: contain;" loading="eager" alt="" src="/shell-1@2x.png"/>
                            <img class="absolute top-[0px] left-[0px] w-full h-[46px] object-cover z-[3]" alt=""
                                 src="/line-2.svg"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overflow-hidden image-card  flex flex-col items-start justify-start max-w-full">
                <img class="image self-stretch h-[313px] relative rounded-8xs max-w-full overflow-hidden shrink-0 object-cover"
                     alt="" src="/xxl-height-1@2x.png"/>
                <div class="self-stretch flex flex-col items-center justify-end py-[23px] px-3.5 box-border relative gap-[55px] min-h-[180px] mt-[-48px] mq450:gap-[55px]">
                    <b class="self-stretch h-[26px] relative inline-block z-[2] mq450:text-base">Koch Uses XENdeck AI at
                        Scale</b>
                    <div class="self-stretch flex flex-row items-start justify-between py-0 px-px gap-[20px] text-base text-grey mq450:flex-wrap">
                        <div class="relative z-[2]">Customer review</div>
                        <div class="relative z-[2]">Case Study</div>
                    </div>
                    <div class="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px]">
                        <img class="absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-8xs max-w-full overflow-hidden max-h-full object-cover z-[1]"
                             alt="" src="/rectangle-3@2x.png"/>
                        <div class="absolute w-full h-9 top-[-4px]">
                            <img class="image-rectangle absolute top-[14px] left-[12px] w-[93px] h-[15px] overflow-hidden object-cover z-[2]"
                                 style="    width: 94px;
    height: 25px;
    object-fit: contain;" loading="eager" alt="" src="/kochlogowhite-1@2x.png"/>
                            <img class="absolute top-[0px] left-[0px] w-full h-[46px] object-cover z-[3]" alt=""
                                 src="/line-2.svg"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overflow-hidden image-card flex flex-col items-start justify-start max-w-full">
                <img class="image self-stretch h-[308px] relative rounded-8xs max-w-full overflow-hidden shrink-0 object-cover"
                     alt="" src="/1200x800-1@2x.png"/>
                <div class="self-stretch flex flex-col items-center justify-between pt-[58px] px-3.5 pb-[23px] relative gap-[29px] mt-[-43px]"
                     style="    height: 180px;
    box-sizing: border-box;">
                    <b class="self-stretch relative z-[2] mq450:text-base">Enterprise Data Analytics Platform and AMI
                        Operations</b>
                    <div class="self-stretch flex flex-row items-start justify-between py-0 px-px gap-[20px] text-base text-grey mq450:flex-wrap">
                        <div class="relative z-[2]">Customer review</div>
                        <div class="relative z-[2]">Case Study</div>
                    </div>
                    <div class="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px]">
                        <img class="absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-8xs max-w-full overflow-hidden max-h-full object-cover z-[1]"
                             alt="" src="/rectangle-3@2x.png"/>
                        <div class="absolute top-[-4px] w-full h-9">
                            <img class="image-rectangle absolute top-[14px] left-[16px] w-[88.2px] h-[17px] overflow-hidden object-cover z-[2]"
                                 loading="eager" alt="" src="/conedisonlogocolor-1@2x.png"/>
                            <img class="absolute top-[0px] left-[0px] w-full h-[46px] object-cover z-[3]" alt=""
                                 src="/line-2.svg"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="self-stretch flex flex-row items-start justify-start pt-0 pb-7 px-0 box-border max-w-full shrink-0 text-23xl mq450:pt-[35px]">
            <div class="w-full flex flex-col items-center justify-end pt-[47px] px-5 pb-0 box-border relative max-w-full mq450:pt-0">
                <div class="w-full h-[79.5px] absolute my-0 mx-[!important] top-[0px] right-[0.5px] left-[-0.5px] shrink-0 flex items-center justify-center z-[0]">
                    <img class="mq450:mt-[-35px] mq750:ml-[-50px] w-[1168px] h-full max-w-full overflow-hidden shrink-0 object-contain absolute left-[2px] top-[4px] [transform:scale(1.176)] "
                         loading="eager" alt="" src="/line-4@2x.svg"/>
                </div>
                <h1 class="mq450:mt-[19px] m-0 w-full text-center h-[55px] relative text-inherit font-bold font-inherit inline-block max-w-full z-[1] mq450:text-6xl mq1050:text-15xl">
                    XENdeck Software </h1>
            </div>
        </div>
        <div class="flex flex-row items-start justify-start py-0 px-5 box-border gap-[73px] max-w-full text-grey mq1050:gap-[73px] mq750:gap-[20px] mq450:gap-[20px] leading-[1.2] flex-nowrap">
            <div @click="() => activeTab = 1"
                 class="cursor-pointer h-7 relative uppercase mq750:text-[12px] font-light inline-block mq450:p-0 border-white border-solid px-[25px]"
                 :class="[activeTab === 1 ? 'text-white-back border-b' : '']">
                AI applications
            </div>
            <div @click="() => activeTab = 2"
                 class="cursor-pointer h-7 relative uppercase mq750:text-[12px] font-light inline-block mq450:p-0 border-white border-solid px-[25px]"
                 :class="[activeTab === 2 ? 'text-white-back border-b' : '']">
                Development Tools
            </div>
            <div @click="() => activeTab = 3"
                 class="cursor-pointer h-7 relative uppercase mq750:text-[12px] font-light inline-block mq450:p-0 border-white border-solid px-[25px]"
                 :class="[activeTab === 3 ? 'text-white-back border-b' : '']">
                Application Platform
            </div>
        </div>
        <!--        <div class="w-[876px] flex flex-row items-start justify-start pt-0 px-5 pb-[61px] box-border max-w-full">-->
        <!--            <img class="h-px w-[226px] relative object-cover" loading="eager" alt="" src="/line-5@2x.png"/>-->
        <!--        </div>-->
        <div v-for="tab in tabsInfos.filter(t => t.tab === activeTab)" :key="tab.tab"
             class="animate-fade w-[1195px] mt-[65px] mq750:mt-[21px] flex flex-row items-center justify-start pt-0 px-5 pb-[107px] mq450:pb-0 box-border gap-[113px] max-w-full shrink-0 lg:gap-[113px] mq750:gap-[40px] mq750:pb-[70px] mq750:box-border mq1050:flex-wrap">
            <img class="h-[485.8px] mq450:hidden flex-1 relative max-w-full overflow-hidden object-contain min-w-[405px] mq750:min-w-full"
                 loading="eager" alt="" src="/group-256@2x.png"/>
            <div class="w-[419px] flex flex-col items-start justify-start pt-0 px-0 pb-[19px] box-border min-w-[419px] max-w-full shrink-0 mq750:min-w-full mq1050:flex-1">
                <div v-html="tab.text"
                     class="self-stretch flex flex-col items-start justify-start gap-[44px] max-w-full mq450:gap-[44px]"></div>
            </div>
        </div>
        <TurnkeyFrames xENdeckApplications="XENdeck customers"/>

        <div class="flex flex-row items-start justify-start relative max-w-full mt-[102px] mq750:hidden">
            <img class="h-[79.5px] w-[995px] absolute my-0 mx-[!important] right-[-206px] bottom-[-56.5px] object-contain"
                 loading="eager" alt="" src="/line-4@2x.svg" style="right: -100px;
    transform: rotateY(180deg) scalex(1.3);"/>
            <div class="w-[1440px] flex flex-row items-start justify-end pt-0 pb-36 px-0 box-border max-w-full mq750:pb-[94px] mq750:box-border">
                <div class="w-[2823px] flex flex-row items-start justify-start py-0 px-0 box-border max-w-[196%] shrink-0">
                    <div class="h-[468px] w-[3120px] relative max-w-[111%] shrink-0">
                        <div class="absolute top-[44px] left-[1354px] w-[1765.5px] h-[314.5px] flex items-center justify-center">
                            <img class="w-full h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.045)]"
                                 alt="" src="/line-6.svg"/>
                        </div>
                        <div class="absolute top-[0px] left-[1580.3px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
                            <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-328@2x.png"/>
                        </div>
                        <div class="absolute top-[0px] left-[1865.5px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
                            <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-325@2x.png"/>
                        </div>
                        <div class="absolute top-[0px] left-[2151.5px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
                            <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-320@2x.png"/>
                        </div>
                        <div class="absolute top-[0px] left-[2437.3px] w-[188.8px] h-[150px] z-[1] flex items-center justify-center">
                            <img class="w-full h-full z-[1] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-317@2x.png"/>
                        </div>
                        <div class="absolute top-[95px] left-[0px] w-[2217px] h-[373px]">
                            <div class="absolute top-[0px] left-[0px] w-full h-full z-[1] flex items-center justify-center">
                                <img class="w-full h-full z-[1] object-contain absolute left-[2px] top-[4px] [transform:scale(1.038)]"
                                     alt="" src="/line-17.svg"/>
                            </div>
                            <div class="absolute top-[155px] left-[1580px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
                                <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                                     loading="eager" alt="" src="/group-323@2x.png"/>
                            </div>
                            <div class="absolute top-[155px] left-[1865.3px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
                                <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                                     loading="eager" alt="" src="/group-327@2x.png"/>
                            </div>
                        </div>
                        <div class="absolute top-[250px] left-[2151.5px] w-[188.8px] h-[150px] z-[2] flex items-center justify-center">
                            <img class="w-full h-full z-[2] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-319.svg"/>
                        </div>
                        <div class="absolute top-[250px] left-[2437.3px] w-[188.8px] h-[150px] z-[1] flex items-center justify-center">
                            <img class="w-full h-full z-[1] object-contain absolute left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-326@2x.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden flex-row items-start justify-start relative max-w-full mt-[102px] mq750:flex mq750:mt-0">
            <div class="w-full flex flex-row items-start justify-end pt-0 pb-36 px-0 box-border max-w-full mq750:pb-[94px] mq750:box-border">
                <div class="w-full flex flex-row items-start justify-start py-0 px-0 box-border max-w-[196%] shrink-0">
                    <div class="h-[468px] w-full relative max-w-[111%] shrink-0 grid-cols-[repeat(3,_minmax(300px,_1fr))]"
                         style="
        display: grid;
    grid-template-columns: repeat(3, minmax(80px, auto));
    height: auto;
    gap: 26px;
    margin: auto;
    padding: 0 25px;
    box-sizing: border-box;
">
                        <div class="top-[95px] left-[0px] w-[2217px] h-[373px]" style="
         position: absolute;
    width: 100%;
    height: 45%;
    left: 0;
    bottom: 0;
    top: auto;
">
                            <div class="top-[0px] left-[0px] w-full h-full z-[1] flex items-center justify-center"><img style="    object-fit: cover;
    object-position: 150% 0px;"
                                class="w-full h-full z-[1] object-contain left-[2px] top-[4px] [transform:scale(1.038)]"
                                alt="" src="/line-17.svg"></div>
                        </div>
                        <div class="top-[44px] left-[1354px] w-[1765.5px] h-[314.5px] flex items-center justify-center"
                             style="    position: absolute;
    width: 100%;
    height: 45%;
    left: 0;
"><img class="w-full h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.045)]" style="    object-fit: cover;
    object-position: 59% 0px;" alt=""
       src="/line-6.svg"></div>
                        <div class="top-[0px] left-[1580.3px] w-auto h-auto z-[2] flex items-center justify-center"
                             style="
    left: 0;
    width: 100%;
"><img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]" loading="eager" alt=""
       src="/group-328@2x.png"></div>
                        <div class="top-[0px] left-[1865.5px] w-auto h-auto z-[2] flex items-center justify-center">
                            <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-325@2x.png"></div>
                        <div class="top-[0px] left-[2151.5px] w-auto h-auto z-[2] flex items-center justify-center">
                            <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-320@2x.png"></div>
                        <div class="top-[0px] left-[2437.3px] w-auto h-auto z-[1] flex items-center justify-center">
                            <img class="w-full h-full z-[1] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-317@2x.png"></div>
                        <div class="top-[250px] left-[2151.5px] w-auto h-auto z-[2] flex items-center justify-center">
                            <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-319.svg"></div>
                        <div class="top-[250px] left-[2437.3px] w-auto h-auto z-[1] flex items-center justify-center">
                            <img class="w-full h-full z-[1] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-326@2x.png"></div>
                        <div class="top-[155px] left-[1580px] w-auto h-auto z-[2] flex items-center justify-center">
                            <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-323@2x.png"></div>
                        <div class="top-[155px] left-[1865.3px] w-auto h-auto z-[2] flex items-center justify-center">
                            <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-327@2x.png"></div>
                        <div class="top-[155px] left-[1865.3px] w-auto h-auto z-[2] flex items-center justify-center">
                            <img class="w-full h-full z-[2] object-contain left-[0px] top-[4px] [transform:scale(1.093)]"
                                 loading="eager" alt="" src="/group-327@2x.png"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-[27px] flex flex-row items-start justify-start pt-0 pb-[18px] pr-[38px] pl-5 box-border max-w-full text-23xl" style="
    position: relative;
    width: 100%;
    text-align: center;
    margin: 0 auto;
"><img class="h-[79.5px] w-full absolute my-0 mx-[!important] right-[-206px] bottom-[-56.5px] object-contain hidden mq750:block" loading="eager" alt="" src="/line-4@2x.svg" style="/* right:-100px; */transform:rotateY(180deg) scalex(1.3);top: -50px;left: 0;width: 100%;object-fit: cover;text-align: center;"><h1 class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block z-[1] mq450:text-6xl mq1050:text-15xl" style="
    text-align: center;
    width: 100%;
"> XENdeck Studio </h1></div>
        <div class="w-[1104px] mq450:w-[90%] flex flex-row items-start justify-start pt-0 px-5 pb-[66px] box-border max-w-full shrink-0 text-center">
            <div class="h-[46px] flex-1 relative inline-block max-w-full mq450:text-base">
                <p class="m-0">
                    XENdeck provides an integrated family of development tools to meet the
                    needs </p>
                <p class="m-0">of different development communities.</p>
            </div>
        </div>
        <div class="flex flex-row items-start justify-start pt-0 px-5 pb-[172px] box-border relative max-w-full mq450:pb-28 mq450:box-border mq450:pb-[100px]">
            <div class="flex flex-row items-start justify-center gap-[77px] max-w-full lg:flex-wrap mq450:gap-[77px] mq750:gap-[77px]">
                <GroupFrame frame26="/frame-26@2x.png" dataScience="Data Science" line5="/line-5-2@2x.png"
                            xENUsesIndustryStandardTo="XEN uses industry standard tools, including Jupyter Notebook, R, Python, and Scala."/>
                <GroupFrame frame26="/frame-28@2x.png" dataScience="Deep Code" line5="/line-18@2x.png"
                            xENUsesIndustryStandardTo="XEN provides access to deep code through the Visual Studio, a popular source code editor."
                            propWidth="256px" propHeight="66px"/>
                <div class="h-[211px] w-[231px] flex flex-col items-center justify-start gap-[30px]">
                    <img class="w-20 h-20 relative overflow-hidden shrink-0 object-contain" loading="eager" alt=""
                         src="/frame-29@2x.png"/>
                    <div class="self-stretch flex-1 flex flex-col items-center justify-start gap-[15px]">
                        <div class="self-stretch h-8 flex flex-col items-center justify-start py-0 px-5 box-border gap-[6px]">
                            <b class="h-[26px] relative uppercase inline-block mq450:text-base">Low code</b>
                            <img class="self-stretch h-px relative max-w-full overflow-hidden shrink-0 object-cover"
                                 alt="" src="/line-19@2x.png"/>
                        </div>
                        <div class="flex-1 relative text-base">
                            <p class="m-0">XEN has a low code interface</p>
                            <p class="m-0">
                                to develop. deploy, and operate enterprise Al applications. </p>
                        </div>
                    </div>
                </div>
                <GroupFrame frame26="/frame-30@2x.png" dataScience="No Code" line5="/line-20.svg"
                            xENUsesIndustryStandardTo="XEN Ex Machina is a no code interface to design, develop, and deploy Al solutions and analytics."
                            propWidth="241px" propHeight="unset"/>
            </div>
            <div class="h-[79.5px] w-[1300px] absolute my-0 mx-[!important] bottom-[-47.5px] left-[-98.5px] z-[1] flex items-center justify-center">
                <img class="h-full w-full z-[1] object-contain absolute left-[2px] top-[4px] left-positioning" style="
    transform: scalex(1.3);" loading="eager" alt="" src="/line-19-1@2x.png"/>
            </div>
        </div>
        <div class="mt-[80px] flex flex-row items-start justify-start pt-0 px-5 pb-[72px] box-border max-w-full text-23xl">
            <h1 class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1050:text-15xl">
                Take the next step </h1>
        </div>
        <div class="w-[1280px] flex flex-row flex-wrap items-start justify-start pt-0 px-5 pb-[76px] box-border gap-[50px] max-w-full shrink-0 text-base mq750:gap-[50px] mq750:pb-0">
            <FrameAboutUsNews contactSales="Contact Sales"
                              learnHowOurIndustryLeadin="Learn how our industry-leading XENdeck AI software products can help your organization."/>
            <FrameAboutUsNews contactSales="Go to Investor
                        Relations"
                              learnHowOurIndustryLeadin="Contact us at support@xendeck.ai to learn more about investing at XENdeck."/>
<!--            <div class="flex-1 flex flex-col items-start justify-start gap-[15px] min-w-[285px] max-w-full">-->
<!--                <button class="cursor-pointer py-[22px] px-5 bg-[transparent] self-stretch rounded-12xs box-border flex flex-row items-center justify-start gap-[15px] max-w-full border-[1px] border-solid border-white-back hover:bg-gainsboro-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-gainsboro-100">-->
<!--                    <div class="h-[67px] w-[380px] relative rounded-12xs box-border hidden max-w-full border-[1px] border-solid border-white-back"/>-->
<!--                    <b class="relative text-lg uppercase font-helvetica text-white-back text-left z-[1]">Go to Investor-->
<!--                        Relations</b>-->
<!--                    <img class="h-[8.8px] w-[16.3px] relative z-[1]" alt="" src="/arrow-1-1.svg"/>-->
<!--                </button>-->
<!--                <div class="self-stretch h-12 relative font-light inline-block">-->
<!--                    <p class="[margin-block-start:0] [margin-block-end:4px]">-->
<!--                        Contact us at support@xendeck.ai to learn more </p>-->
<!--                    <p class="m-0">about investing at XENdeck.</p>-->
<!--                </div>-->
<!--            </div>-->
            <FrameAboutUsNews contactSales="request information"
                              learnHowOurIndustryLeadin="For all other questions, please contact us here."/>
        </div>
        <div class="self-stretch flex flex-row items-start justify-start pt-0 pb-1.5 pr-[5px] pl-0 box-border max-w-full">
            <div class="h-28 flex-1 relative shrink-0 flex items-center justify-center">
                <img class="h-full flex-1 max-w-[101%] left-positioning overflow-hidden shrink-0 object-contain absolute left-[0px] top-[4px] w-full [transform:scale(1.125)]"
                     loading="eager" alt="" src="/line-2-1@2x.svg" style="
    width: 1440px;
    transform: scalex(2)"/>
            </div>
        </div>
        <main class="mx-auto self-stretch flex flex-row items-start justify-start pt-0 px-0 box-border max-w-full lg:box-border ">
            <footer style="padding: 15px;width:100%;flex-wrap: nowrap;"
                    class="mq750:flex flex-1 mq750:gap-0 mq750:w-full hidden flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
                <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"></div>
                <div class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
                    <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]" style="        height: auto;
    width: 100px;
    position: absolute;"
                         loading="eager" alt="" src="/group-32@2x.png">
                    <!-- <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>
        -->        </div>
                <!--                &lt;div class=&quot;flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
                <!--                    --><!--                &lt;/div&gt;-->
                <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
                    <div class="flex flex-row items-start justify-start gap-[12px] flex-wrap" style="
    width: 100%;
">
                        <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
                        <a href="/news" class="relative z-[1] text-white">News</a>
                        <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>
                        <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
                        <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
                    </div>
                </div>
                <!--                &lt;div class=&quot;hidden mq750:flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
                <!--                    &lt;div class=&quot;relative z-[1]&quot;&gt;Contact Us&lt;/div&gt;-->
                <!--                    &lt;div class=&quot;w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full&quot;&gt;-->
                <!--                        &lt;div class=&quot;self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap&quot;&gt;-->
                <!--                            &lt;div class=&quot;flex flex-col items-start justify-start gap-[12px]&quot;&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;About Us&lt;/div&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;News&lt;/div&gt;-->
                <!--                            &lt;/div&gt;-->
                <!--                            &lt;div class=&quot;flex-[0.8557] flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1&quot;&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;AI Software&lt;/div&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Resourses&lt;/div&gt;-->
                <!--                            &lt;/div&gt;-->
                <!--                            &lt;div class=&quot;flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]&quot;&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Privacy Policy&lt;/div&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Terms of Use&lt;/div&gt;-->
                <!--                            &lt;/div&gt;--><!--                        &lt;/div&gt;-->
                <!--                    &lt;/div&gt;--><!--                &lt;/div&gt;--></footer>
            <footer style="    padding-left: 0;
    width: 1290px;"
                    class="flex-1 mq750:hidden flex flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:gap-[89px] mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
                <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"/>
                <div class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
                    <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
                         loading="eager" alt="" src="/group-32@2x.png"/>
                    <!-- <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>
        -->        </div>
                <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
                    <div class="self-stretch gap-[40px] flex flex-row items-start justify-between mq450:flex-wrap">
                        <div class="flex flex-col items-start justify-start px-0 pb-0">
                            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
                        </div>
                        <div class="flex flex-col items-start justify-start gap-[12px]">
                            <a href="/news" class="relative z-[1] text-white">News</a>
                        </div>
                        <div class="flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1">
                            <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>
<!--                            <div class="relative z-[1]">Resourses</div>-->
                        </div>
                        <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]">
                            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
                            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
                        </div>
                    </div>
                </div>
            </footer>
        </main>
    </div>
</template>
<script>
import {defineComponent} from "vue";
import TurnkeyFrames from "@/components/TurnkeyFrames.vue";
import GroupFrame from "@/components/GroupFrame.vue";
import FrameAboutUsNews from "@/components/FrameAboutUsNews.vue";
// import Header from "@/components/Header.vue";

export default defineComponent({
    name: "HomePage",
    data() {
        return {
            activeTab: 1,
            tabsInfos: [
                {
                    tab: 1,
                    text: '<div class="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[5px] box-border max-w-full">\n' +
                        '                        <div class="flex-1 relative inline-block max-w-full mq450:text-base">\n' +
                        '                            <p class="m-0">Ready-to-use enterprise fintech and</p>\n' +
                        '                            <p class="m-0">AI applications for high-value use cases.</p>\n' +
                        '                        </div>\n' +
                        '                    </div>\n' +
                        '                    <div class="w-[279px] h-[116px] relative inline-block mq450:text-base">\n' +
                        '                        <ul class="m-0 font-inherit text-inherit pl-[27px]">\n' +
                        '                            <li class="mb-2">Reliability Suite</li>\n' +
                        '                            <li class="mb-2">Supply Chain Suite</li>\n' +
                        '                            <li class="mb-2">Financial Services Suite</li>\n' +
                        '                            <li>Oil and Gas Suite</li>\n' +
                        '                        </ul>\n' +
                        '                    </div>'
                },
                {
                    tab: 2,
                    text: '<div class="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[5px] box-border max-w-full">\n' +
                        '                        <div class="flex-1 relative inline-block max-w-full mq450:text-base">\n' +
                        '                            <p class="m-0">2 Tab</p>\n' +
                        '                            <p class="m-0">AI applications for high-value use cases.</p>\n' +
                        '                        </div>\n' +
                        '                    </div>\n' +
                        '                    <div class="w-[279px] h-[116px] relative inline-block mq450:text-base">\n' +
                        '                        <ul class="m-0 font-inherit text-inherit pl-[27px]">\n' +
                        '                            <li class="mb-2">Reliability Suite</li>\n' +
                        '                            <li class="mb-2">Supply Chain Suite</li>\n' +
                        '                            <li class="mb-2">Financial Services Suite</li>\n' +
                        '                            <li>Oil and Gas Suite</li>\n' +
                        '                        </ul>\n' +
                        '                    </div>'
                },
                {
                    tab: 3,
                    text: '<div class="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[5px] box-border max-w-full">\n' +
                        '                        <div class="flex-1 relative inline-block max-w-full mq450:text-base">\n' +
                        '                            <p class="m-0">3 Tab</p>\n' +
                        '                            <p class="m-0">AI applications for high-value use cases.</p>\n' +
                        '                        </div>\n' +
                        '                    </div>\n' +
                        '                    <div class="w-[279px] h-[116px] relative inline-block mq450:text-base">\n' +
                        '                        <ul class="m-0 font-inherit text-inherit pl-[27px]">\n' +
                        '                            <li class="mb-2">Reliability Suite</li>\n' +
                        '                            <li class="mb-2">Supply Chain Suite</li>\n' +
                        '                            <li class="mb-2">Financial Services Suite</li>\n' +
                        '                            <li>Oil and Gas Suite</li>\n' +
                        '                        </ul>\n' +
                        '                    </div>'
                }
            ]
        }
    },
    components: {
        TurnkeyFrames, GroupFrame, FrameAboutUsNews,
        // Header
    },
});
</script>

<style lang="scss">
.image-card:hover {
  .image {
    @apply animate-burns;
  }
}

.image-card {
  cursor: pointer;

  .image {
    @apply animate-burnsf;
    //animation-direction: reverse;
  }
}

.image-rectangle {
  @apply bg-[url('/public/home_rectandle_3.png')];
  background-repeat: no-repeat;
  background-position: 0px 2px;
  margin-top: 4px;
  background-size: 150px 35px;
  z-index: 9;
}
</style>
