<template>
  <div
    class="self-stretch flex flex-col items-center justify-start py-[30px] pr-5 pl-[21px] box-border relative gap-[30px] min-h-[168px] max-w-full z-[1] text-left text-xl text-white-back font-helvetica"
  >
    <div
      class="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-center justify-center z-[0]"
    >
      <img
        class="w-full h-full max-w-full overflow-hidden max-h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.083)]"
        alt=""
        src="/aisoftware/group-271.svg"
      />
    </div>
    <div class="w-[726px] flex flex-row items-start justify-center max-w-full">
      <div
        class="h-[34px] w-[260px] flex flex-col items-start justify-start gap-[6px_0px]"
      >
        <div
          class="flex flex-row items-start justify-start py-0 pr-[22px] pl-[23px]"
        >
          <b class="h-7 relative uppercase inline-block z-[1] mq450:text-base"
            >Platform Services</b
          >
        </div>
        <img
          class="self-stretch h-px relative max-w-full overflow-hidden shrink-0 z-[1]"
          alt=""
          src="/aisoftware/line-5-3.svg"
        />
      </div>
    </div>
    <div
      class="w-[726px] flex flex-row items-start justify-between gap-[20px] max-w-full mq750:flex-wrap"
    >
      <div
        class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
      >
        architecture
      </div>
      <div
        class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
      >
        security
      </div>
      <div
        class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
      >
        administration
      </div>
      <div
        class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
      >
        deployment
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "GroupFrame4",
  });
</script>
