<template>
  <div
    class="w-[243px] flex flex-col items-center justify-start gap-[30px] text-left text-xl text-white-back font-helvetica"
    :style="groupDivStyle"
  >
    <img
      class="w-20 h-20 relative overflow-hidden shrink-0 object-cover"
      loading="eager"
      alt=""
      :src="frame26"
    />
    <div
      class="self-stretch flex flex-col items-center justify-start gap-[15px]"
    >
      <div
        class="self-stretch h-8 flex flex-col items-center justify-start py-0 px-5 box-border gap-[6px]"
      >
        <b class="h-[26px] relative uppercase inline-block mq450:text-base"
          >{{dataScience}}</b
        >
        <img
          class="self-stretch h-px relative max-w-full overflow-hidden shrink-0 object-cover"
          loading="eager"
          alt=""
          :src="line5"
        />
      </div>
      <div
        class="self-stretch relative text-base"
        :style="xENUsesIndustryStyle"
      >
        {{xENUsesIndustryStandardTo}}
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "GroupFrame",
    props: {
      frame26: { type: String },
      dataScience: { type: String },
      line5: { type: String },
      xENUsesIndustryStandardTo: { type: String },
      propWidth: { type: [Object, Array, String, Number, Boolean] },
      propHeight: { type: [Object, Array, String, Number, Boolean] },
    },
    computed: {
      groupDivStyle() {
        return {
          width: this.propWidth,
        };
      },
      xENUsesIndustryStyle() {
        return {
          height: this.propHeight,
        };
      },
    },
  });
</script>
