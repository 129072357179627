<template>
    <div class="w-full relative bg-d2d3c overflow-hidden tracking-[normal] mq1025:h-auto mq1025:min-h-[8002]">
        <div class="h-[840px] mq750:h-[900px]" style="background: #23232e;filter: brightness(99%);">
            <img class="absolute z-[1] object-[100px 10px] object-cover mq450:object-left h-[728px] mq750:h-[500px] w-full top-[25px] right-[-58.33%] bottom-[90.49%] left-[40.63%] max-w-full overflow-hidden max-h-full mq750:object-left mq750:left-0 mq750:object-none"
                 alt="" src="/aisoftware/group-398.svg"/>
            <section
                    class="absolute top-[76px] left-[0px] w-full h-full flex flex-col items-end justify-start max-w-full">
                <CousinQueue/>
                <MergingNodes @click="toggle"/>
            </section>
        </div>
        <div class="relative h-0 z-9">
            <transition name="fade">
                <section v-if="active" @mouseover="open" style="width: calc(100% - 30px) !important;"
                         :class="`h-auto w-full absolute shadow-md z-[9] left-0 top-0 mt-[-10px] w-full p-0 mx-[15px] w-auto rounded mq750:flex hidden left-0 top-0 bg-[#23232e] flex-row items-start justify-center box-border tracking-[normal]`">
                    <section
                            class="w-full flex flex-row flex-wrap items-start justify-start gap-[62px_129.27px] min-h-[198px] lg:gap-[62px_129.27px] mq450:gap-[62px_129.27px] mq750:gap-[62px_129.27px]">
                        <div @mouseover="open" class="w-full flex flex-col items-start justify-between gap-[30px]">

                            <div v-if="backNavigation.length"
                                 @click="() => {currentNavigation = [...backNavigation]; backNavigation = []}"
                                 class="inline flex-col items-start justify-start py-0 pr-[3px] pl-0 box-border">
                                <router-link to="#"
                                             class="text-[16px] ml-[20px]  relative font-light inline-block z-[1] text-[#B6B6B6]">
                                    <img style="transform: rotateY(180deg); filter: brightness(5);" class="mr-[10px]"
                                         src="/arrow_navigation_mobile.svg"/> BACK
                                </router-link>
                            </div>

                            <div @mouseover="open" v-for="(item, index) in currentNavigation" :key="index" @click="() => {
                                if (!item.children) return;
                                backNavigation = [...currentNavigation]; currentNavigation = [...item.children]
                            }"
                                 class="flex flex-col items-start justify-start py-[20px] pr-[3px] pl-0 box-border gap-[16px]">
                                <router-link :to="item.link || '#'"
                                             class="text-[16px] relative font-light inline-block z-[1] text-[#B6B6B6] uppercase px-[20px]">
                                    {{ item.name }} <img v-if="item.children" class="ml-[10px]"
                                                         style="filter: brightness(5);"
                                                         src="/arrow_navigation_mobile.svg"/>

                                </router-link>
                            </div>
                        </div>
                    </section>
                </section>
            </transition>
        </div>

        <section
                class="h-[162px] w-full p-0 hidden mq750:flex top-[923px] left-[102px] box-border w-[1239px] flex-row items-start justify-center px-5 max-w-full z-[1] text-left text-[96px] text-white-back font-helvetica border-[1px] border-solid border-gray-300"
                style="left: 0px;top: auto;bottom: -110px;height: auto;border: none !important;">
            <img class="h-[162px] w-full absolute left-0 top-0 object-contain" alt=""
                 src="/aisoftware/star-nodes@2x.png" style="height: auto; position: static;">
            <h1 class="m-0 h-[132px] left-0 w-[694px] absolute text-inherit uppercase font-light font-inherit inline-block max-w-full z-[2] mq450:text-10xl mq1025:text-29xl"
                style="font-size: 32px; text-align: center; align-self: center; height: auto; position: absolute;">
                Placeholder </h1>

        </section>

        <!--      ##DESKTOP ##-->

        <section
                class="mx-[100px] mt-[117px] relative border-none w-auto p-0 flex mq750:hidden box-border w-[1239px] flex-row items-start justify-center px-5 max-w-full z-[1] text-left text-[96px] text-white-back font-helvetica border-[1px] border-solid border-gray-300"
                style="

    align-items: center;
    text-align: center;
" data-v-5c83e013=""><img class="w-full object-contain" alt="" src="/aisoftware/star-nodes@2x.png" style=""
                          data-v-5c83e013="">
            <h1 class="m-0 h-[132px] left-0 w-[694px] absolute text-inherit uppercase font-light font-inherit inline-block max-w-full z-[2] mq450:text-10xl mq1025:text-29xl text-center"
                style="position:absolute;width: 100%;" data-v-5c83e013=""> Placeholder </h1>
        </section>
        <img class="w-full h-full max-w-full mt-[90px] mq750:mt-0 overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
             loading="eager" alt="" src="/line-1@2x.svg" data-v-5c83e013="" style="
    height: 112px;
    object-fit: fill;
    width: 100%;
">
        <div class="flex mq750:hidden mx-[100px]" data-v-5c83e013="" style="
    align-items: end;
    gap: 54px;
">
            <section
                    class="h-[162px] w-full relative p-0 flex mq750:hidden top-[923px] left-[102px] box-border w-[1239px] flex-row items-start justify-center max-w-full z-[1] text-left text-[96px] text-white-back font-helvetica border-[1px] border-solid border-gray-300"
                    style="left:0px;top:auto;/* bottom:-110px; */height:auto;border:none;" data-v-5c83e013=""><img
                    class="h-[162px] w-full left-0 top-0 object-contain" alt="" src="/aisoftware/12012024-130040@2x.png"
                    style="height:auto;position:static;" data-v-5c83e013="">
                <h1 class="m-0 h-[132px] w-full left-0 w-[694px] absolute text-inherit uppercase font-light font-inherit inline-block max-w-full z-[2] mq450:text-10xl mq1025:text-29xl"
                    style="font-size:32px;text-align:center;align-self:center;height:auto;position:absolute;"
                    data-v-5c83e013=""> Placeholder </h1></section>
            <div class="max-w-[478px] text-white" data-v-5c83e013="" style="width:auto;"><h1 data-v-5c83e013="" style="
    font-size: 42px;
">One Platform for All</h1><h4 class="mt-[20px]" data-v-5c83e013="" style="
    font-size: 32px;
    margin: 30px 0 0 0;
">Enterprise AI for everyone</h4>
                <p data-v-5c83e013="" style="
    font-weight: 100;
    font-size: 20px;
    margin: 10px 0 0 0;
    line-height: 1.4;
">Data science, IT, and business teams can work together seamlessly on one powerful platform to deliver the full power
                    of enterprise AI.</p></div>
        </div>
        <div class="flex mq750:hidden mx-[100px] mt-[120px] flex-row-reverse" data-v-5c83e013="" style="
    align-items: end;
    gap: 54px;
">
            <section
                    class="h-[162px] w-full relative p-0 flex mq750:hidden top-[923px] left-[102px] box-border w-[1239px] flex-row items-start justify-center max-w-full z-[1] text-left text-[96px] text-white-back font-helvetica border-[1px] border-solid border-gray-300"
                    style="left:0px;top:auto;/* bottom:-110px; */height:auto;border:none;" data-v-5c83e013=""><img
                    class="h-[162px] w-full left-0 top-0 object-contain" alt=""
                    src="/aisoftware/12012024-151600-1@2x.png" style="height:auto;position:static;" data-v-5c83e013="">
                <h1 class="m-0 h-[132px] w-full left-0 w-[694px] absolute text-inherit uppercase font-light font-inherit inline-block max-w-full z-[2] mq450:text-10xl mq1025:text-29xl"
                    style="font-size:32px;text-align:center;align-self:center;height:auto;position:absolute;"
                    data-v-5c83e013=""> Placeholder </h1></section>
            <div class="max-w-[478px] text-white" data-v-5c83e013="" style="width:auto;"><h1 data-v-5c83e013="" style="
    font-size: 42px;
">One Platform for All</h1><h4 class="mt-[20px]" data-v-5c83e013="" style="
    font-size: 32px;
    margin: 30px 0 0 0;
">Enterprise AI for everyone</h4>
                <p data-v-5c83e013="" style="
    font-weight: 100;
    font-size: 20px;
    margin: 10px 0 0 0;
    line-height: 1.4;
">Data science, IT, and business teams can work together seamlessly on one powerful platform to deliver the full power
                    of enterprise AI.</p></div>
        </div>
        <div class="flex mq750:hidden mx-[100px] mt-[120px]" data-v-5c83e013="" style="
    align-items: end;
    gap: 54px;
">
            <section
                    class="h-[162px] w-full relative p-0 flex mq750:hidden top-[923px] left-[102px] box-border w-[1239px] flex-row items-start justify-center max-w-full z-[1] text-left text-[96px] text-white-back font-helvetica border-[1px] border-solid border-gray-300"
                    style="left:0px;top:auto;/* bottom:-110px; */height:auto;border:none;" data-v-5c83e013=""><img
                    class="h-[162px] w-full left-0 top-0 object-contain" alt="" src="/aisoftware/12012024-130040@2x.png"
                    style="height:auto;position:static;" data-v-5c83e013="">
                <h1 class="m-0 h-[132px] w-full left-0 w-[694px] absolute text-inherit uppercase font-light font-inherit inline-block max-w-full z-[2] mq450:text-10xl mq1025:text-29xl"
                    style="font-size:32px;text-align:center;align-self:center;height:auto;position:absolute;"
                    data-v-5c83e013=""> Placeholder </h1></section>
            <div class="max-w-[478px] text-white" data-v-5c83e013="" style="width:auto;"><h1 data-v-5c83e013="" style="
    font-size: 42px;
">One Platform for All</h1><h4 class="mt-[20px]" data-v-5c83e013="" style="
    font-size: 32px;
    margin: 30px 0 0 0;
">Enterprise AI for everyone</h4>
                <p data-v-5c83e013="" style="
    font-weight: 100;
    font-size: 20px;
    margin: 10px 0 0 0;
    line-height: 1.4;
">Data science, IT, and business teams can work together seamlessly on one powerful platform to deliver the full power
                    of enterprise AI.</p></div>
        </div>
        <div class="flex mq750:hidden mx-[100px] mt-[120px] flex-row-reverse" data-v-5c83e013="" style="
    align-items: end;
    gap: 54px;
">
            <section
                    class="h-[162px] w-full relative p-0 flex mq750:hidden top-[923px] left-[102px] box-border w-[1239px] flex-row items-start justify-center max-w-full z-[1] text-left text-[96px] text-white-back font-helvetica border-[1px] border-solid border-gray-300"
                    style="left:0px;top:auto;/* bottom:-110px; */height:auto;border:none;" data-v-5c83e013=""><img
                    class="h-[162px] w-full left-0 top-0 object-contain" alt=""
                    src="/aisoftware/12012024-151600-1@2x.png" style="height:auto;position:static;" data-v-5c83e013="">
                <h1 class="m-0 h-[132px] w-full left-0 w-[694px] absolute text-inherit uppercase font-light font-inherit inline-block max-w-full z-[2] mq450:text-10xl mq1025:text-29xl"
                    style="font-size:32px;text-align:center;align-self:center;height:auto;position:absolute;"
                    data-v-5c83e013=""> Placeholder </h1></section>
            <div class="max-w-[478px] text-white" data-v-5c83e013="" style="width:auto;"><h1 data-v-5c83e013="" style="
    font-size: 42px;
">One Platform for All</h1><h4 class="mt-[20px]" data-v-5c83e013="" style="
    font-size: 32px;
    margin: 30px 0 0 0;
">Enterprise AI for everyone</h4>
                <p data-v-5c83e013="" style="
    font-weight: 100;
    font-size: 20px;
    margin: 10px 0 0 0;
    line-height: 1.4;
">Data science, IT, and business teams can work together seamlessly on one powerful platform to deliver the full power
                    of enterprise AI.</p></div>
        </div>
        <!--        <img class="h-[79.5px] w-[995px] absolute my-0 mx-[!important] right-[-206px] bottom-[-56.5px] object-contain"-->
        <!--             loading="eager" alt="" src="/line-4@2x.svg" style="right: -100px;-->
        <!--    transform: rotateY(180deg) scalex(1.3);"/>-->

        <div class="mx-[20px] mt-[-60px] hidden mq750:block">
            <div class="text-white">
                <div class="max-w-[80%]">
                    <h1>One Platform for All</h1>
                    <h4 class="mt-[20px]">Enterprise AI for everyone</h4>
                    <p>Data science, IT, and business teams can
                        work together seamlessly on one powerful
                        platform to deliver the full power of enterprise AI.</p>
                </div>
                <section
                        class="h-[162px] w-full p-0 hidden mq750:flex top-[923px] left-[102px] box-border w-[1239px] flex-row items-start justify-center max-w-full z-[1] text-left text-[96px] text-white-back font-helvetica border-[1px] border-solid border-gray-300"
                        style="left: 0px;top: auto;bottom: -110px;height: auto;border: none;">
                    <img class="h-[162px] w-full absolute left-0 top-0 object-contain" alt=""
                         src="/aisoftware/12012024-130040@2x.png" style="height: auto; position: static;">
                    <h1 class="m-0 h-[132px] left-0 w-[694px] absolute text-inherit uppercase font-light font-inherit inline-block max-w-full z-[2] mq450:text-10xl mq1025:text-29xl"
                        style="font-size: 32px; text-align: center; align-self: center; height: auto; position: absolute;">
                        Placeholder </h1>

                </section>
                <router-link to="#"
                             class="text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                    Learn more
                    about XENdeck Studio <img class="ml-[10px]" style="filter: brightness(4);
            height: 10px;" src="/arrow_navigation_mobile.svg"/>
                </router-link>
            </div>
        </div>


        <div class="mx-[20px] mt-[50px] hidden mq750:block">
            <div class="text-white">
                <div class="max-w-[80%]">
                    <h1>One Platform for All</h1>
                    <h4 class="mt-[20px]">Enterprise AI for everyone</h4>
                    <p>Data science, IT, and business teams can
                        work together seamlessly on one powerful
                        platform to deliver the full power of enterprise AI.</p>
                </div>
                <section
                        class="h-[162px] w-full p-0 hidden mq750:flex top-[923px] left-[102px] box-border w-[1239px] flex-row items-start justify-center max-w-full z-[1] text-left text-[96px] text-white-back font-helvetica border-[1px] border-solid border-gray-300"
                        style="left: 0px;top: auto;bottom: -110px;height: auto;border: none;">
                    <img class="h-[162px] w-full absolute left-0 top-0 object-contain" alt=""
                         src="/aisoftware/12012024-130040@2x.png" style="height: auto; position: static;">
                    <h1 class="m-0 h-[132px] left-0 w-[694px] absolute text-inherit uppercase font-light font-inherit inline-block max-w-full z-[2] mq450:text-10xl mq1025:text-29xl"
                        style="font-size: 32px; text-align: center; align-self: center; height: auto; position: absolute;">
                        Placeholder </h1>

                </section>
                <router-link to="#"
                             class="text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                    Learn more
                    about XENdeck Studio <img class="ml-[10px]" style="filter: brightness(4);
            height: 10px;" src="/arrow_navigation_mobile.svg"/>
                </router-link>
            </div>
        </div>

        <div class="mx-[20px] mt-[50px] hidden mq750:block">
            <div class="text-white">
                <div class="max-w-[80%]">
                    <h1>One Platform for All</h1>
                    <h4 class="mt-[20px]">Enterprise AI for everyone</h4>
                    <p>Data science, IT, and business teams can
                        work together seamlessly on one powerful
                        platform to deliver the full power of enterprise AI.</p>
                </div>
                <section
                        class="h-[162px] w-full p-0 hidden mq750:flex top-[923px] left-[102px] box-border w-[1239px] flex-row items-start justify-center max-w-full z-[1] text-left text-[96px] text-white-back font-helvetica border-[1px] border-solid border-gray-300"
                        style="left: 0px;top: auto;bottom: -110px;height: auto;border: none;">
                    <img class="h-[162px] w-full absolute left-0 top-0 object-contain" alt=""
                         src="/aisoftware/12012024-130040@2x.png" style="height: auto; position: static;">
                    <h1 class="m-0 h-[132px] left-0 w-[694px] absolute text-inherit uppercase font-light font-inherit inline-block max-w-full z-[2] mq450:text-10xl mq1025:text-29xl"
                        style="font-size: 32px; text-align: center; align-self: center; height: auto; position: absolute;">
                        Placeholder </h1>

                </section>
                <router-link to="#"
                             class="text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                    Learn more
                    about XENdeck Studio <img class="ml-[10px]" style="filter: brightness(4);
            height: 10px;" src="/arrow_navigation_mobile.svg"/>
                </router-link>
            </div>
        </div>


        <div class="mx-[20px] mt-[50px] hidden mq750:block">
            <div class="text-white">
                <div class="max-w-[80%]">
                    <h1>One Platform for All</h1>
                    <h4 class="mt-[20px]">Enterprise AI for everyone</h4>
                    <p>Data science, IT, and business teams can
                        work together seamlessly on one powerful
                        platform to deliver the full power of enterprise AI.</p>
                </div>
                <section
                        class="h-[162px] w-full p-0 hidden mq750:flex top-[923px] left-[102px] box-border w-[1239px] flex-row items-start justify-center max-w-full z-[1] text-left text-[96px] text-white-back font-helvetica border-[1px] border-solid border-gray-300"
                        style="left: 0px;top: auto;bottom: -110px;height: auto;border: none;">
                    <img class="h-[162px] w-full absolute left-0 top-0 object-contain" alt=""
                         src="/aisoftware/12012024-130040@2x.png" style="height: auto; position: static;">
                    <h1 class="m-0 h-[132px] left-0 w-[694px] absolute text-inherit uppercase font-light font-inherit inline-block max-w-full z-[2] mq450:text-10xl mq1025:text-29xl"
                        style="font-size: 32px; text-align: center; align-self: center; height: auto; position: absolute;">
                        Placeholder </h1>

                </section>
                <router-link to="#"
                             class="text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                    Learn more
                    about XENdeck Studio <img class="ml-[10px]" style="filter: brightness(4);
            height: 10px;" src="/arrow_navigation_mobile.svg"/>
                </router-link>
            </div>
        </div>

        <img class="w-full h-full mq750:hidden max-w-full mt-[90px] mq750:mt-0 overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
             loading="eager" alt="" src="/line-1@2x.svg" data-v-5c83e013="" style="
    height: 112px;
    object-fit: fill;
    transform: rotateY(180deg);
    width: 100%;
">

        <div class="mq750:hidden block mx-[140px] relative">
            <img class="absolute left-0 bottom-[-5px] ml-[-140px]" src="/aisoftware/Line-555.svg" alt="">
            <img class="absolute left-0 top-[250px] ml-[-140px]" src="/aisoftware/Line17777.svg" alt="">
            <h1 class="text-white text-center m-auto mb-[100px] mt-[-50px]">Complete Capabilities
                for AI At-Scale</h1>
            <div class="border-[3px] px-[90px] py-[68px] gap-[150px] flex flex-row items-center justify-center border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430; position: relative; background: #2d2d3c;
">
                <img src="/aisoftware/group-407.svg" alt="">
                <img src="/aisoftware/group-408.svg" alt="">
            </div>

            <div class="grid gap-[18px] grid-cols-[repeat(3,375px)]">
                <div class="border-[3px] leading-8 pt-[40px] pb-[30px] text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430; line-height: 2.5;
    justify-content: flex-start;     background: #2d2d3c; position:relative; z-index:1;

">
                    <h4 class="uppercase text-[20px] px-[20px] border-b border-white border-solid leading-[1.3] mb-[10px]"
                        style="border-color: white !important;">DATA SCIENCE</h4>
                    <p class="uppercase text-[20px]">Integration</p>
                    <p class="uppercase text-[20px]">preparation</p>
                    <p class="uppercase text-[20px]">lineage & veracity</p>
                    <p class="uppercase text-[20px]">visualization</p>
                    <p class="uppercase text-[20px]">graph</p>
                    <p class="uppercase text-[20px]">governance</p>
                </div>

                <div class="border-[3px] leading-8 pt-[40px] pb-[30px] text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430; line-height: 2.5;
    justify-content: flex-start;     background: #2d2d3c; position:relative; z-index:1;
">
                    <h4 class="uppercase text-[20px] px-[20px] border-b border-white border-solid leading-[1.3] mb-[10px]"
                        style="border-color: white !important;">AI</h4>
                    <p class="uppercase text-[20px]">FEATURE STORE</p>
                    <p class="uppercase text-[20px]">MODEL DEVELOPMENT</p>
                    <p class="uppercase text-[20px]">model ops</p>
                    <p class="uppercase text-[20px]">responsible ai</p>
                </div>

                <div class="border-[3px] leading-8 pt-[40px] pb-[30px] text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430; line-height: 2.5;
    justify-content: flex-start;     background: #2d2d3c; position:relative; z-index:1;
">
                    <h4 class="uppercase text-[20px] px-[20px] border-b border-white border-solid leading-[1.3] mb-[10px]"
                        style="border-color: white !important;">Application Development</h4>
                    <p class="uppercase text-[20px]">data models</p>
                    <p class="uppercase text-[20px]">ui development</p>
                    <p class="uppercase text-[20px]">devops</p>
                </div>
            </div>

            <div class="border-[3px] h-[168px] leading-8 pt-[40px] pb-[30px] text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430; line-height: 2.5;
    justify-content: flex-start;     background: #2d2d3c; position:relative; z-index:1;
">
                <h4 class="uppercase text-[20px] px-[20px] border-b border-white border-solid leading-[1.3] mb-[10px]"
                    style="border-color: white !important;">platform services</h4>
                <div class="flex justify-center gap-[60px] text-[20px]">
                    <p class="uppercase">architecture</p>
                    <p class="uppercase">security</p>
                    <p class="uppercase">administration</p>
                    <p class="uppercase">deployment</p>
                </div>
            </div>
        </div>

        <div class="mq750:hidden block mx-[140px] relative">
            <h1 class="text-white text-center m-auto mb-[100px] mt-[160px]">Powering World’s Most Iconic
                Organizations</h1>

            <div class="border-[3px] w-full text-white text-center mt-[20px] flex flex-row items-center justify-between border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                <img class="border-b-[3px] w-[474px] border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/76zb9kx9zd14jmu52ymi4wxk2m5iw62l-1@2x.png">
                <div class="flex flex-col items-start justify-between px-[40px] h-[292px] py-[64px] w-full box-border">
                    <h4 class="text-[36px] text-left  m-0">Enterprise AI at Shell</h4>
                    <router-link to="#"
                                 class="text-left text-[20px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                        Learn more
                    </router-link>
                </div>
            </div>

            <div class="flex flex-row gap-[30px] mt-[30px]">
                <div class="border-[3px] w-[50%] text-white text-center flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                    <img class="border-b-[3px] w-full h-[240px] border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/global-b.png">
                    <div class="flex flex-col items-start justify-between px-[15px] py-[32px] pb-[15px] min-h-[119px] w-full box-border">
                        <h4 class="text-[16px] text-left  m-0">AI-Enhanced Modeling and Simulation
                            in the DoD - Moving from the Lab
                            to the Enterprise</h4>
                        <router-link to="#"
                                     class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                            Learn more
                        </router-link>
                    </div>
                </div>

                <div class="border-[3px] w-[50%] text-white text-center flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                    <img class="border-b-[3px] h-[240px] w-full border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/closeup-of-industrial.png">
                    <div class="flex flex-col items-start justify-between px-[15px] py-[32px] pb-[15px] min-h-[119px] w-full box-border">
                        <h4 class="text-[16px] text-left  m-0">Optimize Production Schedules
                            and Decrease Manufacturing Costs</h4>
                        <router-link to="#"
                                     class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                            Learn more
                        </router-link>
                    </div>
                </div>
            </div>

        </div>

        <img class="w-full h-full mq750:hidden max-w-full mt-[90px] mq750:mt-0 overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
             loading="eager" alt="" src="/line-1@2x.svg" data-v-5c83e013="" style="
    height: 112px;
    object-fit: fill;
    width: 100%;
">

        <div class="mq750:hidden block mx-[140px] relative">
            <h1 class="text-white text-center m-auto mb-[55px] mt-[-30px]">A Recognized Enterprise AI Leader</h1>

            <div class="grid gap-[37px] grid-cols-[repeat(4,281px)] justify-center">
                <div class="border-[3px] h-[360px] text-white text-center mt-[20px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                    <div class="justify-between px-[40px] pb-[35px] h-full  flex flex-col items-center">
                        <img class="pt-[50px] max-w-full m-auto max-h-[185px]" src="/aisoftware/idclogoblue.svg">
                        <div class="flex text-[20px] flex-col items-start py-[15px] min-h-[77px] w-full box-border"
                             style="
    padding-bottom: 0;
    justify-content: end;
    text-align: center;
    align-items: center;">
                            <p>IDC MarketScape</p>
                            <p>(2016, 2022)</p>
                        </div>
                    </div>
                </div>

                <div class="border-[3px] h-[360px] text-white text-center mt-[20px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                    <div class="justify-between px-[40px] pb-[35px] h-full  flex flex-col items-center">
                        <img class="max-w-full mt-auto max-h-[185px]" src="/aisoftware/googlecloudpartneroftheyearbadge768x574@2x.png">
                        <div class="flex text-[20px] flex-col items-start py-[15px] min-h-[77px] w-full box-border"
                             style="
    padding-bottom: 0;
    justify-content: end;
    text-align: center;
    align-items: center;">
                            <p>Google Cloud</p>
                            <p>Partner of the Year</p>
                        </div>
                    </div>
                </div>

                <div class="border-[3px] h-[360px] text-white text-center mt-[20px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                    <div class="justify-between h-full px-[40px] pb-[35px] flex flex-col items-center">
                        <img class="pt-[50px] max-w-full m-auto max-h-[185px]" src="/aisoftware/omdialogonew-1@2x.png">
                        <div class="flex text-[20px] flex-col items-start py-[15px] min-h-[77px] w-full box-border"
                             style="
    padding-bottom: 0;
    justify-content: end;
    text-align: center;
    align-items: center;">
                            <p>Leader in enterprise</p>
                            <p>Development platform</p>
                        </div>
                    </div>
                </div>


                <div class="border-[3px] h-[360px] text-white text-center mt-[20px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                    <div class="justify-between px-[40px] pb-[35px] h-full  flex flex-col items-center">
                        <img class="max-w-full mt-auto max-h-[185px]" src="/aisoftware/forbescloud100award.svg">
                        <div class="flex text-[20px] flex-col items-start py-[15px] min-h-[77px] w-full box-border"
                             style="
    padding-bottom: 0;
    justify-content: end;
    text-align: center;
    align-items: center;">
                            <p>Forbes cloud</p>
                            <p>100 standout( 2020)</p>
                        </div>
                    </div>
                </div>

                </div>

        </div>

        <img class="w-full h-full max-w-full mt-[90px] mq750:mt-0 overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
             loading="eager" alt="" src="/line-1@2x.svg" data-v-5c83e013="" style="
    height: 112px;
    object-fit: fill;
    transform: rotateY(180deg);
    width: 100%;
">

        <div class="mq750:hidden block mx-[140px] relative">
            <h1 class="text-white text-center m-auto mb-[30px] mt-[-30px]">Related Resources</h1>

            <div class="flex gap-[20px]">
                <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    background-color: #242430;
">
                    <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/glowingmolecularstructureinfuturisticgalaxybackdropgeneratedbyai-1@2x.png">
                    <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                        <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                            of AI Product Suite</h4>
                        <div class="flex justify-between w-full ">
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                Announcements
                            </router-link>
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                View
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    background-color: #242430;
">
                    <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/onepersontypingonfuturisticglowingcomputergeneratedbyai-1@2x.png">
                    <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                        <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                            of AI Product Suite</h4>
                        <div class="flex justify-between w-full">
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                Announcements
                            </router-link>
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                View
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    background-color: #242430;
">
                    <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/keyboardsocialbusinessyoungperson-1@2x.png">
                    <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                        <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                            of AI Product Suite</h4>
                        <div class="flex justify-between w-full">
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                Announcements
                            </router-link>
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                View
                            </router-link>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="mx-[20px] hidden mq750:block">
            <h1 class="text-white text-center m-auto mb-[30px]">Complete Capabilities
                for AI At-Scale</h1>
            <div class="border-[3px] px-[90px] py-[68px] gap-[69px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                <img src="/aisoftware/group-407.svg" alt="">
                <img src="/aisoftware/group-408.svg" alt="">
            </div>

            <div class="border-[3px] leading-8 pt-[40px] pb-[30px] text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                <h4 class="uppercase text-[16px] px-[20px] border-b border-white border-solid leading-[1.3] mb-[10px]"
                    style="border-color: white !important;">DATA SCIENCE</h4>
                <p class="uppercase text-[14px]">Integration</p>
                <p class="uppercase text-[14px]">preparation</p>
                <p class="uppercase text-[14px]">lineage & veracity</p>
                <p class="uppercase text-[14px]">visualization</p>
                <p class="uppercase text-[14px]">graph</p>
                <p class="uppercase text-[14px]">governance</p>
            </div>

            <div class="border-[3px] leading-8 pt-[40px] pb-[30px] text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                <h4 class="uppercase text-[16px] px-[20px] border-b border-white border-solid leading-[1.3] mb-[10px]"
                    style="border-color: white !important;">AI</h4>
                <p class="uppercase text-[14px]">FEATURE STORE</p>
                <p class="uppercase text-[14px]">MODEL DEVELOPMENT</p>
                <p class="uppercase text-[14px]">model ops</p>
                <p class="uppercase text-[14px]">responsible ai</p>
            </div>

            <div class="border-[3px] leading-8 pt-[40px] pb-[30px] text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                <h4 class="uppercase text-[16px] px-[20px] border-b border-white border-solid leading-[1.3] mb-[10px]"
                    style="border-color: white !important;">Application Development</h4>
                <p class="uppercase text-[14px]">data models</p>
                <p class="uppercase text-[14px]">ui development</p>
                <p class="uppercase text-[14px]">devops</p>
            </div>

            <div class="border-[3px] leading-8 pt-[40px] pb-[30px] text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                <h4 class="uppercase text-[16px] px-[20px] border-b border-white border-solid leading-[1.3] mb-[10px]"
                    style="border-color: white !important;">platform services</h4>
                <p class="uppercase text-[14px]">architecture</p>
                <p class="uppercase text-[14px]">security</p>
                <p class="uppercase text-[14px]">administration</p>
                <p class="uppercase text-[14px]">deployment</p>
            </div>

            <h1 class="text-white text-[24px] text-center m-auto mt-[60px] mb-[20px]">Powering World’s Most Iconic
                Organizations</h1>

            <div class="border-[3px] text-white text-center mt-[20px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                <img class="border-b-[3px] border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/76zb9kx9zd14jmu52ymi4wxk2m5iw62l-1@2x.png">
                <div class="flex flex-col items-start justify-between px-[15px] py-[15px] min-h-[119px] w-full box-border">
                    <h4 class="text-[16px] text-left  m-0">Enterprise AI at Shell</h4>
                    <router-link to="#"
                                 class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                        Learn more
                    </router-link>
                </div>
            </div>

            <div class="border-[3px] text-white text-center mt-[20px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                <img class="border-b-[3px] border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/global-b.png">
                <div class="flex flex-col items-start justify-between px-[15px] py-[15px] min-h-[119px] w-full box-border">
                    <h4 class="text-[16px] text-left  m-0">AI-Enhanced Modeling and Simulation
                        in the DoD - Moving from the Lab
                        to the Enterprise</h4>
                    <router-link to="#"
                                 class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                        Learn more
                    </router-link>
                </div>
            </div>

            <div class="border-[3px] text-white text-center mt-[20px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                <img class="border-b-[3px] border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/closeup-of-industrial.png">
                <div class="flex flex-col items-start justify-between px-[15px] py-[15px] min-h-[119px] w-full box-border">
                    <h4 class="text-[16px] text-left  m-0">Optimize Production Schedules
                        and Decrease Manufacturing Costs</h4>
                    <router-link to="#"
                                 class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                        Learn more
                    </router-link>
                </div>
            </div>


            <h1 class="text-white text-[24px] text-center m-auto mt-[60px] mb-[20px]">A Recognized Enterprise
                AI Leader</h1>

            <div class="mx-[35px] border-[3px] text-white text-center mt-[20px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;
">
                <v-carousel height="320px" progress="primary" hide-delimiters :show-arrows="false">
                    <!--                    <template v-slot:prev="{ props }">-->
                    <!--                        <img class="ml-[10px]" style="transform: rotateY(180deg);filter: brightness(4);-->
                    <!--            height: 10px;" src="/arrow_navigation_mobile.svg"/>-->
                    <!--                    </template>-->
                    <!--                    <template v-slot:next="{ props }">-->
                    <!--                        <img class="ml-[10px]" style="filter: brightness(4);-->
                    <!--            height: 10px;" src="/arrow_navigation_mobile.svg"/>-->
                    <!--                    </template>-->
                    <v-carousel-item class="pt-[84px] px-[40px] pb-[35px]">
                        <div class="justify-between px-[40px] pb-[35px] flex flex-col h-full items-center">
                            <img class="m-auto max-w-full" src="/aisoftware/idclogoblue.svg">
                            <div class="flex text-[14px] flex-col items-start px-[15px] py-[15px] min-h-[77px] w-full box-border"
                                 style="
    padding-bottom: 0;
    justify-content: end;
    text-align: center;
    align-items: center;">
                                <p>IDC MarketScape</p>
                                <p>(2016, 2022)</p>
                            </div>
                        </div>
                    </v-carousel-item>
                    <v-carousel-item class="pt-[84px] px-[40px] pb-[35px]">
                        <div class="justify-between px-[40px] pb-[35px] h-full flex flex-col items-center">
                            <img class="max-w-full mt-auto max-h-[185px]" src="/aisoftware/googlecloudpartneroftheyearbadge768x574@2x.png">
                            <div class="flex text-[20px] flex-col items-start py-[15px] min-h-[77px] w-full box-border"
                                 style="
    padding-bottom: 0;
    justify-content: end;
    text-align: center;
    align-items: center;">
                                <p>Google Cloud</p>
                                <p>Partner of the Year</p>
                            </div>
                        </div>
                    </v-carousel-item>

                    <v-carousel-item class="pt-[84px] px-[40px] pb-[35px]">
                        <div class="justify-between h-full px-[40px] pb-[35px] flex flex-col items-center">
                            <img class="pt-[50px] max-w-full m-auto max-h-[185px]" src="/aisoftware/omdialogonew-1@2x.png">
                            <div class="flex text-[20px] flex-col items-start py-[15px] min-h-[77px] w-full box-border"
                                 style="
    padding-bottom: 0;
    justify-content: end;
    text-align: center;
    align-items: center;">
                                <p>Leader in enterprise</p>
                                <p>Development platform</p>
                            </div>
                        </div>
                    </v-carousel-item>

                    <v-carousel-item class="pt-[84px] px-[40px] pb-[35px]">
                        <div class="justify-between px-[40px] pb-[35px] h-full  flex flex-col items-center">
                            <img class="max-w-full mt-auto max-h-[185px]" src="/aisoftware/forbescloud100award.svg">
                            <div class="flex text-[20px] flex-col items-start py-[15px] min-h-[77px] w-full box-border"
                                 style="
    padding-bottom: 0;
    justify-content: end;
    text-align: center;
    align-items: center;">
                                <p>Forbes cloud</p>
                                <p>100 standout( 2020)</p>
                            </div>
                        </div>
                    </v-carousel-item>


                </v-carousel>
            </div>
        </div>

        <img class="w-full h-full max-w-full mt-[90px] mq750:mt-0 overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
             loading="eager" alt="" src="/line-1@2x.svg" data-v-5c83e013="" style="
    height: 112px;
    object-fit: fill;
    transform: rotateY(180deg);
    width: 100%;
">

        <div class="mx-[20px] hidden mq750:block">
            <h1 class="text-white text-center m-auto mb-[30px]">Related Resources</h1>
            <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    background-color: #242430;
">
                <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/glowingmolecularstructureinfuturisticgalaxybackdropgeneratedbyai-1@2x.png">
                <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                    <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                        of AI Product Suite</h4>
                    <div class="flex justify-between w-full ">
                        <router-link to="#"
                                     class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                            Announcements
                        </router-link>
                        <router-link to="#"
                                     class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                            View
                        </router-link>
                    </div>
                </div>
            </div>

            <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    background-color: #242430;
">
                <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/onepersontypingonfuturisticglowingcomputergeneratedbyai-1@2x.png">
                <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                    <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                        of AI Product Suite</h4>
                    <div class="flex justify-between w-full">
                        <router-link to="#"
                                     class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                            Announcements
                        </router-link>
                        <router-link to="#"
                                     class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                            View
                        </router-link>
                    </div>
                </div>
            </div>

            <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                 style="
    background-color: #242430;
">
                <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/keyboardsocialbusinessyoungperson-1@2x.png">
                <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                    <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                        of AI Product Suite</h4>
                    <div class="flex justify-between w-full">
                        <router-link to="#"
                                     class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                            Announcements
                        </router-link>
                        <router-link to="#"
                                     class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                            View
                        </router-link>
                    </div>
                </div>
            </div>

            <img class="w-full h-full max-w-full mt-[90px] mq750:mt-0 overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
                 loading="eager" alt="" src="/line-1@2x.svg" data-v-5c83e013="" style="
    height: 112px;
    object-fit: fill;
    width: 100%;
">

            <div class="flex flex-row items-start justify-start pt-0 px-5 pb-[32px] box-border max-w-full text-23xl"
                 style="    margin-top: -50px;
    justify-content: center !important;">
                <h1 class="m-0 h-[55px] text-white relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1050:text-15xl">
                    Take the next step </h1>
            </div>
            <div class="w-[1280px] flex flex-row flex-wrap items-start justify-start pt-0 px-5 pb-[76px] box-border gap-[50px] max-w-full shrink-0 text-base mq750:gap-[50px] mq750:pb-0">
                <FrameAboutUsNews contactSales="Contact Sales"
                                  learnHowOurIndustryLeadin="Learn how our industry-leading XENdeck AI software products can help your organization."/>
                <FrameAboutUsNews contactSales="Go to Investor
                        Relations"
                                  learnHowOurIndustryLeadin="Contact us at support@xendeck.ai to learn more about investing at XENdeck."/>

                <FrameAboutUsNews contactSales="request information"
                                  learnHowOurIndustryLeadin="For all other questions, please contact us here."/>
            </div>

        </div>

        <div class="flex mq750:hidden flex-row items-start justify-start pt-0 px-5 pb-[32px] box-border max-w-full text-23xl"
             style="    margin-top: -50px;
    justify-content: center !important;">
            <h1 class="m-0 h-[55px] text-white relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1050:text-15xl">
                Take the next step </h1>
        </div>
        <div class="w-full mq750:hidden flex flex-row flex-wrap items-start justify-start pt-0 px-5 pb-[76px] box-border gap-[50px] max-w-full shrink-0 text-base mq750:gap-[50px] mq750:pb-0">
            <FrameAboutUsNews contactSales="Contact Sales"
                              learnHowOurIndustryLeadin="Learn how our industry-leading XENdeck AI software products can help your organization."/>
            <FrameAboutUsNews contactSales="Go to Investor
                        Relations"
                              learnHowOurIndustryLeadin="Contact us at support@xendeck.ai to learn more about investing at XENdeck."/>
            <FrameAboutUsNews contactSales="request information"
                              learnHowOurIndustryLeadin="For all other questions, please contact us here."/>
        </div>

        <div class="self-stretch flex flex-row items-start justify-start pt-0 pb-1.5 pr-[5px] pl-0 box-border max-w-full">
            <div class="h-28 flex-1 relative shrink-0 flex items-center justify-center">
                <img class="h-full flex-1 max-w-[101%] left-positioning overflow-hidden shrink-0 object-contain absolute left-[0px] top-[4px] w-full [transform:scale(1.125)]"
                     loading="eager" alt="" src="/line-2-1@2x.svg" style="
    width: 1440px;
    transform: scalex(2)"/>
            </div>
        </div>


        <main class="mx-auto self-stretch flex flex-row items-start justify-start pt-0 px-0 box-border max-w-full lg:box-border ">
            <footer style="padding: 15px;width:100%;flex-wrap: nowrap;"
                    class="mq750:flex flex-1 mq750:gap-0 mq750:w-full hidden flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
                <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"></div>
                <div class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
                    <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
                         style="        height: auto;
    width: 100px;
    position: absolute;" loading="eager" alt="" src="/group-32@2x.png">
<!--                    <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>-->
                </div>
                <!--                &lt;div class=&quot;flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
                <!--                    --><!--                &lt;/div&gt;-->
                <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
                    <div class="flex flex-row items-start justify-start gap-[12px] flex-wrap" style="
    width: 100%;
">
                        <div class="relative z-[1]" style="flex-grow: 1;
  width: 33%;">Contact Us
                        </div>
                        <div class="relative z-[1]" style="flex-grow: 1;
  width: 33%;">About Us
                        </div>
                        <div class="relative z-[1]" style="flex-grow: 1;
  width: 33%;">News
                        </div>
                        <div class="relative z-[1]" style="flex-grow: 1;
  width: 33%;">AI Software
                        </div>
                        <div class="relative z-[1]" style="flex-grow: 1;
  width: 33%;">Resourses
                        </div>
                        <div class="relative z-[1]" style="flex-grow: 1;
  width: 33%;">Privacy Policy
                        </div>
                        <div class="relative z-[1]" style="flex-grow: 1;
  width: 33%;">Terms of Use
                        </div>
                    </div>
                </div>
                <!--                &lt;div class=&quot;hidden mq750:flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
                <!--                    &lt;div class=&quot;relative z-[1]&quot;&gt;Contact Us&lt;/div&gt;-->
                <!--                    &lt;div class=&quot;w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full&quot;&gt;-->
                <!--                        &lt;div class=&quot;self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap&quot;&gt;-->
                <!--                            &lt;div class=&quot;flex flex-col items-start justify-start gap-[12px]&quot;&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;About Us&lt;/div&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;News&lt;/div&gt;-->
                <!--                            &lt;/div&gt;-->
                <!--                            &lt;div class=&quot;flex-[0.8557] flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1&quot;&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;AI Software&lt;/div&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Resourses&lt;/div&gt;-->
                <!--                            &lt;/div&gt;-->
                <!--                            &lt;div class=&quot;flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]&quot;&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Privacy Policy&lt;/div&gt;-->
                <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Terms of Use&lt;/div&gt;-->
                <!--                            &lt;/div&gt;--><!--                        &lt;/div&gt;-->
                <!--                    &lt;/div&gt;--><!--                &lt;/div&gt;--></footer>
            <footer style="
    width: 1290px;"
                    class="flex-1 mq750:hidden flex flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:gap-[89px] mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
                <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"/>
                <div class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
                    <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
                         loading="eager" alt="" src="/group-32@2x.png"/>
<!--                    <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>-->
                </div>

                <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
                    <div class="self-stretch gap-[40px] flex flex-row items-start justify-between mq450:flex-wrap">
                        <div class="flex flex-col items-start justify-start px-0 pb-0">
                            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
                        </div>
                        <div class="flex flex-col items-start justify-start gap-[12px]">
                            <a href="/news" class="relative z-[1] text-white">News</a>
                        </div>
                        <div class="flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1">
                            <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>
                            <!--                            <div class="relative z-[1]">Resourses</div>-->
                        </div>
                        <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]">
                            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
                            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
                        </div>
                    </div>
                </div>

            </footer>
        </main>


        <!-- #######################       /-->
        <!--        <div class="hidden mq750:flex absolute top-[7401px] left-[0.5px] w-[1161px] h-[79.5px] z-[1] flex items-center justify-center">-->
        <!--            <img class="w-full h-full z-[1] object-contain absolute left-[2px] top-[4px] [transform:scale(1.176)]"-->
        <!--                 alt="" src="/aisoftware/line-19-4.svg"/>-->
        <!--        </div>-->
        <!--        <GroupComponent class="mq750:hidden flex"/>-->
        <!--        <div class="absolute top-[7778px] left-[0px] w-[1445px] h-28 flex items-center justify-center">-->
        <!--            <img class="h-[79.5px] w-[995px] absolute my-0 mx-[!important] right-[-206px] bottom-[-56.5px] object-contain"-->
        <!--                 loading="eager" alt="" src="/line-4@2x.svg" style="right: -100px;-->
        <!--    transform: rotateY(180deg) scalex(1.3);"/>-->
        <!--        </div>-->
        <!--        <StrokeNodes class="mq750:hidden flex"/>-->
        <!--        <section class="mq750:hidden flex absolute top-[4100px] left-[0px] w-[1440px] h-[280px]">-->
        <!--&lt;!&ndash;            <div class="absolute top-[43px] left-[-186px] w-[1799px] h-[237px] flex items-center justify-center">&ndash;&gt;-->
        <!--&lt;!&ndash;                <img class="w-full h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.059)]" alt=""&ndash;&gt;-->
        <!--&lt;!&ndash;                     src="/aisoftware/line-171.svg"/>&ndash;&gt;-->
        <!--&lt;!&ndash;            </div>&ndash;&gt;-->
        <!--            <img class="absolute top-[0px] left-[435px] w-[200px] h-[95px] z-[2]" loading="lazy" alt=""-->
        <!--                 src="/aisoftware/group-407.svg"/>-->
        <!--            <img class="absolute top-[0px] left-[785px] w-[220px] h-[95px] z-[2]" loading="lazy" alt=""-->
        <!--                 src="/aisoftware/group-408.svg"/>-->
        <!--        </section>-->

        <template v-if="!isMobile">
            <!--                    <BranchNode1/>-->
            <!--                    <BranchNode/>-->
            <!--                    <section-->
            <!--                            class="absolute top-[2724px] left-[102px] w-[1239px] flex flex-col items-start justify-start gap-[120px_0px] max-w-full">-->
            <!--                        <CustomShapeNodes/>-->
            <!--                        <ImageProcessorNode/>-->
            <!--                    </section>-->
            <!--                    <img class="absolute top-[3840px] left-[243.5px] w-[1402.5px] h-[79.5px] object-contain" loading="lazy"-->
            <!--                         alt="" src="/aisoftware/line-71.svg"/>-->
            <!--                    <section-->
            <!--                            class="absolute top-[3874px] left-[140px] w-[1161px] flex flex-col items-start justify-start gap-[113px_0px] max-w-full text-left text-23xl text-white-back font-helvetica">-->
            <!--                        <div class="self-stretch flex flex-row items-start justify-center py-0 px-5">-->
            <!--                            <h1 class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block z-[1] mq450:text-6xl mq1025:text-15xl">-->
            <!--                                Complete Capabilities for AI At-Scale </h1>-->
            <!--                        </div>-->
            <!--                        <div class="self-stretch flex flex-col items-start justify-start gap-[20px_0px] max-w-full">-->
            <!--                            <div class="self-stretch relative z-[1] flex items-center justify-center">-->
            <!--                                <img class="self-stretch max-w-full overflow-hidden max-h-full z-[1] object-contain absolute left-[0px] top-[4px] w-full h-full [transform:scale(1.066)]"-->
            <!--                                     alt="" src="/aisoftware/group-3281.svg"/>-->
            <!--                            </div>-->
            <!--                            <Frame2/>-->
            <!--                            <GroupFrame4/>-->
            <!--                        </div>-->
            <!--                    </section>-->
            <!--                    <div class="absolute top-[6046px] left-[0px] w-[1445px] h-28 z-[1] flex items-center justify-center">-->
            <!--                        <img class="w-full h-full z-[1] object-contain absolute left-[0px] top-[4px] [transform:scale(1.125)]"-->
            <!--                             alt="" src="/aisoftware/line-11.svg"/>-->
            <!--                    </div>-->
            <!--                    <FrameComponent1/>-->
            <!--                    <div class="absolute top-[6762px] left-[0.5px] w-[1161px] h-[79.5px] z-[1] flex items-center justify-center">-->
            <!--                        <img class="w-full h-full z-[1] object-contain absolute left-[2px] top-[4px] [transform:scale(1.176)]"-->
            <!--                             loading="lazy" alt="" src="/aisoftware/line-19-4.svg"/>-->
            <!--                    </div>-->
            <!--                    <FrameComponent/>-->
            <!--                    <div class="absolute top-[5145px] left-[103px] w-[1238px] h-[290px] z-[1] flex items-center justify-center">-->
            <!--                        <img class="w-full h-full z-[1] object-contain absolute left-[0px] top-[4px] [transform:scale(1.048)]"-->
            <!--                             alt="" src="/aisoftware/group-284.svg"/>-->
            <!--                    </div>-->
            <!--                    <Conditional/>-->
        </template>
    </div>
</template>
<script>
import {defineComponent} from "vue";
import CousinQueue from "../components/AiSoftWare/CousinQueue.vue";
import MergingNodes from "../components/AiSoftWare/MergingNodes.vue";
import GroupComponent from "../components/AiSoftWare/GroupComponent.vue";
import StrokeNodes from "../components/AiSoftWare/StrokeNodes.vue";
import BranchNode1 from "../components/AiSoftWare/BranchNode1.vue";
import BranchNode from "../components/AiSoftWare/BranchNode.vue";
import CustomShapeNodes from "../components/AiSoftWare/CustomShapeNodes.vue";
import ImageProcessorNode from "../components/AiSoftWare/ImageProcessorNode.vue";
import Frame2 from "../components/AiSoftWare/Frame2.vue";
import GroupFrame4 from "../components/AiSoftWare/GroupFrame4.vue";
import FrameComponent1 from "../components/AiSoftWare/FrameComponent1.vue";
import FrameComponent from "../components/AiSoftWare/FrameComponent.vue";
import Conditional from "../components/AiSoftWare/Conditional.vue";
import {getCurrentBreakpoint} from "@/utils/breakpoints";
import FrameAboutUsNews from "@/components/FrameAboutUsNews.vue";


export default defineComponent({
    name: "AiSoftWarePage",
    components: {
        FrameAboutUsNews,
        CousinQueue,
        MergingNodes,
        GroupComponent,
        StrokeNodes,
        BranchNode1,
        BranchNode,
        CustomShapeNodes,
        ImageProcessorNode,
        Frame2,
        GroupFrame4,
        FrameComponent1,
        FrameComponent,
        Conditional,
    },
    data() {
        return {
            isMobile: false,
            active: false,
            items: [
                {
                    name: 'DATA'
                },
                {
                    name: 'AI'
                },
                {
                    name: 'Application development'
                },
                {
                    name: 'Platform services'
                },
                {
                    name: 'Xendeck Ai Studio'
                },
                {
                    name: 'Xendeck Ex Machina'
                }

            ],
            backNavigation: [],
            navigation: [
                {
                    'name': 'About Us',
                    link: '/',
                    children: [
                        {
                            'name': 'Generative AI',
                        },
                        {
                            'name': 'What is Enterprise AI',
                        },
                        {
                            'name': 'Machine Learning',
                        },
                        {
                            'name': 'XENdeck Live',
                        },
                        {
                            'name': 'Publications',
                        },
                        {
                            'name': 'Customer Viewpoints',
                        },
                        {
                            'name': 'Blog',
                        },
                        {
                            'name': 'Developer portal',
                        },

                    ]
                },
                {
                    'name': 'News',
                    link: '/news',
                },
                {
                    'name': 'AI Software',
                    'link': '/ai-software',
                },
                {
                    'name': 'Contact Us',
                    'link': '/contact',
                },
                {
                    'name': 'Resources',
                    'link': '/',
                },

            ],
            currentNavigation: []
        }
    },
    methods: {
        open() {
            this.active = true;
        },
        close() {
            this.active = false;
        },
        toggle() {
            this.active = !this.active
        },
    },
    mounted() {
        this.currentNavigation = [...this.navigation];
        this.isMobile = ['mq450'].includes(getCurrentBreakpoint())
        window.addEventListener('resize', () => {
            this.isMobile = ['mq450'].includes(getCurrentBreakpoint())
        });
    }
});
</script>

<style lang="scss">
//@import 'vuetify/styles';

.fade-enter-active {
  @apply animate-[flip-down_0.5s_ease-in-out_1] flex;
}

.fade-leave-active {
  @apply animate-[flip-down_0.3s_ease-in-out_1_reverse] animate-reverse flex;
  /*@apply hidden;*/
}

.fade-enter-from {
  /*@apply animate-flip-down animate-reverse flex;*/
}

.fade-leave-to {
  /*@apply hidden;*/
}

.v-progress-linear {
  bottom: 0 !important;
  top: auto !important;
}

body {
  .v-progress-linear__background {
    background-color: gray !important;
  }
}

body {
  .v-progress-linear__determinate {
    background-color: white !important;
  }
}
</style>
