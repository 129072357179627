<template>
  <section
    class="absolute top-[6109px] left-[101px] w-[1238px] flex flex-col items-end justify-start gap-[40px_0px] max-w-full text-left text-23xl text-white-back font-helvetica"
  >
    <div
      class="self-stretch flex flex-col items-start justify-start gap-[75px_0px]"
    >
      <div
        class="self-stretch flex flex-row items-start justify-center py-0 px-5"
      >
        <h1
          class="m-0 h-[58px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1025:text-15xl"
        >
          A Recognized Enterprise AI Leader
        </h1>
      </div>
      <div
        class="self-stretch grid flex-row items-start justify-start gap-[0px_38px] grid-cols-[repeat(4,_minmax(211px,_1fr))] text-center text-xl mq450:grid-cols-[minmax(211px,_1fr)] mq1025:justify-center mq1025:grid-cols-[repeat(2,_minmax(211px,_365px))]"
      >
        <div
          class="h-[360px] flex flex-col items-start justify-end py-12 px-[45px] box-border relative gap-[97px]"
        >
          <div
            class="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-center justify-center z-[0]"
          >
            <img
              class="w-full h-full max-w-full overflow-hidden max-h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.05)]"
              alt=""
              src="/aisoftware/group-427.svg"
            />
          </div>
          <img
            class="self-stretch h-[50px] relative max-w-full overflow-hidden shrink-0 z-[1]"
            alt=""
            src="/aisoftware/idclogoblue.svg"
          />
          <div
            class="self-stretch flex flex-row items-start justify-start py-0 px-[3px]"
          >
            <div class="relative uppercase font-light z-[1] mq450:text-base">
              <p class="m-0">IDC MarketScape</p>
              <p class="m-0">(2016, 2022)</p>
            </div>
          </div>
        </div>
        <GroupFrame3
          googleCloudPartneroftheYe="/googlecloudpartneroftheyearbadge768x574@2x.png"
          googleCloud="Google Cloud "
          partnerOfTheYEar="Partner of the yEar"
        />
        <div
          class="h-[360px] flex flex-col items-start justify-end py-12 px-[13px] box-border relative gap-[94px]"
        >
          <div
            class="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-center justify-center z-[0]"
          >
            <img
              class="w-full h-full max-w-full overflow-hidden max-h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.05)]"
              alt=""
              src="/aisoftware/group-427-1.svg"
            />
          </div>
          <div class="flex flex-row items-start justify-start py-0 px-8">
            <img
              class="h-[53px] w-[191px] relative object-cover z-[1]"
              loading="lazy"
              alt=""
              src="/aisoftware/omdialogonew-1@2x.png"
            />
          </div>
          <div class="relative uppercase font-light z-[1] mq450:text-base">
            <p class="m-0">Leader in enterprise</p>
            <p class="m-0">Development platform</p>
          </div>
        </div>
        <GroupFrame3
          googleCloudPartneroftheYe="/forbescloud100award.svg"
          googleCloud="Forbes cloud "
          partnerOfTheYEar="100 standout( 2020)"
          propGap="51px"
          propHeight="164px"
          propBorderRadius="unset"
        />
      </div>
    </div>
    <div
      class="self-stretch h-0 flex flex-row items-start justify-center py-0 px-5 box-border"
    >
      <img class="h-0.5 w-[165px] relative" alt="" src="/aisoftware/group-390.svg" />
    </div>
  </section>
</template>
<script>
  import { defineComponent } from "vue";
  import GroupFrame3 from "@/components/AiSoftWare/GroupFrame3.vue";

  export default defineComponent({
    name: "ConditionalC",
    components: { GroupFrame3 },
  });
</script>
