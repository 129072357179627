<template>
    <div class="w-auto news-select-wrapper px-[250px] mq750:px-[15px] mq450:mb-[50px] mq750:w-full mq750:flex mq750:justify-center sticky top-[90px]">
        <NewsSelect :options="selectOptions" :default="selectOptions[0]" class="select"/>
    </div>
    <div class="w-full h-full mt-[40px] bg-d2d3c overflow-hidden flex flex-col items-center justify-start gap-[4px] tracking-[normal] text-left text-xl text-white-back font-helvetica mq450:h-auto">
        <div class="w-full">
            <div class="px-[250px] mq750:px-[15px]">
            <h2 class="news-page__subtitle">
                What is Enterprise AI?</h2>
            <h5 class="news-page__subtitle !text-[24px] !font-weight-light" style="line-height: 1.4; font-weight: 400">
                Enterprise AI is a category of enterprise software that harnesses advanced artificial intelligence
                techniques to drive digital transformation.
                Developing and deploying Enterprise AI at scale requires a new technology stack. </h5>

            <p class="news-page__text" style="color: #ffffffc2;">Continue reading or <a class="text-white" style="text-decoration: underline; color: #ffffffc2;" href="https://c3.ai/wp-content/uploads/2019/12/C3.ai-A-New-Technology-Stack.pdf"> download the white paper.</a></p>

            <div class="news-page__placeholder-img !mb-[20px]">
                <img width="100%" src="/2024-04-18_15-06-06 1.png" alt="place-img"/>
            </div>

            <h2 class="news-page__subtitle  !mt-[50px]">
                Introduction: A New Technology Stack</h2>

            <p class="news-page__text">
                Over the last four decades, the information technology industry has grown from about $120 billion globally in 1980 to more than $2 trillion. During this time, the IT industry has experienced the transition from mainframe computing to minicomputers, to personal computing, to internet computing, and to handheld computing. The software industry has transitioned from custom applications based on mainframe standards such as MVS, VSAM, and ISAM, to applications developed on a relational database foundation, to enterprise application software, to SaaS and mobile apps, and now to the AI-enabled enterprise. The internet and the iPhone changed everything. Each of these transitions represented a replacement market for its predecessor. Each delivered dramatic benefits in productivity.
</p>
                <p class="news-page__text">
                Each offered organizations the opportunity to gain sustainable competitive advantage. Companies that failed to take advantage of each new generation of technology ceased to be competitive. Today it is unimaginable that a major global corporation would try to close its books without an enterprise resource planning system or run its business solely on mainframe computers.
                </p>
                <p class="news-page__text">
                The IT industry is now undergoing another major transition. A new generation of 21st century technologies – including elastic cloud computing, the internet of things, and artificial intelligence – is driving digital transformation across industry, commerce, and government globally. Digital transformation presents a number of unique requirements that create the need for an entirely new software technology stack. The requirements are daunting.
                </p>
                <p class="news-page__text">
                This paper defines the requirements of the new digital transformation software stack. It explains why the current approach to developing AI and IoT enterprise software – i.e., using structured programming to build applications by assembling and integrating various open source components and cloud services – is slow, costly, and ineffective.
                </p>
                <p class="news-page__text">
                Finally, this paper describes how the XENdeck Suite with its unique model-driven architecture fully addresses the requirements for the digital transformation software stack, providing a low-code/no-code AI and IoT platform that accelerates software development by a factor of 26 or more, reduces cost and risk, and delivers future-proof applications.
            </p>

            <img class="w-full h-full max-w-full mt-[90px] mq750:mt-0 overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
                 loading="eager" alt="" src="/line-1@2x.svg" data-v-5c83e013="" style="
    height: 112px;
    object-fit: fill;
    width: 100vw;
        transform: rotateY(180deg) scalex(1.13)
">

            </div>

            <div class="mx-[20px] hidden mq750:block">
                <h1 class="text-white text-center m-auto mb-[30px]">Related Resources</h1>
                <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    background-color: #242430;
">
                    <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/glowingmolecularstructureinfuturisticgalaxybackdropgeneratedbyai-1@2x.png">
                    <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                        <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                            of AI Product Suite</h4>
                        <div class="flex justify-between w-full ">
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                Announcements
                            </router-link>
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                View
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    background-color: #242430;
">
                    <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/onepersontypingonfuturisticglowingcomputergeneratedbyai-1@2x.png">
                    <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                        <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                            of AI Product Suite</h4>
                        <div class="flex justify-between w-full">
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                Announcements
                            </router-link>
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                View
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                     style="
    background-color: #242430;
">
                    <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/keyboardsocialbusinessyoungperson-1@2x.png">
                    <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                        <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                            of AI Product Suite</h4>
                        <div class="flex justify-between w-full">
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                Announcements
                            </router-link>
                            <router-link to="#"
                                         class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                View
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mq750:hidden mx-[140px] block relative">
                <h1 class="text-white text-center m-auto mb-[30px] mt-[-30px]">Related Resources</h1>

                <div class="flex gap-[20px]">
                    <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                         style="
    background-color: #242430;
">
                        <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/glowingmolecularstructureinfuturisticgalaxybackdropgeneratedbyai-1@2x.png">
                        <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                            <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                                of AI Product Suite</h4>
                            <div class="flex justify-between w-full ">
                                <router-link to="#"
                                             class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                    Announcements
                                </router-link>
                                <router-link to="#"
                                             class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                    View
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                         style="
    background-color: #242430;
">
                        <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/onepersontypingonfuturisticglowingcomputergeneratedbyai-1@2x.png">
                        <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                            <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                                of AI Product Suite</h4>
                            <div class="flex justify-between w-full">
                                <router-link to="#"
                                             class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                    Announcements
                                </router-link>
                                <router-link to="#"
                                             class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                    View
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class=" pt-0 pb-0 text-white text-center mt-[20px] py-[64px] flex flex-col items-center justify-between border-solid border-[#212135]"
                         style="
    background-color: #242430;
">
                        <img class="border-b-[5px] rounded border-solid border-[#212135] max-w-full" style="box-shadow: inset 0.5px 0.5px 5px 1px #edeaea05, 0.5px 0.5px 5px 1px #edeaea05, 1px 1px 5px 2px #00000024, 0px 10px 5px 1px #00000014;
    border-color: #242430;" src="/aisoftware/keyboardsocialbusinessyoungperson-1@2x.png">
                        <div class="flex flex-col items-start px-[15px] py-[15px] min-h-[118px] w-full box-border">
                            <h4 class="text-[16px] text-left leading-8 m-0">XENdeck Announces Launch
                                of AI Product Suite</h4>
                            <div class="flex justify-between w-full">
                                <router-link to="#"
                                             class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                    Announcements
                                </router-link>
                                <router-link to="#"
                                             class="text-left text-[16px]   relative font-light inline-block z-[1] text-[#B6B6B6] mt-[20px] uppercase max-w-[60%] ml-0">
                                    View
                                </router-link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <img class="w-full h-full max-w-full mt-[90px] mq750:mt-0 overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
                 loading="eager" alt="" src="/line-1@2x.svg" data-v-5c83e013="" style="
    height: 112px;
    object-fit: fill;
    width: 100%;
        transform: rotateY(180deg) scalex(1.13)
">

            <div class="flex flex-row items-start justify-start pt-0 px-5 pb-[32px] box-border max-w-full text-23xl"
                 style="    margin-top: -50px;
    justify-content: center !important;">
                <h1 class="m-0 h-[55px] text-white relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1050:text-15xl">
                    Take the next step </h1>
            </div>
            <div class="mx-auto w-[1280px] flex flex-row flex-wrap items-start justify-start pt-0 px-5 pb-[76px] box-border gap-[50px] max-w-full shrink-0 text-base mq750:gap-[50px] mq750:pb-0">
                <FrameAboutUsNews contactSales="Contact Sales"
                                  learnHowOurIndustryLeadin="Learn how our industry-leading XENdeck AI software products can help your organization."/>
                <FrameAboutUsNews contactSales="Go to Investor
                        Relations"
                                  learnHowOurIndustryLeadin="Contact us at support@xendeck.ai to learn more about investing at XENdeck."/>

                <FrameAboutUsNews contactSales="request information"
                                  learnHowOurIndustryLeadin="For all other questions, please contact us here."/>
            </div>



        </div>
    </div>

    <div class="self-stretch bg-[#2d2d3c] overflow-hidden flex flex-row items-start justify-start pt-0 pb-1.5 pr-[5px] pl-0 box-border max-w-full">
        <div class="h-28 flex-1 relative shrink-0 flex items-center justify-center">
            <img class="h-full flex-1 max-w-[101%] left-positioning overflow-hidden shrink-0 object-contain absolute left-[0px] top-[4px] w-full [transform:scale(1.125)]"
                 loading="eager" alt="" src="/line-2-1@2x.svg" style="
    width: 1440px;
    transform: scalex(2)"/>
        </div>
    </div>
    <main class="mx-auto self-stretch flex flex-row items-start justify-start pt-0 px-0 box-border max-w-full lg:box-border ">
        <footer style="padding: 15px;width:100%;flex-wrap: nowrap;"
                class="mq750:flex flex-1 mq750:gap-0 mq750:w-full hidden flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
            <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"></div>
            <div class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
                <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]" style="        height: auto;
    width: 100px;
    position: absolute;" loading="eager" alt="" src="/group-32@2x.png">
            </div>
            <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
                <div class="flex flex-row items-start justify-start gap-[12px] flex-wrap" style="
    width: 100%;
">
                    <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
                    <a href="/news" class="relative z-[1] text-white">News</a>
                    <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>

                    <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
                    <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
                </div>
            </div>
        </footer>
        <footer style="    padding-left: 0;
    width: 1290px;"
                class="flex-1 mq750:hidden flex flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:gap-[89px] mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
            <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"/>
            <div class="w-[226px] ml-[100px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
                <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
                     loading="eager" alt="" src="/group-32@2x.png"/>
            </div>

            <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
                <div class="self-stretch gap-[40px] flex flex-row items-start justify-between mq450:flex-wrap">
                    <div class="flex flex-col items-start justify-start px-0 pb-0">
                        <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
                    </div>
                    <div class="flex flex-col items-start justify-start gap-[12px]">
                        <a href="/news" class="relative z-[1] text-white">News</a>
                    </div>
                    <div class="flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1">
                        <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>
                    </div>
                    <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]">
                        <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
                        <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
                    </div>
                </div>
            </div>

        </footer>
    </main>
</template>

<script>
import {defineComponent, reactive, toRefs} from "vue";
import NewsItem from "@/components/NewsItem.vue";
import {Tab, TabPanel, TabPanels, Tabs} from "vue3-tabs";
import FrameAboutUsNews from "@/components/FrameAboutUsNews.vue";
import NewsSelect from "@/components/NewsSelect.vue";

export default defineComponent({
    name: "itForEnterpriceAI",
    components: {Tabs, Tab, TabPanels, TabPanel, FrameAboutUsNews, NewsSelect},
    props: {
        content: {type: String}
    },
    setup() {
        return {
            selectOptions: [
                'What is Enterprise AI?',
                'Introduction: A New Technology Stack',
                'Related Resources',
                'Take the next step',
            ]
        };
    }
});
</script>

<style lang="scss">

.news-page__subtitle {
  font-size: 42px;
  line-height: 55px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 20px;
  @media (max-width: 750px) {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 10px;
  }
}

.news-page__text {
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  color: #ffffff;
  margin: 0 0 20px;
  @media (max-width: 750px) {
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 15px;
  }
}

.news-page__list {
  list-style-type: disc;

  li {
    margin-bottom: 10px;
  }

  li span:not(.font-light) {
    font-weight: 700;
  }

  li span.font-light {
    color: #ffffffc2;
    font-size: 18px;
  }
}

.news-select-wrapper {
  z-index: 999999999;

  .selected {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (max-width: 750px) {

  .news-select-wrapper {
    top: 80px !important;
    border-left: 25px solid #23232e;
    border-right: 15px solid #23232e;
    border-top: 10px solid #23232e;
    border-bottom: 20px solid #23232e;
    box-sizing: border-box;
    padding: 0 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    background: #2d2d3c;
    z-index: 999999999;


    .custom-select {
      width: 100%;
      margin: 0;
    }

    .selected {
      padding: 10px;
      background: #2d2d3c !important;
      margin-right: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .items {
      background: #2d2d3c !important;
      margin-left: -20px;
      width: calc(100% - 20px) !important;
    }

    .ham3 {
      top: 50%;
      margin-top: -25px;
      right: -10px;
    }
  }

}


</style>