<template>
  <div
      class="w-full h-full bg-d2d3c overflow-hidden flex flex-col items-center justify-start gap-[4px] tracking-[normal] text-left text-xl text-white-back font-helvetica mq450:h-auto"
  >
    <div class="title-block">
      <div class="title-block-text">
        <h2 class="title-block-text__title">AI APP INBOX</h2>
        <p class="title-block-text__subtitle">Generative AI for your AP emails</p>
        <p class="title-block-text__desc">AI that labels, organizes, and writes accurate,
          data-driven responses to your AP emails
          for you and saves you hours of work.
          Connect it to Autonomous AP and process
          emailed invoices all the way to ‘Ok to pay.’</p>
        <button class="title-block-text__btn">Sign-up now</button>
      </div>
      <div class="title-block-img">
        <img class="title-block-img__image" src="/img-title-block.jpg" alt="img-title-block" />
      </div>
    </div>

    <div class="organize-process-response">
      <div class="organize-process-response__title">
        <h1>Organize. Process. Respond.</h1>
      </div>
      <div class="organize-process-response__desc">
        <p>
          Spend less time on email. Our AI organizes your AP email inbox, processes supplier invoices, and responds to supplier inquiries and questions, leaving you more time for strategic tasks.
        </p>
      </div>
      <div class="organize-process-response__img">
        <img src="/opr-img.jpg" alt="opr-img" />
      </div>
    </div>

      <div class="mb-[110px] mq750:mb-[0]">
        <tabs
            v-model="selectedTab"
        >
          <tab
              class="tab-news"
              v-for="(tab, i) in tabs"
              :key="`t${i}`"
              :val="tab"
              :label="tab"
              :indicator="true"
          />
        </tabs>
        <tab-panels
            v-model="selectedTab"
            :animate="true"
        >
          <tab-panel
              v-for="(tab, i) in tabs"
              :key="`tp${i}`"
              :val="tab"
          >
            <div class="tab-item" v-if="tab === 'Organize'">
              <div class="tab-item__img">
                <img src="/slide-1.jpg" alt="img-item" />
              </div>
              <div class="tab-item-desc">
                <div class="tab-item-desc__title">
                  Categorize and prioritize
                </div>
                <div class="tab-item-desc__text">
                  Using AI that understands the content and context of each email, as well as your suppliers, purchase orders, and supplier invoices, XENdeck AI AP Inbox sorts and organizes your incoming emails into categories so you can easily take action on what’s most important.
                </div>
              </div>
            </div>
            <div class="tab-item" v-if="tab === 'Process'">
              <div class="tab-item__img">
                <img src="/slide-2.jpg" alt="img-item" />
              </div>
              <div class="tab-item-desc">
                <div class="tab-item-desc__title">
                  Touchless invoice processing
                </div>
                <div class="tab-item-desc__text">
                  XENdeck AI App Inbox automatically takes appropriate action to process not only invoices received from suppliers but also emails with W‑9 forms, statements, and bank account change requests. It then gives you detailed feedback on how invoices and vendor requests are being processed.
                </div>
                <div class="tab-item-desc__title">
                  Take action on every supplier request
                </div>
                <div class="tab-item-desc__text">
                  Your email inbox is filled with more than just invoices. XENdeck AI App Inbox handles your internal process and takes action on other supplier emails, from processing W‑9 forms to reconciling supplier statements to critical tasks like bank account change requests.
                </div>
                <div class="tab-item-desc__title">
                  Autonomous data capture and processing
                </div>
                <div class="tab-item-desc__text">
                  Our AI identifies email attachments and automatically begins the data processing workflow. It matches invoices with POs, assigns GL codes to non-PO-backed invoices, and continues all the way to your backend ERP and P2P system.
                </div>
              </div>
            </div>
            <div class="tab-item" v-if="tab === 'Respond'">
              <div class="tab-item__img">
                <img src="/slide-3.jpg" alt="img-item" />
              </div>
              <div class="tab-item-desc">
                <div class="tab-item-desc__title">
                  Automate responses to supplier inquiries
                </div>
                <div class="tab-item-desc__text">
                  Generate accurate, data-driven responses
                  to all your vendor queries with the power
                  of generative AI.
                  <br/><br/>
                  XENdeck AI App Inbox understands suppliers’ questions like your experienced AP staff would.
                  It researches the information requested, such as invoice status, payment updates, and more, and composes a response that includes all relevant data, ready for your AP staff to review and send.
                </div>
              </div>
            </div>
          </tab-panel>
        </tab-panels>


    </div>


    <div class="flex flex-row items-start justify-start relative max-w-full">
      <img
          class="h-[79.5px] w-[1161px] absolute my-0 mx-[!important] right-[-206px] bottom-[-56.5px] object-contain mq750:w-[450px]"
          loading="eager"
          alt=""
          src="/line-19-1@2x.png"
      />
    </div>
    <div
        class="flex flex-row items-start justify-start pt-5 px-5 pb-[72px] box-border max-w-full text-23xl mq750:pb-[20px]"
    >
      <h1
          class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1050:text-15xl mq750:h-[25px]"
      >
        Take the next step
      </h1>
    </div>
    <div
        class="w-[1240px] flex flex-row flex-wrap items-start justify-start pt-0 px-5 pb-[76px] box-border gap-[50px] max-w-full shrink-0 text-base mq750:gap-[50px] mq750:pb-[45px]"
    >
      <FrameAboutUsNews
          contactSales="Contact Sales"
          learnHowOurIndustryLeadin="Learn how our industry-leading XENdeck AI software products can help your organization."
      />
      <div
          class="flex-1 flex flex-col items-start justify-start gap-[15px] min-w-[285px] max-w-full"
      >
        <button
            class="cursor-pointer py-[22px] px-5 bg-[transparent] self-stretch rounded-12xs box-border flex flex-row items-center justify-start gap-[15px] max-w-full border-[1px] border-solid border-white-back hover:bg-gainsboro-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-gainsboro-100"
        >
          <div
              class="h-[67px] w-[380px] relative rounded-12xs box-border hidden max-w-full border-[1px] border-solid border-white-back"
          />
          <b
              class="relative text-lg uppercase font-helvetica text-white-back text-left z-[1]"
          >Go to Investor Relations</b
          >
          <img
              class="h-[8.8px] w-[16.3px] relative z-[1]"
              alt=""
              src="/arrow-1-1.svg"
          />
        </button>
        <div class="self-stretch h-12 relative font-light inline-block">
          <p class="[margin-block-start:0] [margin-block-end:4px]">
            Contact us at support@xendeck.ai to learn more
          </p>
          <p class="m-0">about investing at XENdeck.</p>
        </div>
      </div>
      <FrameAboutUsNews
          contactSales="request information"
          learnHowOurIndustryLeadin="For all other questions, please contact us here."
      />
    </div>

<!--    <div-->
<!--        class="self-stretch flex flex-row items-start justify-start pt-0 pb-1.5 pr-[5px] pl-0 box-border max-w-full"-->
<!--    >-->
<!--      <div-->
<!--          class="h-28 flex-1 relative shrink-0 flex items-center justify-center"-->
<!--      >-->
<!--        <img-->
<!--            class="h-full flex-1 max-w-[101%] overflow-hidden shrink-0 object-contain absolute left-[0px] top-[4px] w-full [transform:scale(1.125)]"-->
<!--            loading="eager"-->
<!--            alt=""-->
<!--            src="/line-2-1@2x.png"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
    <div class="self-stretch bg-[#2d2d3c] flex flex-row items-start justify-start pt-0 pb-1.5 pr-[5px] pl-0 box-border max-w-full">
      <div class="h-28 flex-1 relative shrink-0 flex items-center justify-center">
        <img class="h-full flex-1 max-w-[101%] left-positioning overflow-hidden shrink-0 object-contain absolute left-[0px] top-[4px] w-full [transform:scale(1.125)]"
             loading="eager" alt="" src="/line-2-1@2x.svg" style="
    width: 1440px;
    transform: scalex(2)"/>
      </div>
    </div>
    <main class="mx-auto self-stretch flex flex-row items-start justify-start pt-0 px-0 box-border max-w-full lg:box-border ">
      <footer style="padding: 15px;width:100%;flex-wrap: nowrap;"
              class="mq750:flex flex-1 mq750:gap-0 mq750:w-full hidden flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
        <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"></div>
        <div class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
          <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]" style="        height: auto;
    width: 100px;
    position: absolute;"
               loading="eager" alt="" src="/group-32@2x.png">
<!--          <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>-->
        </div>
        <!--                &lt;div class=&quot;flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
        <!--                    --><!--                &lt;/div&gt;-->
        <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
          <div class="flex flex-row items-start justify-start gap-[12px] flex-wrap" style="
    width: 100%;
">
            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
            <!--            <div class="relative z-[1]" style="flex-grow: 1;-->
<!--  width: 33%;">About Us</div>-->
            <a href="/news" class="relative z-[1] text-white">News</a>
             <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>

            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
          </div>
        </div>
        <!--                &lt;div class=&quot;hidden mq750:flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
        <!--                    &lt;div class=&quot;relative z-[1]&quot;&gt;Contact Us&lt;/div&gt;-->
        <!--                    &lt;div class=&quot;w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full&quot;&gt;-->
        <!--                        &lt;div class=&quot;self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap&quot;&gt;-->
        <!--                            &lt;div class=&quot;flex flex-col items-start justify-start gap-[12px]&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;About Us&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;News&lt;/div&gt;-->
        <!--                            &lt;/div&gt;-->
        <!--                            &lt;div class=&quot;flex-[0.8557] flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;AI Software&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Resourses&lt;/div&gt;-->
        <!--                            &lt;/div&gt;-->
        <!--                            &lt;div class=&quot;flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Privacy Policy&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Terms of Use&lt;/div&gt;-->
        <!--                            &lt;/div&gt;--><!--                        &lt;/div&gt;-->
        <!--                    &lt;/div&gt;--><!--                &lt;/div&gt;--></footer>
      <footer style="    padding-left: 0;
    width: 1290px;"
              class="flex-1 mq750:hidden flex flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:gap-[89px] mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
        <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"/>
        <div class="w-[226px] ml-[100px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
          <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
               loading="eager" alt="" src="/group-32@2x.png"/>
<!--          <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>-->
        </div>
        <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
                    <div class="self-stretch gap-[40px] flex flex-row items-start justify-between mq450:flex-wrap">
                        <div class="flex flex-col items-start justify-start px-0 pb-0">
                            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
                        </div>
                        <div class="flex flex-col items-start justify-start gap-[12px]">
                            <a href="/news" class="relative z-[1] text-white">News</a>
                        </div>
                        <div class="flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1">
                            <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>
<!--                            <div class="relative z-[1]">Resourses</div>-->
                        </div>
                        <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]">
                            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
                            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
                        </div>
                    </div>
                </div>
      </footer>
    </main>
  </div>
</template>

<script>
import {defineComponent, reactive, toRefs} from "vue";
import FrameAboutUsNews from "@/components/FrameAboutUsNews.vue";
import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';

const tabs = ['Organize', 'Process', 'Respond'];
export default defineComponent({
  name: "AiAppInbox",
  components: { Tabs, Tab, TabPanels, TabPanel, FrameAboutUsNews },
  setup() {
    const state = reactive({
      selectedTab: tabs[1]
    });
    return {
      tabs,
      ...toRefs(state)
    };
  }
});
</script>

<style lang="scss" scoped>
.tab-item {
  display: flex;
  margin-top: 50px;
  justify-content: center;

  @media (max-width: 950px) {
    flex-direction: column;
    margin: 15px 0;
  }
  &__img {
    width: 100%;
    img {
      height: 810px;
      max-width: 610px;
      border-radius: 5px;

      @media (max-width: 950px) {
        width: 100%;
        height: auto;
        max-width: 100%;
      }
    }
  }

  &-desc {
    padding-left: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 950px) {
      padding-left: 0;
      margin-top: 15px;
    }

    &__title {
      line-height: 37px;
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 10px;

      @media (max-width: 750px) {
        line-height: 18px;
        font-size: 16px;
        margin-bottom: 5px;
      }
    }

    &__text {
      margin: 0 0 30px;
      line-height: 27px;
      font-size: 20px;
      font-weight: 400;

      @media (max-width: 750px) {
        margin: 0 0 20px;
        line-height: 16px;
        font-size: 12px;
      }
    }

    &__link {
      margin: 0;
      line-height: 46px;
      font-size: 36px;
      font-weight: 700;
    }

    &__list {
      list-style-type: disc;
      li {
        margin-bottom: 10px;
      }
      li span {
        font-weight: 700;
      }
    }
  }
}

.tabs {
  justify-content: center;
}

.tab-panels {
  margin: 0 100px;

  @media (max-width: 950px) {
    margin: 0 15px;
  }
}

.tab-news {
  padding: 0 5px !important;
  color: #A2A2A2;
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 750px) {
    padding: 0 !important;
    border-width: 0;
    border-bottom-width: 2px;
    border-style: solid;
    border-color: #A2A2A2;
    font-size: 12px;
    line-height: 1px;
  }

  div {
    padding: 0;
  }
}

.active {
  border-width: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #FFFFFF !important;
  color: #FFFFFF !important;
}

.title-block {
  width: 100%;
  display: flex;
  padding: 60px 0 60px 100px;
  margin-bottom: 60px;
  background-color: rgba(0, 0, 0, 0.23);
  box-sizing: border-box;

  @media (max-width: 950px) {
    flex-direction: column-reverse;
    padding: 30px 15px;
    margin-bottom: 20px;
  }
  &-text {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-right: 55px;
    max-width: 478px;

    @media (max-width: 950px) {
      margin-top: 30px;
      margin-right: 0;
      max-width: 100%;
    }
    &__title {
      color: #1C57E0;
      font-weight: 400;
      font-size: 52px;
      line-height: 60px;
      margin: 0;
      text-transform: uppercase;

      @media (max-width: 750px) {
        font-size: 32px;
        line-height: 30px;
      }
    }
    &__subtitle {
      margin-top: 10px;
      color: #ffffff;
      font-weight: 700;
      font-size: 23px;
      line-height: 30px;
      text-transform: uppercase;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
    &__desc {
      margin-top: 20px;
      color: #ffffff;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;

      @media (max-width: 750px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &__btn {
      margin-top: 30px;
      text-transform: uppercase;
      border: 1px solid #ffffff;
      color: #ffffff;
      background: transparent;
      padding: 20px 32px;
      max-width: 237px;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;

      @media (max-width: 750px) {
        padding: 14px 20px;
        max-width: 186px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &-img {
    &__image {
      border-radius: 5px;
      width: 100%;
      height: 100%;
    }
  }
}

.organize-process-response {
  padding: 0 274px;
  text-align: center;

  @media (max-width: 950px) {
    padding: 0 15px;
  }
  &__title {
    h1 {
      margin: 0 0 22px;
      font-weight: 700;
      font-size: 42px;
      line-height: 41px;

      @media (max-width: 750px) {
        margin: 0 0 10px;
        font-size: 24px;
        line-height: 31px;
      }
    }
  }

  &__desc {
    p {
      margin: 0 0 40px;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;

      @media (max-width: 750px) {
        margin: 0 0 15px;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  &__img {
    margin: 0 0 40px;

    @media (max-width: 750px) {
      margin: 0;
    }
    img {
      margin: 0 0 30px;
      border-radius: 5px;
      width: 100%;
    }
  }
}
</style>