<template>
    <router-link :to="link">
        <div class="hover:opacity-[.6] cursor-pointer w-[211px] flex flex-col items-start justify-start gap-[4px] text-left text-base text-gray-100 font-helvetica">
                <div class="self-stretch relative uppercase font-bold">
                    {{ whatIsEnterpriseAI }}
                </div>
                <img class="self-stretch h-[0.5px] relative max-w-full overflow-hidden shrink-0 object-cover" loading="eager"
                     alt="" src="/line-20.svg"/>
                <div class="mq750:hidden self-stretch relative text-sm font-light mt-[10px]">
                    {{ corePrinciplesAndBestPrac }}
                </div>
    </div>
    </router-link>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "GroupFrame1",
    props: {
        whatIsEnterpriseAI: {type: String},
        corePrinciplesAndBestPrac: {type: String},
        link: {type: String},
    },
});
</script>
