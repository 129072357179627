<template>
  <div
    class="w-[478px] flex flex-col items-start justify-start pt-0 px-0 pb-2 box-border min-w-[478px] max-w-full text-left text-23xl text-white-back font-helvetica mq750:min-w-full mq1025:flex-1"
  >
    <div
      class="self-stretch flex flex-col items-start justify-start gap-[30px_0px] max-w-full"
    >
      <h1
        class="m-0 self-stretch h-[55px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1025:text-15xl"
      >
        Enterprise-Scale AI
      </h1>
      <div
        class="self-stretch flex flex-col items-start justify-start gap-[20px_0px] max-w-full text-13xl"
      >
        <div
          class="self-stretch flex flex-col items-start justify-start gap-[17px_0px]"
        >
          <h2
            class="m-0 self-stretch relative text-inherit font-normal font-inherit mq450:text-lgi mq1025:text-7xl"
          >
            {{largestEnterpriseAIFootpr}}
          </h2>
          <div
            class="self-stretch h-[140px] relative text-xl font-light inline-block box-border pr-3.5 mq450:text-base"
            :style="theXENdeckAIContainerStyle"
          >
            <p class="m-0">{{theXENdeckAIPlatformUnloc}}</p>
            <p class="m-0">{{deploymentsPowering18Bill}}</p>
          </div>
        </div>
        <div
          class="flex flex-row items-start justify-start py-0 pr-5 pl-0 box-border gap-[0px_10px] max-w-full text-xl text-grey"
        >
          <div class="relative uppercase mq450:text-base">
            {{learnMoreAboutModelOps}}
          </div>
          <div
            class="h-[11px] flex flex-col items-start justify-start pt-[11px] px-0 pb-0 box-border"
          >
            <img
              class="w-[19px] h-[7.4px] relative"
              alt=""
              src="/aisoftware/arrow-1-3.svg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "Frame3C",
    props: {
      largestEnterpriseAIFootpr: { type: String },
      theXENdeckAIPlatformUnloc: { type: String },
      deploymentsPowering18Bill: { type: String },
      learnMoreAboutModelOps: { type: String },
      propHeight: { type: [Object, Array, String, Number, Boolean] },
    },
    computed: {
      theXENdeckAIContainerStyle() {
        return {
          height: this.propHeight,
        };
      },
    },
  });
</script>
