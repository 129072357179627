<template>
    <NewsItem content="" />
  <div class="self-stretch overflow-hidden bg-[#2d2d3c] flex flex-row items-start justify-start pt-0 pb-1.5 pr-[5px] pl-0 box-border max-w-full">
    <div class="h-28 flex-1 relative shrink-0 flex items-center justify-center">
      <img class="h-full flex-1 max-w-[101%] left-positioning overflow-hidden shrink-0 object-contain absolute left-[0px] top-[4px] w-full [transform:scale(1.125)]"
           loading="eager" alt="" src="/line-2-1@2x.svg" style="
    width: 1440px;
    transform: scalex(2)"/>
    </div>
  </div>
  <main class="mx-auto self-stretch flex flex-row items-start justify-start pt-0 px-0 box-border max-w-full lg:box-border ">
    <footer style="padding: 15px;width:100%;flex-wrap: nowrap;"
            class="mq750:flex flex-1 mq750:gap-0 mq750:w-full hidden flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
      <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"></div>
      <div class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
        <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]" style="        height: auto;
    width: 100px;
    position: absolute;"
             loading="eager" alt="" src="/group-32@2x.png">
<!--        <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>-->
      </div>
      <!--                &lt;div class=&quot;flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
      <!--                    --><!--                &lt;/div&gt;-->
      <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
        <div class="flex flex-row items-start justify-start gap-[12px] flex-wrap" style="
    width: 100%;
">
          <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
          <!--            <div class="relative z-[1]" style="flex-grow: 1;-->
<!--  width: 33%;">About Us</div>-->
          <a href="/news" class="relative z-[1] text-white">News</a>
           <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>

          <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
          <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
        </div>
      </div>
      <!--                &lt;div class=&quot;hidden mq750:flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
      <!--                    &lt;div class=&quot;relative z-[1]&quot;&gt;Contact Us&lt;/div&gt;-->
      <!--                    &lt;div class=&quot;w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full&quot;&gt;-->
      <!--                        &lt;div class=&quot;self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap&quot;&gt;-->
      <!--                            &lt;div class=&quot;flex flex-col items-start justify-start gap-[12px]&quot;&gt;-->
      <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;About Us&lt;/div&gt;-->
      <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;News&lt;/div&gt;-->
      <!--                            &lt;/div&gt;-->
      <!--                            &lt;div class=&quot;flex-[0.8557] flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1&quot;&gt;-->
      <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;AI Software&lt;/div&gt;-->
      <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Resourses&lt;/div&gt;-->
      <!--                            &lt;/div&gt;-->
      <!--                            &lt;div class=&quot;flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]&quot;&gt;-->
      <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Privacy Policy&lt;/div&gt;-->
      <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Terms of Use&lt;/div&gt;-->
      <!--                            &lt;/div&gt;--><!--                        &lt;/div&gt;-->
      <!--                    &lt;/div&gt;--><!--                &lt;/div&gt;--></footer>
    <footer style="    padding-left: 0;
    width: 1290px;"
            class="flex-1 mq750:hidden flex flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:gap-[89px] mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
      <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"/>
      <div class="w-[226px] ml-[100px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
        <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
             loading="eager" alt="" src="/group-32@2x.png"/>
<!--        <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>-->
      </div>

        <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
            <div class="self-stretch gap-[40px] flex flex-row items-start justify-between mq450:flex-wrap">
                <div class="flex flex-col items-start justify-start px-0 pb-0">
                    <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
                </div>
                <div class="flex flex-col items-start justify-start gap-[12px]">
                    <a href="/news" class="relative z-[1] text-white">News</a>
                </div>
                <div class="flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1">
                    <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>
                    <!--                            <div class="relative z-[1]">Resourses</div>-->
                </div>
                <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]">
                    <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
                    <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
                </div>
            </div>
        </div>

    </footer>
  </main>
</template>

<script>
import {defineComponent} from "vue";
import NewsItem from "@/components/NewsItem.vue";

export default defineComponent({
  name: "NewsPage",
  components: { NewsItem },
});
</script>

<style scoped>

</style>