<template>
  <div
      class="w-full h-full bg-d2d3c overflow-hidden flex flex-col items-start justify-start gap-[4px] tracking-[normal] text-left text-xl text-white-back font-helvetica mq450:h-auto"
  >
    <div class="relative mt-[65px] mq750:mt-[37px] self-stretch flex flex-row items-start justify-start pt-0 pb-[3px] pr-[5px] pl-0 box-border max-w-full shrink-0 text-left text-23xl text-white-back font-helvetica">
      <img class="w-full h-full max-w-full overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
           loading="eager" alt="" src="/line-1@2x.svg"/>
      <h1 class="absolute top-[80px] mq750:top-[30px] mq450:top-[0] mq450:mt-[19px] m-0 w-full text-left ml-[100px] mq750:ml-[15px] h-[55px] text-inherit font-bold font-inherit inline-block max-w-full z-[1] mq750:text-[24px] mq1050:text-15xl">Terms of Use</h1>
    </div>
    <div class="container terms-of-us">
      <div class="terms-of-us__text pb-[20px] mq750:mt-[15px]">
        By accessing or using the site, you are accepting these terms (on behalf of yourself or the entity that you represent), and you represent and warrant that you have the right, authority, and capacity to enter into these terms (on behalf of yourself or the entity that you represent). You may not access or use the site or accept the terms if you are not at least 18 years old. If you do not agree with all of the provisions of these terms, do not access and/or use the site. XENdeck reserves the right to update the terms at any time without notice to you. Continued use of our Site following any change to the terms indicates your acknowledgement of such changes and agreement to be bound by modified terms and conditions.
      </div>
      <div class="terms-of-us__title">
        1. Accounts
      </div>
      <div class="terms-of-us__text">
        <span>1.1 ACCOUNT CREATION</span>
        <br/><br/>
        In order to use certain features of the Site, you must register for an account (“Account”) and provide certain information about yourself as prompted by the account registration form. You represent and warrant that: (a) all required registration information you submit is current, complete, truthful and accurate; and (b) you will maintain the accuracy of such information. You may delete your Account at any time, for any reason, by sending an email to us at privacy@mail.com and telling us, with sufficient detail, that you wish to delete your Account with us.
        XENdeck may suspend or terminate your Account in accordance with Section 10.
        <br/><br/>
        <span>1.2 ACCOUNT RESPONSIBILITIES</span>
        <br/><br/>
        You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. XENdeck is not liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. You may not share your account with anyone else. You agree to immediately notify XENdeck  of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. XENdeck cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.
      </div>
      <div class="terms-of-us__title">
        2. Access to the site
      </div>
      <div class="terms-of-us__text">
        <span>2.1 LICENSE</span>
        <br/><br/>
        Subject to these Terms, XENdeck grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Site solely for your own personal, noncommercial use.
        <br/><br/>
        <span>2.2 CERTAIN RESTRICTIONS</span>
        <br/><br/>
        The rights granted to you in these Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website, product, or service; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information of XENdeck without the express written consent of XENdeck. Unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms. All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof.
        <br/><br/>
        <span>2.3 MODIFICATION</span>
        <br/><br/>
        XENdeck reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) with or without notice to you. You agree that XENdeck I will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.
        <br/><br/>
        <span>2.4 NO SUPPORT OR MAINTENANCE</span>
        <br/><br/>
        You acknowledge and agree that XENdeck  will have no obligation to provide you with any support or maintenance in connection with the Site.
      </div>
      <div class="terms-of-us__title">
        3. Copyright Policy
      </div>
      <div class="terms-of-us__text">
        XENdeck respects the intellectual property of others and asks that users of our Site do the same. In connection with our Site, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination, in appropriate circumstances, of users of our online Site who are infringers of intellectual property rights, including copyrights. If you believe that one of our users is, through the use of our Site, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent:
        <br/><br/>
        <ul class="terms-of-us__list">
          <li>your physical or electronic signature;</li>
          <li>identification of the copyrighted work(s) that you claim to have been infringed;</li>
          <li>identification of the material on our services that you claim is infringing and that you request us to remove;</li>
          <li>sufficient information to permit us to locate such material;</li>
          <li>your address, telephone number, and e-mail address;</li>
          <li>a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and</li>
          <li>a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</li>
        </ul>
      </div>
      <div class="terms-of-us__text"></div>

      <div class="terms-of-us__title">
        4. General
      </div>
      <div class="terms-of-us__text">
        <span>4.1 GOVERNING LAW AND DISPUTE RESOLUTION</span>
        <br/><br/>
        a. You agree that all matters relating to your access and use of the Site, including all disputes, will be governed by the laws of the United States and the laws of the State of California, without regard to its conflict of laws provisions. The United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Transactions Act are specifically excluded from application to this Agreement.
        <br/><br/>
        b. Any claim or dispute arising from your access and use of the Site shall be resolved in the United States District Court for the Northern District of California San Francisco Branch or the Superior and Municipal Courts of the State of California, San Mateo County, and you submit to the personal and exclusive jurisdiction thereof, and waive any venue or jurisdiction objections against such courts.
        <br/><br/>
        c. Notwithstanding the foregoing, in the event of your or other’s unauthorized access to or use of the Site or its content in violation of these Terms, you agree that we are entitled to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction.
        <br/><br/>
        <span>4.2 CONTACT INFORMATION</span>
        <br/><br/>
        XENdeck, Inc., 1400 Seaport Blvd., Redwood City, CA 94063, Attention: General Counsel.
        January 10, 2024
      </div>
    </div>
    <div class="self-stretch bg-[#2d2d3c] flex flex-row items-start justify-start pt-0 pb-1.5 pr-[5px] pl-0 box-border max-w-full">
      <div class="h-28 flex-1 relative shrink-0 flex items-center justify-center">
        <img class="h-full flex-1 max-w-[101%] left-positioning overflow-hidden shrink-0 object-contain absolute left-[0px] top-[4px] w-full [transform:scale(1.125)]"
             loading="eager" alt="" src="/line-2-1@2x.svg" style="
    width: 1440px;
    transform: scalex(2)"/>
      </div>
    </div>
    <main class="mx-auto self-stretch flex flex-row items-start justify-start pt-0 px-0 box-border max-w-full lg:box-border ">
      <footer style="padding: 15px;width:100%;flex-wrap: nowrap;"
              class="mq750:flex flex-1 mq750:gap-0 mq750:w-full hidden flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
        <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"></div>
        <div class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
          <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]" style="        height: auto;
    width: 100px;
    position: absolute;"
               loading="eager" alt="" src="/group-32@2x.png">
          <!-- <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>
        --></div>
        <!--                &lt;div class=&quot;flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
        <!--                    --><!--                &lt;/div&gt;-->
        <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
          <div class="flex flex-row items-start justify-start gap-[12px] flex-wrap" style="
    width: 100%;
">
            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
            <!--            <div class="relative z-[1]" style="flex-grow: 1;-->
<!--  width: 33%;">About Us</div>-->
            <a href="/news" class="relative z-[1] text-white">News</a>
             <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>

            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
          </div>
        </div>
        <!--                &lt;div class=&quot;hidden mq750:flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
        <!--                    &lt;div class=&quot;relative z-[1]&quot;&gt;Contact Us&lt;/div&gt;-->
        <!--                    &lt;div class=&quot;w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full&quot;&gt;-->
        <!--                        &lt;div class=&quot;self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap&quot;&gt;-->
        <!--                            &lt;div class=&quot;flex flex-col items-start justify-start gap-[12px]&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;About Us&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;News&lt;/div&gt;-->
        <!--                            &lt;/div&gt;-->
        <!--                            &lt;div class=&quot;flex-[0.8557] flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;AI Software&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Resourses&lt;/div&gt;-->
        <!--                            &lt;/div&gt;-->
        <!--                            &lt;div class=&quot;flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Privacy Policy&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Terms of Use&lt;/div&gt;-->
        <!--                            &lt;/div&gt;--><!--                        &lt;/div&gt;-->
        <!--                    &lt;/div&gt;--><!--                &lt;/div&gt;--></footer>
      <footer style="    padding-left: 0;
    width: 1290px;"
              class="flex-1 mq750:hidden flex flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:gap-[89px] mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
        <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"/>
        <div class="w-[226px] ml-[100px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
          <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
               loading="eager" alt="" src="/group-32@2x.png"/>
          <!-- <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>
        --></div>
        <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
                    <div class="self-stretch gap-[40px] flex flex-row items-start justify-between mq450:flex-wrap">
                        <div class="flex flex-col items-start justify-start px-0 pb-0">
                            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
                        </div>
                        <div class="flex flex-col items-start justify-start gap-[12px]">
                            <a href="/news" class="relative z-[1] text-white">News</a>
                        </div>
                        <div class="flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1">
                            <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>
<!--                            <div class="relative z-[1]">Resourses</div>-->
                        </div>
                        <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]">
                            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
                            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
                        </div>
                    </div>
                </div>
      </footer>
    </main>
  </div>
</template>

<script>

</script>

<style lang="scss" scoped>
.container {
  width: auto;
  margin: 0 100px;

  @media (max-width: 750px) {
   margin: 0 15px;
  }
}

.terms-of-us {
  margin: 0 100px;
  @media (max-width: 750px) {
    margin: 0 15px;
  }
  &__title {
    font-size: 42px;
    font-weight: 700;
    line-height: 54px;
    margin-bottom: 15px;

    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 10px;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 60px;
    
    @media (max-width: 750px) {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 19px;
    }

    & span {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 400;
      line-height: 23px;
      color: #a2a2a2;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  &__list {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;

    @media (max-width: 750px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
</style>