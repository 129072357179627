<template>
  <section
    class="absolute top-[6787px] left-[102px] w-[1239px] flex flex-col items-start justify-start gap-[68px_0px] max-w-full text-left text-23xl text-white-back font-helvetica"
  >
    <div
      class="self-stretch flex flex-row items-start justify-center py-0 px-5"
    >
      <h1
        class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1025:text-15xl"
      >
        Related Resources
      </h1>
    </div>
    <div
      class="self-stretch grid flex-row items-start justify-start gap-[0px_19.5px] max-w-full grid-cols-[repeat(3,_minmax(300px,_1fr))] text-xl mq750:grid-cols-[minmax(300px,_1fr)] mq1025:justify-center mq1025:grid-cols-[repeat(2,_minmax(300px,_520px))]"
    >
      <div
        class="flex flex-col items-start justify-start gap-[2px_0px] max-w-full"
      >
        <img
          class="self-stretch h-56 relative max-w-full overflow-hidden shrink-0 object-cover"
          loading="lazy"
          alt=""
          src="/aisoftware/glowingmolecularstructureinfuturisticgalaxybackdropgeneratedbyai-1@2x.png"
        />
        <div
          class="self-stretch rounded-8xs bg-gray-200 flex flex-col items-end justify-start pt-[2.5px] pb-[23px] pr-[3px] pl-[1.5px] box-border gap-[29px] max-w-full"
        >
          <img
            class="self-stretch h-[144.5px] relative rounded-8xs max-w-full overflow-hidden shrink-0 hidden"
            alt=""
            src="/aisoftware/rectangle-3.svg"
          />
          <div
            class="self-stretch h-[72px] flex flex-col items-start justify-start gap-[20px_0px] max-w-full"
          >
            <img
              class="self-stretch h-[9px] relative max-w-full overflow-hidden shrink-0 z-[1]"
              alt=""
              src="/aisoftware/line-3-11.svg"
            />
            <div
              class="self-stretch flex flex-row items-start justify-start py-0 pr-[11px] pl-[12.5px] box-border max-w-full shrink-0"
            >
              <b
                class="h-[52px] flex-1 relative inline-block max-w-full z-[1] mq450:text-base"
              >
                <p class="m-0">XENdeck Announces Launch</p>
                <p class="m-0">of AI Product Suite</p>
              </b>
            </div>
          </div>
          <div
            class="self-stretch flex flex-row items-start justify-start py-0 px-1.5 box-border max-w-full text-base text-grey"
          >
            <div
              class="flex-1 flex flex-row items-start justify-between max-w-full gap-[20px] mq450:flex-wrap"
            >
              <div class="relative uppercase z-[1]">Announcements</div>
              <div class="relative uppercase z-[1]">View</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-start justify-start max-w-full">
        <img
          class="self-stretch h-[228.5px] relative rounded-t-8xs rounded-b-none max-w-full overflow-hidden shrink-0 object-cover"
          loading="lazy"
          alt=""
          src="/aisoftware/onepersontypingonfuturisticglowingcomputergeneratedbyai-1@2x.png"
        />
        <div
          class="self-stretch h-[144.5px] rounded-8xs bg-gray-200 flex flex-col items-end justify-start pt-[2.5px] pb-[23px] pr-[3px] pl-[1.5px] box-border gap-[20px_0px] max-w-full z-[1] mt-[-2px] mq450:h-auto"
        >
          <img
            class="self-stretch h-[144.5px] relative rounded-8xs max-w-full overflow-hidden shrink-0 hidden"
            alt=""
            src="/aisoftware/rectangle-3.svg"
          />
          <img
            class="self-stretch h-[9px] relative max-w-full overflow-hidden shrink-0 z-[2]"
            alt=""
            src="/aisoftware/line-24.svg"
          />
          <div
            class="self-stretch flex flex-row items-start justify-start py-0 px-1.5 box-border max-w-full shrink-0"
          >
            <div
              class="flex-1 flex flex-col items-start justify-start gap-[29px_0px] max-w-full"
            >
              <b
                class="self-stretch relative inline-block pr-[5px] z-[2] mq450:text-base"
              >
                <p class="m-0">How artificial intelligence</p>
                <p class="m-0">is transforming the world</p>
              </b>
              <div
                class="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-px box-border max-w-full text-base text-grey"
              >
                <div
                  class="flex-1 flex flex-row items-start justify-between max-w-full gap-[20px] mq450:flex-wrap"
                >
                  <div class="relative uppercase z-[2]">Article</div>
                  <div class="relative uppercase z-[2]">View</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-start justify-start max-w-full">
        <img
          class="self-stretch h-[227px] relative rounded-t-8xs rounded-b-none max-w-full overflow-hidden shrink-0 object-cover"
          loading="lazy"
          alt=""
          src="/aisoftware/keyboardsocialbusinessyoungperson-1@2x.png"
        />
        <div
          class="self-stretch h-[144.5px] rounded-8xs bg-gray-200 flex flex-col items-start justify-start pt-[2.5px] px-[1.5px] pb-[23px] box-border gap-[20px_0px] max-w-full z-[1] mt-[-0.5px] mq450:h-auto"
        >
          <img
            class="self-stretch h-[144.5px] relative rounded-8xs max-w-full overflow-hidden shrink-0 hidden"
            alt=""
            src="/aisoftware/rectangle-3.svg"
          />
          <img
            class="self-stretch h-[9px] relative max-w-full overflow-hidden shrink-0 z-[2]"
            alt=""
            src="/aisoftware/line-24.svg"
          />
          <div
            class="self-stretch flex flex-row items-start justify-start pt-0 pb-[9px] pr-3 pl-[12.5px] box-border max-w-full shrink-0"
          >
            <b
              class="flex-1 relative inline-block max-w-full box-border pr-0 z-[2] mq450:text-base"
            >
              <p class="m-0">Without AI we won`t meet ESG goals</p>
              <p class="m-0">and address climate change</p>
            </b>
          </div>
          <div
            class="self-stretch flex flex-row items-start justify-start py-0 pr-[7px] pl-[13.5px] box-border max-w-full text-base text-grey"
          >
            <div
              class="flex-1 flex flex-row items-start justify-between max-w-full gap-[20px] mq450:flex-wrap"
            >
              <div class="relative uppercase z-[2]">Article</div>
              <div class="relative uppercase z-[2]">View</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "FrameComponent1",
  });
</script>
