<template>
  <div
      class="w-full h-full bg-d2d3c overflow-hidden flex flex-col items-start justify-start gap-[4px] tracking-[normal] text-left text-xl text-white-back font-helvetica mq450:h-auto"
  >
    <div class="relative mt-[65px] mq750:mt-[37px] self-stretch flex flex-row items-start justify-start pt-0 pb-[3px] pr-[5px] pl-0 box-border max-w-full shrink-0 text-left text-23xl text-white-back font-helvetica">
      <img class="w-full h-full max-w-full overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
           loading="eager" alt="" src="/line-1@2x.svg"/>
      <h1 class="absolute top-[80px] mq750:top-[30px] mq450:top-[0] mq450:mt-[19px] m-0 w-full text-left ml-[100px] mq750:ml-[15px] h-[55px] text-inherit font-bold font-inherit inline-block max-w-full z-[1] mq750:text-[24px] mq1050:text-15xl">Privacy Policy</h1>
    </div>
    <div class="container privacy-policy">
      <div class="privacy-policy__text pb-[20px] mq750:mt-[15px]">
        XENdeck is committed to the protection of the privacy rights of individuals. This Privacy Policy (“XENdeck Privacy Policy”) explains how XENdeck handles information relating to an identified or identifiable individual (“Personal Information”) that is provided to XENdeck or that XENdeck collects when you visit www.xendeck. com (the “Site”). More specifically, this Privacy Policy informs you as to the categories of Personal Information that we collect or process, why such Personal Information is processed, how it is used and what rights you have. This Privacy Policy does not apply to Personal Information collected on any third-party site or application (including advertising) that may link to or be accessible from our websites. We are not responsible for the privacy policies or data collection, use and disclosure practices of those sites. We encourage you to review the privacy policies of each site you visit.
        <br/><br/>
        The terms “you,” “your,” and “yours” refer to anyone utilizing the Site. The terms “XENdeck,” “we,” “us,” and “our” refer to XENdeck, Inc. and its subsidiaries and affiliates. By using the Site, you consent to the practices set forth in this Privacy Policy. We may periodically make changes to this Privacy Policy that we will include on this page. It is your responsibility to review this Privacy Policy frequently and remain informed about any changes.
      </div>
      <div class="privacy-policy__title">
        1. How and What Personal Information Is Collected
      </div>
      <div class="privacy-policy__text">
        Use of the Site; Cookies. XENdeck collects Personal Information that you voluntarily provide us by completing and submitting forms on this Site, such as on the “Contact Us” or “Ask an Expert” pages on the Site. This Personal Information may include information such as your name, email address, telephone number, company name and size, job title and the reason why you are contacting us. You may also use the site to apply for a job with us, in which case you will provide us Personal Information relevant to your job application.
        <br/><br/>
        The Site also automatically collects Personal Information through the use of cookies or similar technologies to facilitate and enhance your use of the Site and track usage patterns. Cookies are commonly used small files that are placed on your device. For example, we collect information on the pages you have visited, your referring URL or IP address, the time of your visit and related session information, your requested page, browser type, USR agent, status of request, number of bytes in the response, and query string. The cookies that the Site uses generally fall under the following categories:
        <br/><br/>
        <span>1.1 Essential Cookies</span>
        <br/><br/>
        Essential cookies facilitate your use and navigation of the Site. Essential cookies include session cookies, which are temporary cookies that remain in the cookie file of your browser until you close the browser.
        <br/><br/>
        <span>1.2 Analytics Cookies</span>
        <br/><br/>
        We and our service providers use analytics cookies that collect aggregated and / or anonymous information to analyze the performance of the Site and how it is used. For example, analytics cookies show us which areas of the Site are most frequently visited. An example of a third-party analytics cookie is the Google Analytics cookie.
        Users may obtain information on how to opt out of Google Analytics cookies
        at the following URL: https://policies.google.com/technologies/ads.
        <br/><br/>
        <span>1.3 Third Party Marketing Cookies</span>
        <br/><br/>
        These cookies collect information on your activities on the Site and other sites to display targeted marketing based on your interests.
        <br/><br/>
        Some browsers offer a “do not track” (“DNT”) option. Because no common industry or legal standard for DNT has been adopted by industry groups, technology companies or regulators, we do not respond to DNT signals. We will make efforts to continue to monitor developments around DNT browser technology and the implementation of a standard. However, you may elect not to accept cookies by changing the designated settings on your web browser. Please note that some features of our Site may not function without the essential cookies.
      </div>
      <div class="privacy-policy__title">
        2. How Information Is Used
      </div>
      <div class="privacy-policy__text">
        We may use the Personal Information in one or more of the following ways:
        <ul class="privacy-policy__list">
          <li>Sending information or materials you request</li>
          <li>Responding to your questions and concerns posted via the Site</li>
          <li>Providing a newsletter or other marketing communications</li>
          <li>Operating and improving the Site</li>
          <li>Improving our products and services and our marketing efforts</li>
          <li>Conducting research and analysis</li>
          <li>Processing and delivering contest entries and rewards</li>
          <li>Evaluating your job application if you have submitted it through the Site</li>
          <li>Linking or combining your information with other information we may receive from third parties to help better serve you.</li>
        </ul>
      </div>
      <div class="privacy-policy__title">
        3. Information Security
      </div>
      <div class="privacy-policy__text">
        XENdeck takes seriously the security of your Personal Information. In order to protect your Personal Information from unauthorized access, disclosure, alteration, or destruction, we implement technical and organizational measures that we consider appropriate in light of the state of the art, the costs of implementation, and the nature, scope, context, and purposes of processing as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons. However, you should be aware that we cannot ensure that your Personal Information will never be accessed, disclosed, altered, or destroyed in ways not described in this Privacy Policy. By way of example, third parties may unlawfully intercept or access transmissions or private communications.
      </div>
      <div class="privacy-policy__title">
        4. Questions and Concerns
      </div>
      <div class="privacy-policy__text">
        If you have any questions or concerns regarding the way we collect or handle your information,
        please contact us at support@xendeck.ai or invest@xendeck.ai
        <br/><br/>
        We will take every privacy concern seriously and will assess it in a reasonably timely manner.
      </div>
    </div>

    <div class="self-stretch bg-[#2d2d3c] flex flex-row items-start justify-start pt-0 pb-1.5 pr-[5px] pl-0 box-border max-w-full">
      <div class="h-28 flex-1 relative shrink-0 flex items-center justify-center">
        <img class="h-full flex-1 max-w-[101%] left-positioning overflow-hidden shrink-0 object-contain absolute left-[0px] top-[4px] w-full [transform:scale(1.125)]"
             loading="eager" alt="" src="/line-2-1@2x.svg" style="
    width: 1440px;
    transform: scalex(2)"/>
      </div>
    </div>
    <main class="mx-auto self-stretch flex flex-row items-start justify-start pt-0 px-0 box-border max-w-full lg:box-border ">
      <footer style="padding: 15px;width:100%;flex-wrap: nowrap;"
              class="mq750:flex flex-1 mq750:gap-0 mq750:w-full hidden flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
        <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"></div>
        <div class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
          <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]" style="        height: auto;
    width: 100px;
    position: absolute;"
               loading="eager" alt="" src="/group-32@2x.png">
          <!-- <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>
        --></div>
        <!--                &lt;div class=&quot;flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
        <!--                    --><!--                &lt;/div&gt;-->
        <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
          <div class="flex flex-row items-start justify-start gap-[12px] flex-wrap" style="
    width: 100%;
">
            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
            <!--            <div class="relative z-[1]" style="flex-grow: 1;-->
<!--  width: 33%;">About Us</div>-->
            <a href="/news" class="relative z-[1] text-white">News</a>
             <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>

            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
          </div>
        </div>
        <!--                &lt;div class=&quot;hidden mq750:flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
        <!--                    &lt;div class=&quot;relative z-[1]&quot;&gt;Contact Us&lt;/div&gt;-->
        <!--                    &lt;div class=&quot;w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full&quot;&gt;-->
        <!--                        &lt;div class=&quot;self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap&quot;&gt;-->
        <!--                            &lt;div class=&quot;flex flex-col items-start justify-start gap-[12px]&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;About Us&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;News&lt;/div&gt;-->
        <!--                            &lt;/div&gt;-->
        <!--                            &lt;div class=&quot;flex-[0.8557] flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;AI Software&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Resourses&lt;/div&gt;-->
        <!--                            &lt;/div&gt;-->
        <!--                            &lt;div class=&quot;flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Privacy Policy&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Terms of Use&lt;/div&gt;-->
        <!--                            &lt;/div&gt;--><!--                        &lt;/div&gt;-->
        <!--                    &lt;/div&gt;--><!--                &lt;/div&gt;--></footer>
      <footer style="    padding-left: 0;
    width: 1290px;"
              class="flex-1 mq750:hidden flex flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:gap-[89px] mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
        <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"/>
        <div class="w-[226px] ml-[100px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
          <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
               loading="eager" alt="" src="/group-32@2x.png"/>
          <!-- <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>
        --></div>
        <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
                    <div class="self-stretch gap-[40px] flex flex-row items-start justify-between mq450:flex-wrap">
                        <div class="flex flex-col items-start justify-start px-0 pb-0">
                            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
                        </div>
                        <div class="flex flex-col items-start justify-start gap-[12px]">
                            <a href="/news" class="relative z-[1] text-white">News</a>
                        </div>
                        <div class="flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1">
                            <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>
<!--                            <div class="relative z-[1]">Resourses</div>-->
                        </div>
                        <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]">
                            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
                            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
                        </div>
                    </div>
                </div>
      </footer>
    </main>
  </div>
</template>

<script>

</script>

<style lang="scss" scoped>
.container {
  width: auto;
  margin: 0 100px;

  @media (max-width: 750px) {
   margin: 0 15px;
  }
}

.privacy-policy {
  margin: 0 100px;
  @media (max-width: 750px) {
    margin: 0 15px;
  }
  &__title {
    font-size: 42px;
    font-weight: 700;
    line-height: 54px;
    margin-bottom: 15px;

    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 10px;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    margin-bottom: 60px;
    
    @media (max-width: 750px) {
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 19px;
    }

    & span {
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 400;
      line-height: 23px;
      color: #a2a2a2;

      @media (max-width: 750px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  &__list {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;

    @media (max-width: 750px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
</style>