<template>
  <section
    class="absolute top-[5027px] left-[103px] w-[1238px] flex flex-col items-start justify-start gap-[64px_0px] max-w-full text-left text-23xl text-white-back font-helvetica"
  >
    <div
      class="self-stretch flex flex-row items-start justify-center py-0 pr-5 pl-[21px]"
    >
      <h1
        class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1025:text-15xl"
      >
        Powering World’s Most Iconic Organizations
      </h1>
    </div>
    <div
      class="self-stretch flex flex-col items-start justify-start gap-[30px_0px] max-w-full text-17xl"
    >
      <div
        class="w-[879px] flex flex-row items-start justify-start py-0 px-0.5 box-border max-w-full"
      >
        <div
          class="flex-1 flex flex-row items-start justify-start gap-[0px_40px] max-w-full mq750:flex-wrap"
        >
          <img
            class="h-[289px] flex-1 relative rounded-8xs max-w-full overflow-hidden object-cover min-w-[308px]"
            loading="lazy"
            alt=""
            src="/aisoftware/76zb9kx9zd14jmu52ymi4wxk2m5iw62l-1@2x.png"
          />
          <div
            class="flex flex-col items-start justify-start pt-[63px] px-0 pb-0 box-border min-w-[361px] max-w-full mq450:min-w-full mq750:flex-1"
          >
            <div class="flex flex-col items-start justify-start gap-[93px_0px]">
              <h2
                class="m-0 h-[47px] relative text-inherit font-bold font-inherit inline-block mq450:text-3xl mq1025:text-10xl"
              >
                Enterprise AI at Shell
              </h2>
              <div
                class="w-[153px] relative text-xl uppercase text-grey inline-block whitespace-nowrap box-border pr-5 mq450:text-base"
              >
                learn more
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="self-stretch flex flex-row flex-wrap items-end justify-start gap-[0px_30px] max-w-full text-5xl"
      >
        <div
          class="flex-1 flex flex-col items-start justify-end pt-[278px] px-[19px] pb-5 box-border relative gap-[46px_0px] min-w-[393px] max-w-full mq450:min-w-full"
        >
          <b class="w-[550px] relative inline-block max-w-full mq450:text-lgi">
            <p class="m-0">AI-Enhanced Modeling and Simulation in the</p>
            <p class="m-0">DoD - Moving from the Lab to the Enterprise</p>
          </b>
          <div
            class="relative text-xl uppercase text-grey whitespace-nowrap mq450:text-base"
          >
            learn more
          </div>
          <div
            class="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] z-[1] flex items-center justify-center"
          >
            <img
              class="w-full h-full max-w-full overflow-hidden max-h-full z-[1] object-contain absolute left-[0px] top-[4px] [transform:scale(1.033)]"
              loading="lazy"
              alt=""
              src="/aisoftware/group-433.svg"
            />
          </div>
        </div>
        <div
          class="flex-1 flex flex-col items-start justify-end pt-[280px] px-5 pb-5 box-border relative gap-[46px_0px] min-w-[393px] max-w-full mq450:min-w-full"
        >
          <div
            class="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-center justify-center z-[0]"
          >
            <img
              class="w-full h-full max-w-full overflow-hidden max-h-full object-contain absolute left-[0px] top-[5px] [transform:scale(1.028)]"
              loading="lazy"
              alt=""
              src="/aisoftware/group-434.svg"
            />
          </div>
          <b
            class="w-[550px] relative inline-block max-w-full z-[1] mq450:text-lgi"
          >
            <p class="m-0">Optimize Production Schedules and</p>
            <p class="m-0">Decrease Manufacturing Costs</p>
          </b>
          <div
            class="relative text-xl uppercase text-grey whitespace-nowrap z-[1] mq450:text-base"
          >
            learn more
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "FrameComponent",
  });
</script>
