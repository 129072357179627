<template>
  <footer
    class="absolute top-[7900px] left-[0px] bg-d2d3c w-full flex flex-row items-start justify-start pt-[19px] px-[100px] pb-0.5 box-border gap-[89px] max-w-full text-left text-base text-white-back font-helvetica mq750:gap-[22px] mq750:pl-[25px] mq750:pr-[25px] mq750:box-border mq1125:flex-wrap mq1125:gap-[44px] mq1125:pl-[50px] mq1125:pr-[50px] mq1125:box-border"
  >
    <div class="h-[102px] w-[1440px] relative bg-d2d3c hidden max-w-full" />
    <div
      class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[35px_0px] text-3xs"
    >
      <img
        class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 z-[1]"
        alt=""
        src="/aisoftware/group-32-1.svg"
      />
<!--      <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>-->
    </div>
    <div class="flex flex-col items-start justify-start pt-[5px] px-0 pb-0">
      <div class="relative z-[1]">Contact Us</div>
    </div>
    <div
      class="flex flex-col items-start justify-start pt-[5px] pb-0 pr-[3px] pl-0"
    >
      <div class="flex flex-col items-start justify-start gap-[8px_0px]">
        <div class="h-[22px] relative inline-block z-[1]">About Us</div>
        <div class="h-[22px] relative inline-block z-[1]">News</div>
      </div>
    </div>
    <div
      class="w-[289px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border"
    >
      <div
        class="self-stretch flex flex-row items-start justify-between gap-[20px]"
      >
        <div class="flex flex-col items-start justify-start gap-[8px_0px]">
          <div class="relative z-[1]">AI Software</div>
          <div class="h-[22px] relative inline-block z-[1]">Resourses</div>
        </div>
        <div class="flex flex-col items-start justify-start gap-[8px_0px]">
          <div class="relative z-[1]">Privacy Policy</div>
          <div class="relative z-[1]">Terms of Use</div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "GroupComponent",
  });
</script>
