<template>
  <div
    class="self-stretch flex flex-row items-end justify-start gap-[0px_54px] max-w-full text-left text-23xl text-white-back font-helvetica mq1025:flex-wrap"
  >
    <div
      class="flex-1 rounded-8xs flex flex-row items-start justify-start bg-[url('/public/aisoftware/12012024-130040@2x.png')] bg-cover bg-no-repeat bg-[top] min-w-[460px] max-w-full mq750:min-w-full"
    >
      <img
        class="h-[427px] w-[707px] relative rounded-8xs object-cover hidden min-h-[427px] max-w-full"
        alt=""
        src="/aisoftware/12012024-130040@2x.png"
      />
      <img
        class="h-[429px] flex-1 relative max-w-full overflow-hidden z-[1]"
        loading="lazy"
        alt=""
        src="/aisoftware/group-405.svg"
      />
    </div>
    <div
      class="w-[478px] flex flex-col items-start justify-start pt-0 px-0 pb-[7px] box-border min-w-[478px] max-w-full mq750:min-w-full mq1025:flex-1"
    >
      <div
        class="self-stretch flex flex-col items-start justify-start gap-[30px_0px] max-w-full"
      >
        <h1
          class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1025:text-15xl"
        >
          Open and Interoperable
        </h1>
        <div
          class="self-stretch flex flex-col items-start justify-start gap-[24px_0px] max-w-full text-13xl"
        >
          <h2
            class="m-0 self-stretch h-[74px] relative text-inherit font-normal font-inherit inline-block box-border pr-px mq450:text-lgi mq1025:text-7xl"
          >
            Designed for the modern enterprise
          </h2>
          <div
            class="self-stretch flex flex-col items-start justify-start gap-[20px_0px] max-w-full text-xl"
          >
            <div
              class="self-stretch h-28 relative font-light inline-block mq450:text-base"
            >
              <p class="m-0">
                An open, AI-first architecture allows the XENdeck
              </p>
              <p class="m-0">
                AI Platform to integrate natively with existing data and ML
                investments — giving users one workspace across the AI
                lifecycle.
              </p>
            </div>
            <div
              class="flex flex-row items-start justify-start py-0 pr-5 pl-0 box-border gap-[0px_10px] max-w-full text-grey"
            >
              <div class="relative uppercase mq450:text-base">
                Learn more about Architecture
              </div>
              <div
                class="h-3 flex flex-col items-start justify-start pt-3 px-0 pb-0 box-border"
              >
                <img
                  class="w-[19px] h-[7.4px] relative"
                  alt=""
                  src="/aisoftware/arrow-1-3.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "CustomShapeNodes",
  });
</script>
