<template>
  <section
    class="absolute top-[2177px] left-[102px] w-[1239px] flex flex-row items-end justify-start gap-[0px_15px] max-w-full text-left text-23xl text-white-back font-helvetica mq1025:flex-wrap"
  >
    <Frame3
      largestEnterpriseAIFootpr="Largest enterprise AI footprint"
      theXENdeckAIPlatformUnloc="The XENdeck AI Platform unlocks business value through powerful data unification, seamless prototyping, and rapid production "
      deploymentsPowering18Bill="deployments — powering 1.8 billion predictions per day."
      learnMoreAboutModelOps="Learn more about Model Ops"
    />
    <div
      class="flex-1 flex flex-row items-start justify-start pt-0 pb-[2.400000000000091px] pr-0 pl-9 box-border relative min-w-[485px] max-w-full text-29xl mq750:min-w-full"
    >
      <div
        class="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-8xs z-[1]"
      />
      <div class="h-[424.6px] flex-1 relative max-w-full">
        <img
          class="absolute top-[0px] left-[2px] w-[707px] h-[424px] object-cover"
          loading="lazy"
          alt=""
          src="/aisoftware/12012024-151600-1@2x.png"
        />
        <div
          class="absolute top-[2px] left-[0px] box-border w-full h-full z-[2] border-[1px] border-solid border-gray-300"
        >
          <div
            class="absolute top-[0px] left-[0px] box-border w-full h-full hidden border-[1px] border-solid border-gray-300"
          />
          <h1
            class="m-0 absolute top-[156px] left-[219px] text-inherit uppercase font-light font-inherit z-[3] mq450:text-10xl mq1025:text-19xl"
          >
            Placeholder
          </h1>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { defineComponent } from "vue";
  import Frame3 from "@/components/AiSoftWare/Frame3.vue";

  export default defineComponent({
    name: "BranchNode",
    components: { Frame3 },
  });
</script>
