<template>
  <section
    class="absolute top-[1630px] left-[102px] w-[1239px] flex flex-row items-end justify-start gap-[0px_54px] max-w-full text-left text-29xl text-white-back font-helvetica mq1025:flex-wrap"
  >
    <div
      class="h-[427px] flex-1 relative rounded-8xs bg-[url('/public/aisoftware/12012024-130040@2x.png')] bg-cover bg-no-repeat bg-[top] min-w-[460px] max-w-full z-[1] mq750:min-w-full"
    >
      <img
        class="absolute top-[0px] left-[0px] rounded-8xs w-[707px] h-[427px] object-cover hidden z-[1]"
        alt=""
        src="/aisoftware/12012024-130040@2x.png"
      />
      <div
        class="absolute top-[2px] left-[0px] box-border w-full h-full z-[3] border-[1px] border-solid border-gray-300"
      >
        <h1
          class="m-0 absolute top-[156px] left-[180px] text-inherit uppercase font-light font-inherit z-[2] mq450:text-10xl mq1025:text-19xl"
        >
          PLaceholder
        </h1>
        <div
          class="absolute top-[0px] left-[0px] box-border w-full h-full hidden border-[1px] border-solid border-gray-300"
        />
      </div>
    </div>
    <div
      class="w-[478px] flex flex-col items-start justify-start pt-0 px-0 pb-[5px] box-border min-w-[478px] max-w-full text-23xl mq750:min-w-full mq1025:flex-1"
    >
      <div
        class="self-stretch flex flex-col items-start justify-start gap-[30px_0px] max-w-full"
      >
        <h1
          class="m-0 self-stretch h-[55px] relative text-inherit font-bold font-inherit inline-block box-border pr-2 mq450:text-6xl mq1025:text-15xl"
        >
          One Platform for All
        </h1>
        <div
          class="self-stretch flex flex-col items-start justify-start gap-[20px_0px] max-w-full text-13xl"
        >
          <div
            class="self-stretch flex flex-col items-start justify-start gap-[10px_0px] max-w-full"
          >
            <h2
              class="m-0 w-[458.3px] h-11 relative text-inherit font-normal font-inherit inline-block max-w-full box-border pr-5 mq450:text-lgi mq1025:text-7xl"
            >
              Enterprise AI for everyone
            </h2>
            <div
              class="self-stretch h-[84px] relative text-xl font-light inline-block mq450:text-base"
            >
              <p class="m-0">Data science, IT, and business teams can</p>
              <p class="m-0">work together seamlessly on one powerful</p>
              <p class="m-0">
                platform to deliver the full power of enterprise AI.
              </p>
            </div>
          </div>
          <div
            class="flex flex-row items-start justify-start py-0 pr-5 pl-0 box-border gap-[0px_12px] max-w-full text-xl text-grey"
          >
            <div class="relative uppercase mq450:text-base">
              Learn more about XENdeck Studio
            </div>
            <div
              class="h-3 flex flex-col items-start justify-start pt-3 px-0 pb-0 box-border"
            >
              <img
                class="w-[19px] h-[7.4px] relative"
                alt=""
                src="/aisoftware/arrow-1-3.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "BranchNode1",
  });
</script>
