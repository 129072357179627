<template>
    <div class="custom-select w-[340px] m-[10px] relative" :tabindex="tabindex" @blur="open = false" :class="{ active: open }">
        <div class="selected bg-[#23232E] text-[#B6B6B6] text-[16px] font-[400] rounded-[5px] uppercase leading-[1] px-[31px] py-[22px]"
             :class="{ open: open }" @click="open = !open">
            {{ selected }}
        </div>
        <div class="items !flex !flex-column !gap-[30px]" :class="{ selectHide: !open }" style="flex-direction: row">
            <div v-for="(option, i) of options" :class="{ active: option === selected }" :key="i" @click="
          selected = option;
          open = false;
          $emit('input', option);

        ">
                {{ option }}
            </div>
        </div>
        <svg @click="open = !open" class="cursor-pointer ham3 absolute top-[8px] right-0 w-[40px]" :class="{ active: open }" viewBox="0 0 100 100"
             fill="#ffffff">
            <path class="top"
                  d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"/>
            <path class="middle" d="m 70,50 h -40"/>
            <path class="bottom"
                  d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            selected: this.default
                ? this.default
                : this.options.length > 0
                    ? this.options[0]
                    : null,
            open: false,
        };
    },
    watch: {
        selected(val) {
            if (val) {
                window.scrollBy(0, document.evaluate(`//*[text()[contains(., '${val}')]][local-name() = "h1" or local-name() = "h2" or local-name() = "h3"][not(contains(@style,'display:none'))][last()]`, document.body).iterateNext()?.getBoundingClientRect()?.top - 150 || 0);
            }
        }
    },
    mounted() {
        this.$emit("input", this.selected);
    },
};
</script>

<style scoped>
.custom-select {
    text-align: left;
    outline: none;
    max-height: 47px;
    line-height: 47px;
}

.custom-select.active {
    width: 100%;
}

.custom-select .selected {
    border-radius: 6px;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
}

.custom-select .selected.open {
    border-radius: 0;
    width: 100%;
    @media (max-width: 750px) {
        width: 293px;
    }
}

/*.custom-select .selected:after {*/
/*    position: absolute;*/
/*    content: "";*/
/*    top: 22px;*/
/*    right: 1em;*/
/*    width: 0;*/
/*    height: 0;*/
/*    border: 5px solid transparent;*/
/*    border-color: #B6B6B6 transparent transparent transparent;*/
/*}*/

.custom-select .items {
    padding: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #B6B6B6;
    text-transform: uppercase;
    align-items: center;

    /*border-radius: 5px;*/
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    background-color: #23232e;
    left: 0;
    right: 0;
    z-index: 2;
    position: relative;
    margin-top: -1px;
    width: calc(100% - 13px);
    @media (max-width: 750px) {
        width: 280px;
        overflow: auto;
        max-height: 520px;
    }

    > div {
        box-sizing: border-box;
    }

    & > .active {
        background: rgb(221 221 221 / 10%);
        border-radius: 6px;
        padding: 10px 20px;
    }
}

.custom-select .items div {
    color: #B6B6B6;

    cursor: pointer;
    user-select: none;
    @media (max-width: 750px) {
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
}

.custom-select .items div:hover {
    background-color: #ad8225;
}

.selectHide {
    display: none !important;
}
</style>
