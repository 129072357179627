<template>
  <div
    class="self-stretch flex flex-row items-end justify-start gap-[0px_15px] max-w-full mq1025:flex-wrap"
  >
    <Frame3
      largestEnterpriseAIFootpr="Accelerate digital transformation"
      theXENdeckAIPlatformUnloc="Realize value quickly with a platform designed to deliver accelerated development — up to 26 times faster than alternatives. Accomplished through a common data and model ontology, the XENdeck "
      deploymentsPowering18Bill="AI Platform offers extreme reusability, full interoperability, and unmatched scalability."
      learnMoreAboutModelOps="Explore C3 AI applications"
      propHeight="unset"
    />
    <div
      class="flex-1 flex flex-row items-start justify-start py-0 pr-0 pl-9 box-border relative min-w-[485px] max-w-full mq750:min-w-full"
    >
      <div
        class="h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-8xs z-[1]"
      />
      <div
        class="flex-1 flex flex-row items-start justify-start bg-[url('/public/aisoftware/12012024-151600-1@2x.png')] bg-cover bg-no-repeat bg-[top] max-w-full"
      >
        <img
          class="h-[424px] w-[707px] relative object-cover hidden min-h-[424px] max-w-full"
          alt=""
          src="/aisoftware/12012024-151600-1@2x.png"
        />
        <img
          class="h-[427px] flex-1 relative max-w-full overflow-hidden z-[2]"
          loading="lazy"
          alt=""
          src="/aisoftware/group-403.svg"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";
  import Frame3 from "@/components/AiSoftWare/Frame3.vue";

  export default defineComponent({
    name: "ImageProcessorNode",
    components: { Frame3 },
  });
</script>
