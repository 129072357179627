<template>
    <header @mouseleave="() => !isMobile && close()"
            class="w-full flex flex-col items-center mq750:items-start  justify-center tracking-[normal] font-helvetica"
            style="position: sticky;
    top: 0;
    z-index: 99999999999;
    background: white;">
        <section

                class="flex-1 bg-white-back flex flex-row items-end py-[25px] pr-5 pl-[26px] box-border gap-[531px] max-w-full text-left text-base text-gray font-helvetica lg:gap-[531px] mq750:gap-0 mq750:w-full mq750:justify-between"
                style="    flex: 0 32px;
    height: 82px;
    align-items: center;">
            <div class="h-[85px] w-[1440px] relative bg-white-back hidden max-w-full"/>
            <img class="h-8 w-[133px] relative z-[1]" loading="eager" alt="" src="/group-321.svg"/>
            <div class="mq750:block hidden" style="transform: scaleY(0.5) scaleX(0.6);margin-right: -20px;">
                <svg @click="toggle" class="cursor-pointer ham4" :class="{active: active}" viewBox="0 0 100 100"
                     width="80" fill="#2B2B2C">
                    <path class="top"
                          d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"/>
                    <path class="middle" d="m 70,50 h -40"/>
                    <path class="bottom"
                          d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"/>
                </svg>
            </div>

            <!--            <div-->
            <!--                @mouseover="open"-->
            <!--                class="w-[580px] hidden flex-row items-center justify-between gap-[20px] max-w-full mq750:flex"-->
            <!--            >-->
            <!--                     -->
            <!--            </div>-->

            <div @mouseover="open"
                 class="w-[580px] flex flex-row items-center justify-between gap-[20px] max-w-full mq750:hidden">
                <div @mouseover="open" class="flex flex-col items-start justify-start py-0 pr-[3px] pl-0 box-border">
                     <router-link @click="close" to="/"
                                 class="text-[16px] relative font-light inline-block z-[1] text-[#2B2B2C] ">
                        About Us
                    </router-link>
                </div>
                <div @mouseover="open" class="flex flex-col items-start justify-start py-0 pr-[3px] pl-0 box-border">
                     <router-link @click="close"  to="/news"
                                 class="text-[16px] relative font-light inline-block z-[1] text-[#2B2B2C] ">
                        News
                    </router-link>
                </div>
                <div @mouseover="open" class="flex flex-col items-start justify-start py-0 pr-[3px] pl-0 box-border">
                     <router-link @click="close"  to="/ai-software" class="relative font-light whitespace-nowrap z-[1]">
                        AI Software
                    </router-link>
                </div>
                <div class="flex flex-col items-start justify-start py-0 pr-1.5 pl-0">
                    <img class="w-[21px] h-[21px] relative overflow-hidden shrink-0 z-[1]" loading="eager" alt=""
                         src="/searchoutline.svg"/>
                </div>
                <button @click="toggle"
                        class="cursor-pointer py-[8px] px-[9px] bg-[transparent] w-[125px] rounded-[1px] box-border flex flex-row items-center justify-center z-[1] border-[1px] border-solid border-midnightblue hover:bg-slateblue-200 hover:box-border hover:border-[1px] hover:border-solid hover:border-slateblue-100">
                     <router-link @click="close"  to="/contact" class="relative text-[18px] font-helvetica text-midnightblue text-left whitespace-nowrap">Contact
                        Us</router-link>
                </button>
            </div>
        </section>
        <template v-if="isMobile">
            <transition name="fade">
                <section v-if="active" @mouseover="open" style="position: absolute;
    top: 82px;"
                         :class="`h-[100vh] w-full mq750:flex hidden left-0 bg-white-back flex-row items-start justify-center pt-[25px] pb-[41px] pr-5 pl-[25px] box-border tracking-[normal]`">
                    <div class="h-[264px] w-[1440px] relative bg-white-back hidden max-w-full"/>
                    <section
                            class="w-[1235px] flex flex-row flex-wrap items-start justify-start gap-[62px_129.27px] min-h-[198px] max-w-full z-[1] lg:gap-[62px_129.27px] mq450:gap-[62px_129.27px] mq750:gap-[62px_129.27px]">
                        <div @mouseover="open"
                             class="w-[580px] flex flex-col items-start justify-between gap-[30px] max-w-full">
                            <div data-v-61dd7a3d="" class="absolute left-[0px] top-[4px]"
                                 style="left: auto;right: 0px;margin-top: 50px;"><img data-v-61dd7a3d=""
                                                                                      class="w-full h-full max-w-full overflow-hidden max-h-full z-[1] object-contain [transform:scalex(1.13)]"
                                                                                      loading="eager" alt=""
                                                                                      src="/line-1@2x.svg"
                                                                                      style="opacity: .75;width: 100%;">
                            </div>
                            <div class="flex flex-col items-start justify-start py-0 pr-1.5 pl-0">
                                <img class="w-[21px] h-[21px] relative overflow-hidden shrink-0 z-[1]" loading="eager"
                                     alt="" src="/searchoutline.svg"/>
                            </div>

                            <div v-if="backNavigation.length"
                                 @click="() => {currentNavigation = [...backNavigation]; backNavigation = []}"
                                 class="inline flex-col items-start justify-start py-0 pr-[3px] pl-0 box-border">
                                 <router-link to="#"
                                             class="text-[16px] relative font-light inline-block z-[1] text-[#2B2B2C] opacity-[.8]">
                                    <img style="transform: rotateY(180deg)" class="mr-[10px]"
                                         src="/arrow_navigation_mobile.svg"/> BACK
                                </router-link>
                            </div>

                            <div @mouseover="open" v-for="(item, index) in currentNavigation" :key="index" @click="() => {
                                if (!item.children) { close(); return; }
                                backNavigation = [...currentNavigation]; currentNavigation = [...item.children]
                            }" class="flex flex-col items-start justify-start py-0 pr-[3px] pl-0 box-border">
                                 <router-link :to="item.link || '#'"
                                             class="text-[16px] relative font-light inline-block z-[1] text-[#2B2B2C] opacity-[.8]">
                                    {{ item.name }} <img v-if="item.children" class="ml-[10px]"
                                                         src="/arrow_navigation_mobile.svg"/>

                                </router-link>
                            </div>
                        </div>
                    </section>
                </section>
            </transition>
        </template>
        <template v-if="!isMobile">
            <transition name="fade">
                <section v-if="active" style="position: absolute;
    top: 82px;"
                         :class="`w-full text-[#2B2B2C] mq750:hidden left-0 bg-white-back flex flex-row items-start justify-center pt-[25px] pb-[41px] pr-5 pl-[25px] box-border tracking-[normal]`">
                    <div class="h-[264px] w-[1440px] relative bg-white-back hidden max-w-full"/>
                    <section
                            class="w-[1235px] flex flex-row flex-wrap items-start justify-start gap-[62px_129.27px] min-h-[198px] max-w-full z-[1] lg:gap-[62px_129.27px] mq450:gap-[62px_129.27px] mq750:gap-[62px_129.27px]">
                        <GroupFrame2  @click="close" link="/news" generativeAI="Generative AI" generativeAIAnd="Generative AI and "
                                     theFutureOfBusiness="the Future of Business"/>

                        <GroupFrame1 @click="close" link="/what-is-enterprise-ai" whatIsEnterpriseAI="What is enterprise AI"
                                     corePrinciplesAndBestPrac="Core principles and best practices of Enterprise AI"/>

                        <GroupFrame1 @click="close" link="/it-for-enterprise-ai" whatIsEnterpriseAI="IT for Enterprise AI"
                                     corePrinciplesAndBestPrac="Business process re-engineering"/>

                        <GroupFrame1 @click="close" link="/enterprise-ai-buyers-guide" whatIsEnterpriseAI="Enterprise AI Buyer’s Guide"
                                     corePrinciplesAndBestPrac="Enterprise AI is a powerful new category of software that is a core enabler of digital
                            transformation"/>
<!--                        <GroupFrame2  @click="close" generativeAI="XENdeck live" generativeAIAnd="Series of livestream events"-->
<!--                                     theFutureOfBusiness="featuring XENdeck customers"/>-->
<!--                        <GroupFrame1 @click="close"  whatIsEnterpriseAI="Publications"-->
<!--                                     corePrinciplesAndBestPrac="Thought Leadership content and Product Data Sheets"/>-->
<!--                        <GroupFrame1 @click="close"  whatIsEnterpriseAI="Customer viewpoints"-->
<!--                                     corePrinciplesAndBestPrac="Videos of customers sharing their expirience of Enterprise AI"/>-->
<!--                        <GroupFrame1 @click="close"  whatIsEnterpriseAI="Blog"-->
<!--                                     corePrinciplesAndBestPrac="Insights and perspectives from XENdeck thought leaders"/>-->
<!--                        <GroupFrame1 @click="close"  whatIsEnterpriseAI="Developer portal"-->
<!--                                     corePrinciplesAndBestPrac="Review detailed technical documentation and training"/>-->
                    </section>
                </section>
            </transition>
        </template>
    </header>
</template>

<script>
import GroupFrame2 from "./HeaderGroupFrame2.vue";
import GroupFrame1 from "./HeaderGroupFrame1.vue";
import {getCurrentBreakpoint} from "@/utils/breakpoints.js";

export default {
    name: "HeaderComponent",
    components: {GroupFrame2, GroupFrame1},
    // computed: {
    //     isMobile() {
    //         return ['mq750', 'mq450'].includes(getCurrentBreakpoint())
    //     },
    //     // asd() {
    //     //     // return getCurrentBreakpoint();
    //     // }
    // },
    watch: {
        isMobile(val) {
            console.log(val)
        }
    },
    data() {
        return {
            isMobile: false,
            active: true,
            closing: false,
            backNavigation: [],
            navigation: [
                {
                    'name': 'About Us',
                    'link': '/',
                    children: [
                        {
                            'name': 'Generative AI',
                            'link': '/news',
                        },
                        {
                            'name': 'What is Enterprise AI',
                            'link': '/what-is-enterprise-ai',
                        },
                        {
                            'name': 'IT for Enterprise AI',
                            'link': '/it-for-enterprise-ai',
                        },
                        {
                            'name': 'Enterprise AI Buyer’s Guide',
                            'link': '/enterprise-ai-buyers-guide',
                        },
                    ]
                },
                {
                    'name': 'News',
                    'link': '/news',
                },
                {
                    'name': 'AI Software',
                    'link': '/ai-software',
                },
                {
                    'name': 'Contact Us',
                    'link': '/contact',
                },
                {
                    'name': 'Resources',
                    'link': '/',
                },

            ],
            currentNavigation: []
        }
    },
    methods: {
        open() {
            if (this.closing) {
                return;
            }
            this.active = true;
        },
        close() {
            this.closing = true;
            this.active = false;
            setTimeout(() => {
                this.closing = false;
            }, 500)
            this.currentNavigation = [...this.navigation];
            this.backNavigation = [];
        },
        toggle() {
            if (this.closing) {
                return;
            }
            this.active = !this.active
            this.closing = !this.active;
            setTimeout(() => {
                this.closing = false;
            }, 500)

            if (!this.active) {
                this.currentNavigation = [...this.navigation];
                this.backNavigation = [];
            }
        },
    },
    mounted() {
        this.currentNavigation = [...this.navigation];
        this.isMobile = ['mq450'].includes(getCurrentBreakpoint())
        window.addEventListener('resize', () => {
            this.isMobile = ['mq450'].includes(getCurrentBreakpoint())
        });
    }
}
</script>

<style scoped>
.fade-enter-active {
    @apply animate-[flip-down_0.5s_ease-in-out_1] flex;
}

.fade-leave-active {
    @apply animate-[flip-down_0.3s_ease-in-out_1_reverse] animate-reverse flex;
    /*@apply hidden;*/
}

.fade-enter-from {
    /*@apply animate-flip-down animate-reverse flex;*/
}

.fade-leave-to {
    /*@apply hidden;*/
}
</style>