<template>
    <div class="mq750:mt-[37px] self-stretch flex flex-row items-start justify-start pt-0 pb-[3px] pr-[5px] pl-0 box-border max-w-full shrink-0 text-left text-23xl text-white-back font-helvetica">
        <div class="flex-1 flex flex-col items-center justify-end mq750:p-0 pt-[42px] pb-[15px] pr-5 pl-[39px] box-border relative max-w-[101%] shrink-0">
            <h1 class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block max-w-full mq450:text-6xl mq1050:text-15xl leading-normal mq750:text-[24px]  mq750:mt-[32px]">
                {{ xENdeckApplications }} </h1>
            <div class="mq750:mt-[-32px]  w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] z-[1] flex items-center justify-center">
                <img class="w-full h-full max-w-full overflow-hidden max-h-full z-[1] object-contain absolute left-[0px] top-[4px] [transform:scalex(1.13)]"
                     loading="eager" alt="" src="/line-1@2x.svg"/>
            </div>
        </div>
    </div>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "TurnkeyFrames",
    props: {
        xENdeckApplications: {type: String},
    },
});
</script>
