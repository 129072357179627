import { createApp } from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from 'vue-router'
import {routes} from "@/routes";
// import "./index.scss"
// import './assets/tailwind.css'


const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

import Unicon from 'vue-unicons'
import { uniBars, uniArrowRight } from 'vue-unicons/dist/icons'


Unicon.add([uniBars, uniArrowRight])

import vClickOutside from "click-outside-vue3"

router.beforeEach((toRoute, fromRoute, next) => {
    const documentTitle =
        toRoute?.meta && toRoute?.meta?.title
            ? toRoute?.meta?.title
            : "Xendeck";
    window.document.title = documentTitle;
    if (toRoute?.meta?.description) {
        addMetaTag(toRoute?.meta?.description);
    }
    next();
});

const addMetaTag = (value) => {
    let element = document.querySelector(`meta[name='description']`);

    if (element) {
        element.setAttribute("content", value);
    } else {
        element = `<meta name="description" content="${value}" />`;
        document.head.insertAdjacentHTML("beforeend", element);
    }
};

// import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
})

const app = createApp(App);

app.use(vuetify)

app.use(router)

app.use(Unicon)

app.use(vClickOutside)

app.mount('#app')
