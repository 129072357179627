<template>
  <div
    class="self-stretch grid flex-row items-start justify-start gap-[0px_18px] max-w-full grid-cols-[repeat(3,_minmax(281px,_1fr))] text-left text-xl text-white-back font-helvetica mq750:grid-cols-[minmax(281px,_1fr)] mq1025:justify-center mq1025:grid-cols-[repeat(2,_minmax(281px,_487px))]"
  >
    <div
      class="h-[406px] flex flex-col items-center justify-start py-[30px] pr-5 pl-[21px] box-border relative gap-[20px] max-w-full z-[1]"
    >
      <div
        class="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-center justify-center z-[0]"
      >
        <img
          class="w-full h-full max-w-full overflow-hidden max-h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.037)]"
          alt=""
          src="/aisoftware/group-407-1.svg"
        />
      </div>
      <div
        class="w-[204px] flex flex-row items-start justify-start py-0 pr-0 pl-px box-border max-w-[54%]"
      >
        <div
          class="h-[34px] flex-1 flex flex-col items-start justify-start gap-[8px_0px]"
        >
          <div class="flex flex-row items-start justify-start py-0 px-7">
            <b
              class="h-[26px] relative uppercase inline-block z-[1] mq450:text-base"
              >Data Science</b
            >
          </div>
          <img
            class="self-stretch h-px relative max-w-full overflow-hidden shrink-0 z-[1]"
            loading="lazy"
            alt=""
            src="/aisoftware/line-51.svg"
          />
        </div>
      </div>
      <div
        class="flex flex-row items-start justify-start py-0 pr-[34px] pl-[33px] box-border max-w-[54%]"
      >
        <div
          class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
        >
          Integration
        </div>
      </div>
      <div
        class="flex flex-row items-start justify-start py-0 pr-8 pl-[31px] box-border max-w-[54%]"
      >
        <div
          class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
        >
          preparation
        </div>
      </div>
      <div
        class="h-7 relative uppercase font-light inline-block max-w-[54%] z-[1] mq450:text-base"
      >
        lineage & veracity
      </div>
      <div
        class="flex flex-row items-start justify-start py-0 pr-7 pl-[28.5px] box-border max-w-[54%]"
      >
        <div
          class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
        >
          visualization
        </div>
      </div>
      <div
        class="flex flex-row items-start justify-start py-0 pr-[65px] pl-16 box-border max-w-[53%]"
      >
        <div
          class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
        >
          graph
        </div>
      </div>
      <div
        class="flex flex-row items-start justify-start py-0 px-[29px] box-border max-w-[53%]"
      >
        <div
          class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
        >
          governance
        </div>
      </div>
    </div>
    <div
      class="h-[406px] flex flex-col items-start justify-start py-[30px] px-[77px] box-border relative gap-[20px] max-w-full z-[1]"
    >
      <div
        class="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-center justify-center z-[0]"
      >
        <img
          class="w-full h-full max-w-full overflow-hidden max-h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.037)]"
          alt=""
          src="/aisoftware/group-409.svg"
        />
      </div>
      <div
        class="self-stretch flex flex-row items-start justify-start py-0 px-[9px]"
      >
        <div
          class="h-9 flex-1 flex flex-col items-start justify-start gap-[8px_0px]"
        >
          <div
            class="self-stretch flex flex-row items-start justify-center py-0 px-5"
          >
            <b class="h-7 relative uppercase inline-block z-[1] mq450:text-base"
              >AI</b
            >
          </div>
          <img
            class="self-stretch h-px relative max-w-full overflow-hidden shrink-0 z-[1]"
            alt=""
            src="/aisoftware/line-51.svg"
          />
        </div>
      </div>
      <div
        class="self-stretch flex flex-row items-start justify-start py-0 pr-[31px] pl-[31.5px]"
      >
        <div
          class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
        >
          Feature store
        </div>
      </div>
      <div
        class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
      >
        model development
      </div>
      <div
        class="self-stretch flex flex-row items-start justify-start py-0 pr-[50px] pl-[49px]"
      >
        <div
          class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
        >
          model ops
        </div>
      </div>
      <div
        class="self-stretch flex flex-row items-start justify-start py-0 px-[31px]"
      >
        <div
          class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
        >
          responsible Ai
        </div>
      </div>
    </div>
    <div
      class="h-[406px] flex flex-col items-start justify-start py-[30px] pr-[30px] pl-[31px] box-border relative gap-[20px] max-w-full z-[1]"
    >
      <div
        class="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] flex items-center justify-center z-[0]"
      >
        <img
          class="w-full h-full max-w-full overflow-hidden max-h-full object-contain absolute left-[0px] top-[4px] [transform:scale(1.037)]"
          alt=""
          src="/aisoftware/group-409.svg"
        />
      </div>
      <div
        class="self-stretch h-9 flex flex-col items-start justify-start gap-[8px_0px]"
      >
        <div
          class="self-stretch flex flex-row items-start justify-start py-0 px-[11px]"
        >
          <b class="h-7 relative uppercase inline-block z-[1] mq450:text-base"
            >Application Development</b
          >
        </div>
        <img
          class="self-stretch h-px relative max-w-full overflow-hidden shrink-0 z-[1]"
          alt=""
          src="/aisoftware/line-5-21.svg"
        />
      </div>
      <div
        class="self-stretch flex flex-row items-start justify-center py-0 pr-0 pl-px"
      >
        <div
          class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
        >
          data models
        </div>
      </div>
      <div
        class="self-stretch flex flex-row items-start justify-start py-0 pr-[71px] pl-[72px]"
      >
        <div
          class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
        >
          ui development
        </div>
      </div>
      <div class="self-stretch flex flex-row items-start justify-center">
        <div
          class="h-7 relative uppercase font-light inline-block z-[1] mq450:text-base"
        >
          devops
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "Frame2C",
  });
</script>
