import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);

export const getBreakpointValue = (value) => {
    const key = Object.keys(fullConfig.theme.screens[value])[0]
    return parseInt(fullConfig.theme.screens[value][key].replace(/\D/g, ""))
}

export const getCurrentBreakpoint = () => {
// console.log( Object.keys(fullConfig.theme.screens))
    let currentBreakpoint;
    let biggestBreakpointValue = 0;
    for (const breakpoint of Object.keys(fullConfig.theme.screens)) {
        const breakpointValue = getBreakpointValue(breakpoint);
        console.log(breakpoint, breakpointValue, biggestBreakpointValue, breakpointValue > biggestBreakpointValue)
        if (
            breakpointValue > biggestBreakpointValue &&
            window.innerWidth > breakpointValue
        ) {
            biggestBreakpointValue = breakpointValue;
            currentBreakpoint = breakpoint;

        }
    }
    console.log(biggestBreakpointValue)
    console.log(currentBreakpoint || 'mq450');
    return currentBreakpoint || 'mq450';
};
