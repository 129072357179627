<template>
    <div class="w-full mq750:mt-auto">
        <div class="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full mt-[-59px] text-left text-base text-silver font-helvetica mt-[120px] mq750:hidden">
            <div class="w-full flex flex-row flex-wrap items-start justify-evenly gap-[0px_5px] max-w-full">
                <router-link v-for="(item, index) in items" :key="index" :to="item.link || '#'" class="text-[#B6B6B6] px-[20px] border-b border-[#B6B6B6] border-solid uppercase">{{ item.name }}</router-link>
                <!--      <GroupComponent1 data="Data" line18="/line-18.svg" />-->
                <!--      <button-->
                <!--        class="cursor-pointer [border:none] pt-[21px] px-[15px] pb-4 bg-d2d3c rounded-t-8xs rounded-b-none flex flex-col items-start justify-start gap-[5px] z-[2]"-->
                <!--      >-->
                <!--        <div-->
                <!--          class="w-[67px] h-16 relative rounded-t-8xs rounded-b-none bg-d2d3c hidden"-->
                <!--        />-->
                <!--        <div class="flex flex-row items-start justify-start py-0 px-2.5">-->
                <!--          <div-->
                <!--            class="h-[22px] relative text-base uppercase font-light font-helvetica text-silver text-left inline-block z-[1]"-->
                <!--          >-->
                <!--            Ai-->
                <!--          </div>-->
                <!--        </div>-->
                <!--        <img-->
                <!--          class="w-9 h-px relative z-[1]"-->
                <!--          alt=""-->
                <!--          src="/aisoftware/line-18-1.svg"-->
                <!--        /></button-->
                <!--      ><GroupFrame5-->
                <!--        applicationDevelopment="application development"-->
                <!--        line19="/line-19.svg"-->
                <!--      /><GroupFrame5-->
                <!--        applicationDevelopment="platform services"-->
                <!--        line19="/line-19-11.svg"-->
                <!--        propFlex="unset"-->
                <!--        propMinWidth="unset"-->
                <!--        propWidth="244px"-->
                <!--        propWidth1="244px"-->
                <!--      /><GroupFrame5-->
                <!--        applicationDevelopment="xendeck ai studio"-->
                <!--        line19="/line-19-2.svg"-->
                <!--        propFlex="unset"-->
                <!--        propMinWidth="unset"-->
                <!--        propWidth="238px"-->
                <!--        propWidth1="238px"-->
                <!--      /><GroupComponent1-->
                <!--        data="xendeck ex machina"-->
                <!--        line18="/line-19-3.svg"-->
                <!--        propWidth="257px"-->
                <!--        propWidth1="257px"-->
                <!--        propHeight="unset"-->
                <!--        propDisplay="inline-block"-->
                <!--      />-->
            </div>
        </div>
        <div class="mq750:block hidden mq750:mb-[100px] relative">
            <div class="w-auto mx-[15px] cursor-pointer h-[64px] bg-[#2D2D3C] uppercase flex items-center justify-center text-center text-[#B6B6B6]">
                xendeck platform menu
            </div>
        </div>
    </div>
</template>
<script>
import {defineComponent} from "vue";
// import GroupComponent1 from "@/components/AiSoftWare/GroupComponent1.vue";
// import GroupFrame5 from "@/components/AiSoftWare/GroupFrame5.vue";

export default defineComponent({
    name: "MergingNodes",
    // components: {GroupComponent1, GroupFrame5},
    data() {
        return {
            active: true,
            items: [
                {
                    name: 'DATA'
                },
                {
                    name: 'AI'
                },
                {
                    name: 'Application development'
                },
                {
                    name: 'Platform services'
                },
                {
                    name: 'Xendeck Ai Studio'
                },
                {
                    name: 'Xendeck Ex Machina'
                }

            ],
            backNavigation: [],
            navigation: [
                {
                    'name': 'About Us',
                    link: '/',
                    children: [
                        {
                            'name': 'Generative AI',
                        },
                        {
                            'name': 'What is Enterprise AI',
                        },
                        {
                            'name': 'Machine Learning',
                        },
                        {
                            'name': 'XENdeck Live',
                        },
                        {
                            'name': 'Publications',
                        },
                        {
                            'name': 'Customer Viewpoints',
                        },
                        {
                            'name': 'Blog',
                        },
                        {
                            'name': 'Developer portal',
                        },

                    ]
                },
                {
                    'name': 'News',
                    'link': '/news',
                },
                {
                    'name': 'AI Software',
                    'link': '/ai-software',
                },
                {
                    'name': 'Contact Us',
                    'link': '/contact',
                },
                {
                    'name': 'Resources',
                    'link': '/',
                },

            ],
            currentNavigation: []
        }
    },
    methods: {
        open() {
            this.active = true;
        },
        close() {
            this.active = false;
        },
        toggle() {
            this.active = !this.active
        },
    }
});
</script>
