<template>
  <div
      class="w-full h-full bg-d2d3c overflow-hidden flex flex-col items-center justify-start gap-[4px] tracking-[normal] text-left text-xl text-white-back font-helvetica mq450:h-auto"
  >
    <div class="relative mt-[65px] mq750:mt-[37px] self-stretch flex flex-row items-start justify-start pt-0 pb-[3px] pr-[5px] pl-0 box-border max-w-full shrink-0 text-left text-23xl text-white-back font-helvetica">
      <img class="w-full h-full max-w-full overflow-hidden max-h-full z-[1] object-contain left-[0px] top-[4px] [transform:scalex(1.13)]"
           loading="eager" alt="" src="/line-1@2x.svg"/>
      <h1 class="absolute top-[80px] mq750:top-[30px] mq450:top-[0] mq450:mt-[19px] m-0 w-full text-center h-[55px] text-inherit font-bold font-inherit inline-block max-w-full z-[1] mq450:text-[24px] mq1050:text-15xl">Contact Us</h1>
    </div>

    <div class="advantages-block">
      <h2 class="advantages-block__title">How can we help you?</h2>
      <div class="advantages-block-items">
        <div class="advantages-block-item">
          <div class="advantages-block-item__title">Sales</div>
          <img class="advantages-block-item__img" src="/handshake.svg" alt="handshake" />
          <div class="advantages-block-item__desc">Learn how our industry-leading
            XENdeck AI software products can help your organization.</div>
            <a  href="mailto:support@xendeck.ai" class="advantages-block-item__link text-white mt-2">Contact Sales</a>
        </div>
        <div class="advantages-block-item">
          <div class="advantages-block-item__title">Investor Relations</div>
          <img class="advantages-block-item__img" src="/peoples.svg" alt="peoples" />
          <div class="advantages-block-item__desc">Contact us at support@xendeck.ai or invest@xendeck.ai to learn more about investing at XENdeck.</div>
          <a href="mailto:support@xendeck.ai" class="advantages-block-item__link text-white mt-[40px] ">Go to Investor Relations</a>
        </div>
        <div class="advantages-block-item">
          <div class="advantages-block-item__title">Other Inquiries</div>
          <img class="advantages-block-item__img" src="/idea.svg" alt="idea" />
          <div class="advantages-block-item__desc">For all other questions, please contact us here.</div>
          <a  href="mailto:support@xendeck.ai" class="advantages-block-item__link text-white mt-[40px] ">request information</a>
        </div>
      </div>
    </div>

    <div class="get-in-touch">
      <div class="get-in-touch__title">Get in touch</div>
      <div class="get-in-touch-item">
        <div class="get-in-touch-item__subtitle">Support</div>
        <div class="get-in-touch-item__desc">If you are a customer looking for support:
          Contact Customer Support</div>
      </div>
      <div class="get-in-touch-item">
        <div class="get-in-touch-item__subtitle">Press</div>
        <div class="get-in-touch-item__desc">If you would like to contact our media team:
          Contact Media Team</div>
      </div>
    </div>

    <div class="flex flex-row items-start justify-start relative max-w-full">
      <img
          class="h-[79.5px] w-[1161px] absolute my-0 mx-[!important] right-[-206px] bottom-[-56.5px] object-contain mq750:w-[450px]"
          loading="eager"
          alt=""
          src="/line-19-1@2x.png"
      />
    </div>
    <div
        class="flex flex-row items-start justify-start pt-5 px-5 pb-[25px] box-border max-w-full text-23xl mq750:pb-[15px]"
    >
      <h2
          class="leading-1 m-0 relative text-[32px] mq750:text-[24px] font-bold font-inherit inline-block mq750:h-[25px]"
      >
        XENdeck offices
      </h2>
    </div>


      <div class="mb-[110px] mq750:mb-[0]">
        <tabs
            v-model="selectedTab"
        >
          <tab
              class="tab-address"
              v-for="(tab, i) in tabs"
              :key="`t${i}`"
              :val="tab"
              :label="tab"
              :indicator="true"
          />
        </tabs>
        <tab-panels
            v-model="selectedTab"
            :animate="true"
        >
          <tab-panel
              v-for="(tab, i) in tabs"
              :key="`tp${i}`"
              :val="tab"
          >
            <div class="tab-item" v-if="tab === 'United States'">
              <div class="tab-item-address">
                <div class="tab-item-address__city">Redwood City, California</div>
                <div class="tab-item-address__adr">Headquarters
                  1400 Seaport Boulevard
                  63650-503-2200</div>
              </div>
              <div class="tab-item-address">
                <div class="tab-item-address__city">Washington, D.C.</div>
                <div class="tab-item-address__adr">1775 Tysons Boulevard,
                  5th FloorSuite 11W102, 11W103, 11CTysons, VA 22102</div>
              </div>
              <div class="tab-item-address">
                <div class="tab-item-address__city">Chicago, Illinois</div>
                <div class="tab-item-address__adr">1 South Dearborn Street Tenant LLC
                  #21-137Chicago, IL 60603</div>
              </div>
              <div class="tab-item-address">
                <div class="tab-item-address__city">Houston, Texas</div>
                <div class="tab-item-address__adr">2700 Post Oak Blvd.
                  #07W109
                  Houston, TX 77056</div>
              </div>
              <div class="tab-item-address">
                <div class="tab-item-address__city">Atlanta, Georgia</div>
                <div class="tab-item-address__adr">1175 Peachtree St
                  #1000Atlanta,
                  GA 30361</div>
              </div>
              <div class="tab-item-address">
                <div class="tab-item-address__city">New York City, New York</div>
                <div class="tab-item-address__adr">430 Park Avenue
                  #5-101
                  New York City, NY 10022</div>
              </div>
            </div>
            <div class="tab-item" v-if="tab === 'International'">
              <div class="tab-item-address">
                <div class="tab-item-address__city"></div>
                <div class="tab-item-address__adr"></div>
              </div>
              <div class="tab-item-address">
                <div class="tab-item-address__city"></div>
                <div class="tab-item-address__adr"></div>
              </div>
              <div class="tab-item-address">
                <div class="tab-item-address__city"></div>
                <div class="tab-item-address__adr"></div>
              </div>
              <div class="tab-item-address">
                <div class="tab-item-address__city"></div>
                <div class="tab-item-address__adr"></div>
              </div>
              <div class="tab-item-address">
                <div class="tab-item-address__city"></div>
                <div class="tab-item-address__adr"></div>
              </div>
              <div class="tab-item-address">
                <div class="tab-item-address__city"></div>
                <div class="tab-item-address__adr"></div>
              </div>
            </div>
            <div class="tab-item" v-if="tab === 'British Virgin Islands'">
              <div class="tab-item-address">
                <div class="tab-item-address__city">Tortola</div>
                <div class="tab-item-address__adr">Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110</div>
              </div>
            </div>
          </tab-panel>
        </tab-panels>
    </div>

    <div class="self-stretch bg-[#2d2d3c] flex flex-row items-start justify-start pt-0 pb-1.5 pr-[5px] pl-0 box-border max-w-full">
      <div class="h-28 flex-1 relative shrink-0 flex items-center justify-center">
        <img class="h-full flex-1 max-w-[101%] left-positioning overflow-hidden shrink-0 object-contain absolute left-[0px] top-[4px] w-full [transform:scale(1.125)]"
             loading="eager" alt="" src="/line-2-1@2x.svg" style="
    width: 1440px;
    transform: scalex(2)"/>
      </div>
    </div>
    <main class="mx-auto self-stretch flex flex-row items-start justify-start pt-0 px-0 box-border max-w-full lg:box-border ">
      <footer style="padding: 15px;width:100%;flex-wrap: nowrap;"
              class="mq750:flex flex-1 mq750:gap-0 mq750:w-full hidden flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
        <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"></div>
        <div class="w-[226px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
          <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]" style="        height: auto;
    width: 100px;
    position: absolute;"
               loading="eager" alt="" src="/group-32@2x.png">
          <!-- <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>
        --></div>
        <!--                &lt;div class=&quot;flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
        <!--                    --><!--                &lt;/div&gt;-->
        <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
          <div class="flex flex-row items-start justify-start gap-[12px] flex-wrap" style="
    width: 100%;
">
            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
            <!--            <div class="relative z-[1]" style="flex-grow: 1;-->
<!--  width: 33%;">About Us</div>-->
            <a href="/news" class="relative z-[1] text-white">News</a>
             <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>
            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
          </div>
        </div>
        <!--                &lt;div class=&quot;hidden mq750:flex flex-col items-start justify-start pt-[5px] px-0 pb-0&quot;&gt;-->
        <!--                    &lt;div class=&quot;relative z-[1]&quot;&gt;Contact Us&lt;/div&gt;-->
        <!--                    &lt;div class=&quot;w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full&quot;&gt;-->
        <!--                        &lt;div class=&quot;self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap&quot;&gt;-->
        <!--                            &lt;div class=&quot;flex flex-col items-start justify-start gap-[12px]&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;About Us&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;News&lt;/div&gt;-->
        <!--                            &lt;/div&gt;-->
        <!--                            &lt;div class=&quot;flex-[0.8557] flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;AI Software&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Resourses&lt;/div&gt;-->
        <!--                            &lt;/div&gt;-->
        <!--                            &lt;div class=&quot;flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]&quot;&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Privacy Policy&lt;/div&gt;-->
        <!--                                &lt;div class=&quot;relative z-[1]&quot;&gt;Terms of Use&lt;/div&gt;-->
        <!--                            &lt;/div&gt;--><!--                        &lt;/div&gt;-->
        <!--                    &lt;/div&gt;--><!--                &lt;/div&gt;--></footer>
      <footer style="    padding-left: 0;
    width: 1290px;"
              class="flex-1 mq750:hidden flex flex-row items-start justify-start pt-[19px] px-[100px] pb-[3px] box-border relative gap-[89px] max-w-full text-left text-base text-white-back font-helvetica lg:flex-wrap lg:gap-[89px] lg:pl-[50px] lg:pr-[50px] lg:box-border mq750:gap-[89px] mq750:pl-[25px] mq750:pr-[25px] mq750:box-border">
        <div class="h-full w-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px] bg-d2d3c"/>
        <div class="w-[226px] ml-[100px] flex flex-col items-start justify-start py-0 pr-[93px] pl-0 box-border gap-[37px] text-[10px]">
          <img class="self-stretch h-8 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]"
               loading="eager" alt="" src="/group-32@2x.png"/>
          <!-- <div class="relative z-[1] w-[500px] mq450:w-[200px]">©️2024 XENdeck, Charles Court. 1st Floor, 189 Main Street, P.O. Box 4406 Tortola VG1110, British Virgin Islands</div>
        --></div>
        <div class="w-[447px] flex flex-col items-start justify-start pt-[5px] px-0 pb-0 box-border max-w-full">
                    <div class="self-stretch gap-[40px] flex flex-row items-start justify-between mq450:flex-wrap">
                        <div class="flex flex-col items-start justify-start px-0 pb-0">
                            <a href="/contact" class="relative z-[1] text-white">Contact Us</a>
                        </div>
                        <div class="flex flex-col items-start justify-start gap-[12px]">
                            <a href="/news" class="relative z-[1] text-white">News</a>
                        </div>
                        <div class="flex flex-col items-start justify-start py-0 pr-3.5 pl-0 box-border gap-[12px] min-w-[65px] mq450:flex-1">
                            <a href="/ai-software" class="relative z-[1] text-white">AI Software</a>
<!--                            <div class="relative z-[1]">Resourses</div>-->
                        </div>
                        <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[65px]">
                            <a href="/privacy-policy" class="relative z-[1] text-white">Privacy Policy</a>
                            <a href="/terms-of-use" class="relative z-[1] text-white">Terms of Use</a>
                        </div>
                    </div>
                </div>
      </footer>
    </main>
  </div>
</template>

<script>
import {defineComponent, reactive, toRefs} from "vue";
import { Tabs, Tab, TabPanels, TabPanel } from 'vue3-tabs';

// const tabs = ['United States', 'International'];
const tabs = ['British Virgin Islands'];

export default defineComponent({
  name: "AiAppInbox",
  components: { Tabs, Tab, TabPanels, TabPanel },
  setup() {
    const state = reactive({
      selectedTab: tabs[0]
    });
    return {
      tabs,
      ...toRefs(state)
    };
  }
});
</script>

<style lang="scss" scoped>
.tab-item {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 950px) {
    flex-direction: column;
    margin: 15px 0;
  }

  &-address {
    flex-basis: 33.333333%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: 950px) {
      align-items: start;
    }
    &__city {
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
    }

    &__adr {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      width: 214px;
      margin-bottom: 54px;

      @media (max-width: 750px) {
        margin-bottom: 20px;
      }
    }
  }
}

.tabs {
  justify-content: center;
}

.tab-panels {
  margin: 0 100px;

  @media (max-width: 950px) {
    margin: 0 15px;
  }
}

.tab-address {
  padding: 0 5px !important;
  color: #A2A2A2;
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 750px) {
    padding: 0 !important;
    border-width: 0;
    border-bottom-width: 2px;
    border-style: solid;
    border-color: #A2A2A2;
    font-size: 12px;
    line-height: 1px;
  }

  div {
    padding: 0;
  }
}

.advantages-block {
  width: 100%;

  &__title {
    margin: 35px 100px 42px;
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;

    @media (max-width: 750px) {
      margin: 20px 15px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }
  }

  &-items {
    display: flex;
    justify-content: center;
    margin: 0 100px 90px;

    @media (max-width: 750px) {
      margin: 0 15px;
      flex-direction: column;
    }
  }

  &-item {
    width: 100%;
    border: 1px solid #ffffff;
    margin-right: 20px;
    padding: 30px 20px 20px;
    position: relative;

    @media (max-width: 750px) {
      margin-right: 0;
      margin-bottom: 30px;
      width: auto;
      padding: 30px 20px;
    }

    &__title {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;

      @media (max-width: 750px) {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
      }
    }

    &__img {
      position: absolute;
      right: 35px;
      top: 20px;
    }

    &__desc {
      margin-top: 15px;
      width: 245px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

      @media (max-width: 750px) {
        width: 220px;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
      }
    }

    &__link {
      margin-top: 95px;
      font-size: 15px;
      font-weight: 400;
      line-height: 25px;
      text-transform: uppercase;

      @media (max-width: 750px) {
        margin-top: 60px;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
}

.active {
  border-width: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #FFFFFF !important;
  color: #FFFFFF !important;
}

.get-in-touch {
  display: flex;
  margin-bottom: 100px;
  width: 100%;

  @media (max-width: 750px) {
    flex-direction: column;
    margin-bottom: 15px;
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    margin-left: 100px;
    margin-right: 20px;
    flex-basis: 392px;

    @media (max-width: 750px) {
      margin-left: 15px;
      margin-right: 0;
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 23px;
      flex-basis: auto;
    }
  }

  &-item {
    margin-right: 22px;
    flex-basis: 392px;

    @media (max-width: 750px) {
      margin-left: 15px;
      margin-right: 0;
      font-size: 18px;
      line-height: 23px;
      flex-basis: auto;
    }
    &__subtitle {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 10px;
      line-height: 26px;

      @media (max-width: 750px) {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 21px;
      }
    }

    &__desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;

      @media (max-width: 750px) {
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 15px;
      }
    }
  }
}
</style>