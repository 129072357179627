<template>
  <section
    class="absolute top-[7437px] left-[100px] w-[1240px] flex flex-col items-start justify-start gap-[76px_0px] max-w-full text-left text-23xl text-white-back font-helvetica"
  >
    <div
      class="self-stretch flex flex-row items-start justify-center py-0 pr-5 pl-[21px]"
    >
      <h1
        class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1025:text-15xl"
      >
        Take the next step
      </h1>
    </div>
    <div
      class="self-stretch flex flex-row flex-wrap items-start justify-center gap-[0px_50px] max-w-full text-lg"
    >
      <ShadowNodes
        contactSales="Contact Sales"
        learnHowOurIndustryLeadin="Learn how our industry-leading XENdeck AI software products can help your organization."
      />
      <div
        class="flex-1 flex flex-col items-start justify-start gap-[15px_0px] min-w-[285px] max-w-full text-base"
      >
          <a class="w-full" href="mailto:support@xendeck.ai">
        <button
          class="cursor-pointer py-[22px] px-5 bg-[transparent] self-stretch rounded-12xs box-border flex flex-row items-start justify-start gap-[0px_15px] max-w-full border-[1px] border-solid border-white-back"
        >
          <div
            class="h-[67px] w-[380px] relative rounded-12xs box-border hidden max-w-full border-[1px] border-solid border-white-back"
          />
          <b
            class="relative text-lg uppercase font-helvetica text-white-back text-left z-[1]"
            >Go to Investor Relations</b
          >
          <div
            class="h-3 flex flex-col items-start justify-start pt-3 px-0 pb-0 box-border"
          >
            <img
              class="w-[16.3px] h-[8.8px] relative z-[1]"
              alt=""
              src="/aisoftware/arrow-1-1.svg"
            />
          </div>
        </button>
          </a>
        <div class="self-stretch h-12 relative font-light inline-block">
          <p class="[margin-block-start:0] [margin-block-end:4px]">
            Contact us at support@xendeck.ai to learn more
          </p>
          <p class="m-0">about investing at XENdeck.</p>
        </div>
      </div>
      <ShadowNodes
        contactSales="request information"
        learnHowOurIndustryLeadin="For all other questions, please contact us here."
        propGap="0px 15px"
      />
    </div>
  </section>
</template>
<script>
  import { defineComponent } from "vue";
  import ShadowNodes from "@/components/AiSoftWare/ShadowNodes.vue";

  export default defineComponent({
    name: "StrokeNodes",
    components: { ShadowNodes },
  });
</script>
