<template>
    <div class="w-auto news-select-wrapper px-[250px] mq750:px-[15px] mq450:mb-[50px] mq750:w-full mq750:flex mq750:justify-center sticky top-[80px]">
        <NewsSelect :options="selectOptions" :default="'Generative AI for Business'" class="select"/>
    </div>
    <div class="mt-[50px] w-full h-full bg-d2d3c overflow-hidden flex flex-col items-center justify-start gap-[4px] tracking-[normal] text-left text-xl text-white-back font-helvetica mq450:h-auto">
        <div class="w-full">
            <div class="px-[250px] mq750:px-[15px]">
                <h2 class="news-page__subtitle">
                    Generative AI for Business </h2>
                <p class="news-page__text">
                    The AI revolution is in full force, with businesses everywhere looking to Generative AI to transform
                    their organizations. This next step in how humans interact with computers promises to upend
                    virtually every industry on the planet. </p>
                <h2 class="news-page__subtitle">
                    What is Generative AI? </h2>
                <div class="flex justify-between mb-[20px] mq750:flex-col">
                    <p class="news-page__text w-[50%] mq750:w-full">
                        The history of technology is marked by before-and-after moments – advances that spurred new
                        industries, enabled the transformation of others, changed how people interact with technology,
                        and reshaped society.
                        <br/><br/>
                        The public rollout of the Netscape web browser in 1994 was one such moment, the introduction of
                        the Apple iPhone just 16 years ago another. We’re now living through an equally – if not more –
                        significant moment
                        with the emergence of generative AI.
                        While the consumer applications of generative AI have dazzled us all, with ChatGPT and other
                        services churning out remarkably polished essays and high-quality images
                        in a flash, the implications for the enterprise
                        are even greater.
                        <br/><br/>
                        This is why business leaders across industries are examining where and how to incorporate
                        generative AI throughout their organizations. While many large enterprises, such as Koch
                        Industries and the U.S. Air Force, have long been digitally transforming with enterprise AI
                        application software, the emergence
                        of generative AI promises to accelerate such efforts
                        at a rapid pace. The opportunities are enormous;
                        the risks of watching from the sidelines just too great. </p>
                    <img class="news-page__img w-[50%] ml-[33px] mq750:w-full mq750:ml-[0]" src="/image-face.jpeg"
                         alt=""/>
                </div>


                <h2 class="news-page__subtitle">
                    What Is Unique About Generative AI? </h2>
                <p class="news-page__text">
                    As the name suggests, generative AI enables computers to generate content based on a set of AI and
                    Machine Learning (ML) algorithms applied to vast data sources. The user experience, akin to having a
                    conversation,
                    marks a fundamental shift in the human-computer interaction model. Generative AI enables software to
                    “learn”
                    the fundamental patterns of a corpus of images, text, and audio files, and then rapidly produce
                    comprehensive, thorough results (although at this point inaccuracies aren’t uncommon.) These AI
                    models use a variety of techniques, such as transformer models, generative adversarial networks
                    (GANs), and variational auto-encoders.
                    <br/><br/>
                    A user types in a prompt, the software spits out what you asked for. Beyond ChatGPT, there’s now
                    Stability.ai, Google’s Bard, and Microsoft’s generative-AI equipped Bing, with the tagline, “Ask
                    real questions. Get complete answers.” To generate content—churning out an essay about Plato, for
                    instance, or about the history
                    of AI technology—generative AI services rely entirely on publicly available data. And that’s what
                    stands between current generative AI offerings, and what’s needed to serve the enterprise.
                    <br/><br/>
                    Access to the appropriate enterprise data is the essential ingredient for generative AI for
                    business. A financial services company, for example, doesn’t need a generative AI system that learns
                    only from public data. It needs a system built for its domain (read The Significance of Domain
                    Models) that generates comprehensive insights from its proprietary data. That might include deposit
                    trends, information about its loans, and so on. Ditto for a manufacturing company,
                    or healthcare institution. Generative AI for the enterprises also incorporates public data via Large
                    Language Models (LLMs). A financial services firm would want real-time interest rate data in the
                    mix, for instance.
                    But it’s the domain-specific, enterprise data that promises to make generative AI so transformative
                    for businesses.
                    <br/><br/>

                    The difference doesn’t stop there. Generative AI makes it possible for far more people across an
                    organization to take advantage of the predictive insights generated by the underlying enterprise AI
                    applications. Put simply: Generative AI available to the public creates what you ask for based on
                    current (meaning past) data available across the web. Generative AI in the enterprise, by contrast,
                    can produce vital insights based on what’s going to happen for a specific business, such as telling
                    you which parts of your manufacturing facility will need maintenance when or which customers are
                    likely to close their accounts. </p>

                <div class="marketplace">
                    <img class="marketplace__img" src="/marketplace.jpg" alt=""/>
                    <div class="marketplace-desc">
                        <p class="marketplace-desc__text">On xendeck marketplace</p>
                        <p class="marketplace-desc__link">XENdeck Generative AI</p>
                    </div>
                </div>

                <h2 class="news-page__subtitle">
                    Generative AI Terms and Their Definitions </h2>
                <div class="news-page__text">
                    Before we dive into the benefits of generative AI for the enterprise, it's important to understand
                    the key terms in this field:
                    <br/><br/>
                    <ul class="news-page__list">
                        <li><span>Natural Language Processing (NLP):</span> A branch of AI that enables a computer to
                            understand, analyze, and generate human language.
                        </li>
                        <li><span>Transformer:</span> A neural network architecture (introduced by Google in 2017) that
                            can train significantly larger models on ever larger datasets than before. It allowed the
                            emergence of recent LLMs to process and learn from sequences of words, recognizing patterns
                            and relationships within the text.
                        </li>
                        <li><span>Large Language Model (LLM):</span> A deep learning model specialized in text
                            generation. Today almost all LLMs are based on the transformer architecture (with variations
                            from the original). As the name indicates, the real revolution is in the scale of these
                            models, the very large number of parameters (typically in the 10s to 100s of billions), and
                            the very large corpus of text used to train them.
                            <br/><br/>
                            <div class="px-[40px]">
                                <span>a.</span> Examples of commercial / closed-source models: OpenAI’s GPT3/3.5/4,
                                GCP’s PaLM, AWS’s Bedrock, Anthropic’s Claude, etc.
                                <br/><br/>
                                <span>b.</span> Examples of open-source models: Google FLAN-T5 & FLAN-UL2, MPT-7B,
                                Falcon, etc.
                            </div>
                        </li>
                        <li><span>Pre-trained Model / Foundational Model:</span> An LLM trained from scratch in a
                            self-supervised way (without labels) on a very large dataset and made available for others
                            to use. Foundational models are not intended to be used directly and need to be fine-tuned
                            for specific tasks.
                        </li>
                        <li><span>Fine-tuning:</span> The process of taking a pre-trained model and adapting it to a
                            specific task (summarization, text generation, question-answering, etc.) by training it in a
                            supervised way (with labels). Fine-tuning requires much less data and compute power than the
                            original pre-training. Well fine-tuned models often outperform much larger models.
                        </li>
                        <li><span>Hallucination:</span> A situation where the LLM generates a wrong output; the root
                            cause is that the LLM uses its internal “knowledge” (what it has been trained on) and that
                            knowledge is not applicable to the user query.
                        </li>
                        <li><span>Multi-modal AI:</span> a type of AI with the ability to process and understand inputs
                            from different types of inputs such as text, speech, images, and videos.
                        </li>
                        <li><span>Retrieval Model:</span> a system (typically a Transformer) used to retrieve data from
                            a source of information. Combining retrieval models with large language models partially
                            addresses the hallucination problem by anchoring the LLM in a known corpus of data.
                        </li>
                        <li><span>Vector Store:</span> a type of data store specialized in managing vectorial
                            representations of documents (known as embeddings), those stores are optimized to
                            efficiently find nearest neighbors (according to various distance metrics), they are central
                            architectural pieces of a Generative AI platform.
                        </li>
                        <li><span>Enterprise AI:</span> Providing predictive insights to expert users for improving
                            performance of enterprise processes and systems.
                        </li>
                        <li><span>Generative AI for Enterprise Search:</span> Leveraging generative AI’s intuitive
                            search bar to access the predictive insights and underlying data of enterprise AI
                            applications, improving decision-making for all enterprise users.
                        </li>
                    </ul>
                </div>

                <h2 class="news-page__subtitle">

                </h2>
                <p class="news-page__text">

                </p>

                <h2 class="news-page__subtitle">
                    Why Is Generative AI Transformational for the Enterprise? </h2>
                <p class="news-page__text">
                    The ability for generative AI to create high-quality, contextually relevant content—text, images,
                    videos—in a fraction
                    of the time it takes today, is transformational for a wide swath of businesses and specific
                    functions. Generative AI applications and use cases span practically all industries and
                    organizations across manufacturing, healthcare, energy, retail, transportation, government,
                    financial services, and so on.
                    <br/><br/>
                    With generative AI, marketers can rapidly create a broader set of personalized campaign content
                    without adding more writers; financial analysts can produce granular custom reports for executives
                    in minutes. Such advances will lead
                    to dramatic cost savings, better customer experiences, and increase sales velocity.
                    Those are just a few of the potential advantages.
                    <br/><br/>
                    Core to transforming a business is the positive impact generative AI can have on the enterprise
                    search experience. Imagine using a search engine to access exactly what you need within your
                    business, making it easy for users
                    to access the most pertinent information, portions of reports, and predictive analytics from your
                    enterprise data
                    and external systems. By making data, analytics, and predictions broadly available across an
                    organization through
                    an intuitive search bar—and not just to the data analysts in the company— generative AI can vastly
                    improve decision-making at every level in the organization. Suddenly, people throughout the ranks of
                    an enterprise can take advantage of this powerful AI technology, boosting efficiency, productivity
                    and, importantly, the ability to plan.
                    <br/><br/>
                    Take a machine operator as an example. Machinery operators typically monitor equipment performance
                    and manufacturing conditions at a control board. They are responsible for triaging alarms,
                    responding to urgent issues, and ensuring that operations safely and reliably meet production
                    targets and quality specifications. It’s a demanding role. As a result, operators do not have time
                    to read detailed manuals or aggregate information across systems
                    to identify trends in performance. Furthermore, many manufacturers face an aging workforce, where
                    deep expertise
                    is leaving the organization as operators retire.
                    <br/><br/>
                    Generative AI poses a unique opportunity to overcome these challenges. A large language model (LLM)
                    can be trained on a corpus of enterprise data – such as historical machine failures, work order
                    logs, inspections, production performance, and OEM operating manuals – to synthesize information and
                    make recommendations for less experienced operators.
                    <br/><br/>
                    While working directly from the control board, a machinery operator may ask a generative AI
                    application: “The conveyor belt on production line A is broken. How do I fix it?” The Generative AI
                    application will quickly return
                    the exact troubleshooting steps from the equipment’s Standard Operating Procedure (SOP) document,
                    along with additional commentary from recent work orders on the production line A conveyor belt.
                    <br/><br/>
                    With this generative AI application, lesser experienced operators instantly gain access to the
                    knowledge and experience of the operators who came and learned before them – without requiring
                    decades on the job.
                    The AI application synthesizes all relevant information and makes it available in a useful and easy
                    to grasp format,
                    all from entering a simple prompt into the enterprise search bar. The result is an operator new to
                    the job becomes more efficient, more effective, and can deliver better outcomes for the business.
                </p>
            </div>

            <div class="px-[100px] pb-[10px] mt-[110px] mq750:hidden">
                <div class="flex flex-row items-start justify-start relative max-w-full">
                    <img class="h-[95.5px] w-[1161px] absolute my-0 mx-[!important] right-[-206px] bottom-[-56.5px] object-contain"
                         loading="eager" alt="" src="/line-1@2x.png"/>
                </div>
                <div class="flex flex-row items-start justify-center pt-5 px-5 pb-[10px] box-border max-w-full text-23xl mb-[30px]">
                    <h1 class="m-0 h-[55px] relative text-inherit text-center font-bold font-inherit inline-block mq450:text-6xl mq1050:text-15xl w-3/4">
                        Many business functions can benefit from applications of generative AI </h1>
                </div>

                <tabs v-model="selectedTab">
                    <tab class="tab-news" v-for="(tab, i) in tabs" :key="`t${i}`" :val="tab" :label="tab"
                         :indicator="true"/>
                </tabs>
                <tab-panels v-model="selectedTab" :animate="true">
                    <tab-panel v-for="(tab, i) in tabs" :key="`tp${i}`" :val="tab">
                        <div class="tab-item">
                            <img class="tab-item__img" src="/img-tab-item.jpeg" alt="img-item"/>
                            <div class="tab-item-desc">
                                <div class="tab-item-desc__text">Generative AI can improve sales productivity by
                                    identifying
                                    the right opportunities to focus on; the technology can help
                                    boost conversion rates by generating personalized prospecting templates and sales
                                    scripts.
                                    <br/><br/>
                                    <span>Detailed Use Cases:</span> Generative AI can help enterprises
                                    <ul class="tab-item-desc__list">
                                        <li><span>Create sales emails</span> that are personalized and context specific.
                                            This will increase open rates and click-through rates, improving overall
                                            sales pipeline performance.
                                        </li>
                                        <li><span>Forecast sales and revenue</span> by analyzing data from a variety of
                                            sources, including historical sales data, market trends, and customer
                                            behavior. This can improve accuracy of sales projections and enable better
                                            planning of marketing and sales efforts.
                                        </li>
                                        <li><span>Improve sales reporting</span> by automatically summarizing notes and
                                            action items from transcribed customer interactions.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </tab-panel>
                </tab-panels>
                <div class="flex flex-row items-start justify-start relative max-w-full bottom-[130px]">
                    <img class="h-[95.5px] w-[1161px] absolute my-0 mx-[!important] right-[-206px] bottom-[-96.5px] object-contain"
                         loading="eager" alt="" src="/line-2-1@2x.png"/>
                </div>
            </div>


            <div class="px-[250px] pb-[100px] mq750:px-[15px] mq750:pb-[40px]">
                <h2 class="news-page__subtitle">
                    The Significance of Domain Models for Enterprises </h2>
                <p class="news-page__text">
                    A significant breakthrough in generative AI came with the development of transformer models—neural
                    networks that can learn the context of sequential data—to rapidly create high quality, comprehensive
                    responses to user queries. Transformer models were first described in an important Google research
                    paper in 2017 called “Attention Is All You Need,” and they have been advancing ever since.
                    <br/><br/>
                    The capabilities of generative AI have both amazed and scared the public, some of which detracts
                    from the opportunity for businesses with generative AI. Because the current versions of popular
                    generative AI models,
                    such as Chat GPT and Google Bard, have been trained on a wide body of publicly available data, they
                    lack the nuances required for specific domains, which is where domain models come in.
                    <br/><br/>
                    For a manufacturer or health care service to take advantage of generative AI, they need a system
                    that generates content—reports, analysis, etc. — based on data relevant to their domains, including
                    such data sets as terms specific to a company. Most of an enterprise’s specific data is proprietary,
                    sensitive, and sits behind a firewall.
                    As such, applying generative AI to the enterprise requires domain models.
                    <br/><br/>
                    Domain models combine the fluency and comprehensibility of emerging transformers with the
                    domain-specific data and knowledge of an enterprise. With access to the corpus of data, best
                    practices, and policies specific to an enterprise, generative AI models can, for example, answer
                    inventory-related search queries for supply chain analysts, create brand-compliant graphics for
                    marketing managers, and identify potential compliance exceptions in new contracts.
                    <br/><br/>
                    Domain models will generate the greatest value for enterprises by finding contextually relevant and
                    policy-compliant responses to user queries. Sophisticated domain models have the greatest value
                    potential for enterprises by not just tapping into existing enterprise data, but by calculating
                    metrics and analyzing that data to produce value-added content for enterprise users.
                    <br/><br/>
                    Supply chain executives trying to identify how well their company is fulfilling customer orders will
                    be less interested
                    in looking at each order’s fulfillment history. Rather, they’d want to see On-Time-In-Full (OTIF)
                    history by product line, customer, or region. The ability of domain models not just to find and
                    regurgitate data, but to combine data and create business-relevant metrics analysis that are
                    predictive in nature is where generative AI models can become invaluable to enterprise users. </p>
                <div class="news-page__placeholder-img">
                    <img src="/place-img.jpg" alt="place-img"/>
                </div>
                <div class="news-page__text">
                    Domain models also build upon existing analytical frameworks and business logic to find correlations
                    between data, identifying cause-and-effect and generating analytic-backed insights. In the above
                    example of a supply chain executive investigating service levels, domain models will not just
                    produce an OTIF report, but also an analysis
                    of potential drivers of poor OTIF, caused perhaps by a specific transportation provider or linked to
                    the timing of orders relative to the planning cycle of the company.
                    <br/><br/>
                    Domain models will be able to support enterprise users not just in analyzing the past but in taking
                    proactive actions
                    by using predictive insights. The supply chain executive in the above scenario will be supported by
                    their domain generative model by identifying the future orders with the highest risk of delays along
                    with explanations of the drivers of each order’s risks.
                    <br/><br/>
                    Enterprises leveraging domain generative models will capture outsized value not just by effortlessly
                    generating insights for their users, but by also giving them the ability to act on those insights.
                    By integrating generative AI capabilities with personal productivity tools and enterprise
                    applications, companies will give their users the ability to:
                    <ul class="news-page__list">
                        <li>Take personal actions such as generating reports or setting alerts</li>
                        <li>Initiate collaborative actions by kicking off a new project or create the agenda of a group
                            meeting
                        </li>
                        <li>Kickoff workflows in enterprise applications such as accelerating supply orders, creating
                            support tickets, or generating a customer discount.
                        </li>
                    </ul>
                    <br/><br/>
                    Let’s get back to our supply chain executive investigating customer delivery fulfillment. Using
                    domain generative models, the executive can identify past track records and risks to future
                    deliveries, as well as making changes
                    to production schedules and transportation arrangements to expedite critical orders at risk of
                    delay.
                </div>
                <h2 class="news-page__subtitle">
                    Key Considerations for Adopting Generative AI in the Enterprise </h2>
                <div class="news-page__text">
                    Organizations looking to adopt generative AI technologies for enterprise use cases stand to gain a
                    major productivity boost. Yet as with many leading-edge technologies, several factors exist that
                    business leaders should consider when choosing a generative AI technology partner.
                    <br/><br/>
                    <div class="news-page__paragraph-title">
                        Data Availability and Quality
                    </div>
                    Generative AI domain models require large amounts of data to be trained effectively. Difficulty in
                    obtaining or collecting relevant data, particularly for specialized or rare situations can limit the
                    scope and effectiveness of the model. Furthermore, limited availability of high-quality data can
                    lead to poor performance and accuracy of the model. Consequently, enterprises should consider both
                    the availability and quality of data to train and operate generative AI systems.
                    <br/><br/>
                    <div class="news-page__paragraph-title">
                        Security and Privacy Risks
                    </div>
                    In addition to availability and quality of data, unauthorized use of sensitive data for training or
                    operating the model can also expose the enterprise to security and privacy risks. Vulnerabilities in
                    the model architecture could be exploited
                    by hackers to gain access to sensitive data and cause harm to individuals and enterprises. Moreover,
                    lack of transparency and accountability in data handling and use could lead to potential regulatory
                    noncompliance.
                    <br/><br/>
                    <div class="news-page__paragraph-title">
                        Intellectual Property Risks
                    </div>
                    With public-facing generative AI systems, enterprises may be exposed to intellectual property
                    infringement risks.
                    By design, public generative AI systems are trained on internet data. Consequently, generated
                    content similar
                    to existing works that are protected by intellectual property laws could lead to legal actions and
                    potential financial damages. Ongoing debate around the ownership and authorship of generated content
                    from public generative AI systems could lead to legal disputes and challenges.
                    <br/><br/>
                    <div class="news-page__paragraph-title">
                        Bias and Inaccuracy
                    </div>
                    As the generative AI model is trained on available data, bias and inaccuracy in the training data
                    can be perpetuated
                    or even amplified in the output of the model, leading to discriminatory, legally risky, or unsafe
                    outcomes. Furthermore, inaccurate answers due to outdated or irrelevant data or model hallucination
                    can lead to unintended consequences and exacerbate an existing issue.
                    <br/><br/>
                    <div class="news-page__paragraph-title">
                        Domain Models
                    </div>
                    With a customized generative AI model trained on the enterprise’s own data and deployed on top of
                    enterprise data and applications, enterprises can reap the benefits of generative AI systems while
                    minimizing risks.
                    <br/><br/>
                    First, many enterprises likely have a wealth of high-quality data that is subject to rigorous data
                    management practices and quality checks. Secondly, enterprise data is often subject to access
                    controls and security measures, which can help prevent unauthorized access to sensitive data.
                    Moreover, as enterprise data is typically owned by the organization, generating content based on
                    enterprise’s own data eliminates intellectual property rights risks. Diverse, representative, and
                    high-quality enterprise data can also help minimize bias in model outputs. Finally, while limiting
                    hallucinations is an ongoing research area, generative AI systems that have a built-in feedback loop
                    with human evaluation, such as the XENdeck Generative AI Product Suite, will be able to eliminate
                    hallucination and inaccuracy over time.
                </div>


                <h2 class="news-page__subtitle">
                    The Future of Generative AI in the Enterprise </h2>
                <p class="news-page__text">
                    Advances in technology are once again promising to transform business and society – and this time
                    it’s happening
                    at hyper speed. Generative AI presents a new epoch of human efficiency and effectiveness, affecting
                    society and industry in ways we have yet to understand. When we look back, as discussed above, this
                    moment will be as groundbreaking as rollout of the personal computer and introduction of the Apple
                    iPhone.
                    <br/><br/>
                    With generative AI solutions, business leaders will drive their organizations to previously
                    unattainable and unthinkable speed and scale of execution. Companies will automate and optimize
                    business processes, generate novel insights, and deliver new customer experiences faster than ever
                    before.
                    <br/><br/>
                    While the general public has become enraptured by charming and relatable examples – from writing
                    puns to creating a photo of an astronaut riding a horse – business leaders need to focus on
                    well-scoped problems that generate economic value.
                    <br/><br/>
                    Developing and implementing generative AI technology for business transformation requires a
                    thoughtful and deliberate approach. First, business leaders must identify tractable use cases where
                    the solution will be useful,
                    drive value, and where stakeholders agree to measurable success metrics.
                    <br/><br/>
                    Tractable use cases include automating repetitive tasks, or synthesizing insights from unstructured
                    data and documentation. This could be achieved through streamlining high-volume tasks to save time,
                    or unlocking once
                    all-but-impossible to access information deep in documentation and disparate data sources. Success
                    metrics will measure the business impact, and could be tied to economic benefit, customer service,
                    sustainability outcomes,
                    or business efficiencies.
                    <br/><br/>
                    As you can see, all this is leading to major changes within a given enterprise. To scale these
                    technologies and solutions, leaders will need to evaluate existing workflows of people who already
                    perform job functions generative AI can transform. Successful enterprise implementations of
                    generative AI will embed solutions into existing customer
                    and employee workflows, rather than serve as standalone tools.
                    <br/><br/>
                    The simplicity of using generative AI—for a consumer or a business user—should make adoption a
                    non-issue.
                    The possibilities really are limitless, and enterprise adoption should occur in months rather than
                    years, ultimately making generative AI a moment in technology history whose significance will soon
                    be undeniable – for enterprises everywhere. </p>
            </div>
        </div>
        <div class="flex flex-row items-start justify-start relative max-w-full">
            <img class="h-[79.5px] w-[1161px] absolute my-0 mx-[!important] right-[-206px] bottom-[-56.5px] object-contain mq750:w-[450px]"
                 loading="eager" alt="" src="/line-19-1@2x.png"/>
        </div>
        <div class="flex flex-row items-start justify-start pt-5 px-5 pb-[72px] box-border max-w-full text-23xl mq750:pb-[20px]">
            <h1 class="mq750:mt-[30px] m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1050:text-15xl mq750:h-[25px]">
                Related Resources </h1>
        </div>
        <div class="w-[1240px] grid flex-row items-start justify-start pt-0 px-5 pb-[106px] box-border gap-[20px] max-w-full shrink-0 grid-cols-[repeat(3,_minmax(300px,_1fr))] mq750:pb-[69px] mq750:box-border mq750:grid-cols-[minmax(300px,_1fr)] mq1050:justify-center mq1050:grid-cols-[repeat(2,_minmax(300px,_520px))]">
            <div class="flex flex-col items-start justify-start max-w-full">
                <img class="self-stretch h-[229px] relative rounded-8xs max-w-full overflow-hidden shrink-0 object-cover"
                     alt="" src="/news-1.jpeg"/>
                <div class="self-stretch flex flex-col items-center py-[23px] px-3.5 box-border relative gap-[55px] mq450:gap-[55px] mt-3 bg-gray min-h-[96px] justify-between">
                    <b class="self-stretch h-[26px] relative inline-block z-[2] mq450:text-base">Generative AI is about
                        to upend enterprise software and cybersecurity</b>
                    <div class="self-stretch flex flex-row items-start justify-between py-0 px-px gap-[20px] text-base text-grey mq450:flex-wrap">
                        <div class="relative z-[2]">ARTICLE</div>
                        <div class="relative z-[2]">VIEW</div>
                    </div>
                    <div class="w-full h-full absolute my-0 mx-[!important] top-[0px] right-[0px] bottom-[0px] left-[0px]"></div>
                </div>
            </div>
            <div class="flex flex-col items-start justify-start max-w-full">
                <img class="self-stretch h-[229px] relative rounded-8xs max-w-full overflow-hidden shrink-0 object-cover"
                     alt="" src="/news-2.jpeg"/>
                <div class="self-stretch flex flex-col items-center py-[23px] px-3.5 box-border relative gap-[55px] mq450:gap-[55px] mt-3 bg-gray min-h-[96px] justify-between">
                    <b class="self-stretch h-[26px] relative inline-block z-[2] mq450:text-base">Generative AI is about
                        to upend enterprise software and cybersecurity</b>
                    <div class="self-stretch flex flex-row items-start justify-between py-0 px-px gap-[20px] text-base text-grey mq450:flex-wrap">
                        <div class="relative z-[2]">ARTICLE</div>
                        <div class="relative z-[2]">VIEW</div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col items-start justify-start max-w-full">
                <img class="self-stretch h-[229px] relative rounded-8xs max-w-full overflow-hidden shrink-0 object-cover"
                     alt="" src="/news-3.jpeg"/>
                <div class="self-stretch flex flex-col items-center pt-[23px] px-3.5 pb-[23px] relative bg-gray mt-3 gap-[29px] min-h-[96px] justify-between">
                    <b class="self-stretch relative z-[2] mq450:text-base">XENdeck Generative AI Product Suite</b>
                    <div class="self-stretch flex flex-row items-start justify-between py-0 px-px gap-[20px] text-base text-grey mq450:flex-wrap">
                        <div class="relative z-[2]">ARTICLE</div>
                        <div class="relative z-[2]">VIEW</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-row items-start justify-start relative max-w-full">
            <img class="h-[79.5px] w-[1161px] absolute my-0 mx-[!important] right-[-206px] bottom-[-56.5px] object-contain mq750:w-[450px]"
                 loading="eager" alt="" src="/line-19-1@2x.png"/>
        </div>
        <div class="mq750:mt-[30px] flex flex-row items-start justify-start pt-5 px-5 pb-[72px] box-border max-w-full text-23xl mq750:pb-[20px]">
            <h1 class="m-0 h-[55px] relative text-inherit font-bold font-inherit inline-block mq450:text-6xl mq1050:text-15xl mq750:h-[25px]">
                Take the next step </h1>
        </div>
        <div class="w-[1240px] flex flex-row flex-wrap items-start justify-start pt-0 px-5 pb-[76px] box-border gap-[50px] max-w-full shrink-0 text-base mq750:gap-[50px] mq750:pb-[45px]">
            <FrameAboutUsNews contactSales="Contact Sales"
                              learnHowOurIndustryLeadin="Learn how our industry-leading XENdeck AI software products can help your organization."/>
            <FrameAboutUsNews contactSales="Go to Investor Relations"
                              learnHowOurIndustryLeadin="Contact us at support@xendeck.ai to learn more about investing at XENdeck."/>
            <FrameAboutUsNews contactSales="request information"
                              learnHowOurIndustryLeadin="For all other questions, please contact us here."/>
        </div>
    </div>
</template>

<script>
import {defineComponent, reactive, toRefs} from "vue";
import NewsSelect from "@/components/NewsSelect.vue";
import FrameAboutUsNews from "@/components/FrameAboutUsNews.vue";
import {Tabs, Tab, TabPanels, TabPanel} from 'vue3-tabs';

const tabs = [
    'Sales',
    'Marketing',
    'Manufacturing Operations',
    'Supply Chain',
    'Legal',
    'Finance',
    'HR',
    'IT'
];
export default defineComponent({
    name: "NewsItem",
    components: {Tabs, Tab, TabPanels, TabPanel, FrameAboutUsNews, NewsSelect},
    props: {
        content: {type: String}
    },
    setup() {
        const state = reactive({
            selectedTab: tabs[1]
        });
        return {
            tabs,
            ...toRefs(state),
            selectOptions: [
                'Generative AI for Business',
                'What is Generative AI?',
                'What Is Unique About Generative AI?',
                'Generative AI Terms and Their Definitions',
                'Why Is Generative AI Transformational for the Enterprise?',
                'Many business functions can benefit from applications of generative AI',
                'The Significance of Domain Models for Enterprises',
                'Key Considerations for Adopting Generative AI in the Enterprise',
                'The Future of Generative AI in the Enterprise',
                'Related Resources',
                'Take the next step',
            ]
        };
    }
});


</script>

<style lang="scss" scoped>
.news-page {
  &__subtitle {
    font-size: 42px;
    line-height: 55px;
    font-weight: 700;
    color: #ffffff;
    margin: 0 0 20px;
    @media (max-width: 750px) {
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 10px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    color: #ffffff;
    margin: 0 0 20px;
    @media (max-width: 750px) {
      font-size: 14px;
      line-height: 16px;
      margin: 0 0 15px;
    }
  }

  &__list {
    list-style-type: disc;

    li {
      margin-bottom: 10px;
    }

    li span {
      font-weight: 700;
    }
  }

  &__placeholder-img {
    img {
      max-width: 100%;
    }
  }

  &__paragraph-title {
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    text-transform: uppercase;
    color: #A2A2A2;
    @media (max-width: 750px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.marketplace {
  height: 190px;
  display: flex;
  margin: 60px 65px 80px;
  border: 6px solid #212135;
  @media (max-width: 750px) {
    height: 85px;
    margin: 40px 15px;
    border: 3px solid #212135;
  }

  &-desc {
    padding: 50px 41px;
    border-left: 6px solid #212135;
    @media (max-width: 750px) {
      padding: 10px 8px;
      border-left: 3px solid #212135;
    }

    &__text {
      margin: 0 0 17px;
      line-height: 23px;
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      @media (max-width: 750px) {
        line-height: 14px;
        font-size: 12px;
      }
    }

    &__link {
      margin: 0;
      line-height: 46px;
      font-size: 36px;
      font-weight: 700;
      @media (max-width: 750px) {
        line-height: 21px;
        font-size: 16px;
      }
    }
  }

  &__img {
    width: 220px;
    height: 190px;
    @media (max-width: 750px) {
      width: 80px;
      height: 80px;
    }
  }
}

.tab-item {
  height: 440px;
  display: flex;
  margin-top: 30px;
  margin-bottom: 100px;
  justify-content: center;

  &-desc {
    padding-left: 38px;

    &__text {
      margin: 0 0 15px;
      line-height: 24px;
      font-size: 18px;
      font-weight: 400;
    }

    &__link {
      margin: 0;
      line-height: 46px;
      font-size: 36px;
      font-weight: 700;
    }

    &__img {
      width: 220px;
      height: 190px;
    }

    &__list {
      list-style-type: disc;

      li {
        margin-bottom: 10px;
      }

      li span {
        font-weight: 700;
      }
    }
  }
}

.tabs {
  justify-content: center;
}

.tab-news {
  padding: 0 5px !important;
  border-width: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #A2A2A2;
  color: #A2A2A2;
  text-transform: uppercase;
  cursor: pointer;
}

.active {
  border-width: 0;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #FFFFFF !important;
  color: #FFFFFF !important;

}
</style>

<style lang="scss">
.news-page__subtitle {
    font-size: 42px;
    line-height: 55px;
    font-weight: 700;
    color: #ffffff;
    margin: 0 0 20px;
    @media (max-width: 750px) {
        font-size: 24px;
        line-height: 32px;
        margin: 0 0 10px;
    }
}

.news-page__text {
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    color: #ffffff;
    margin: 0 0 20px;
    @media (max-width: 750px) {
        font-size: 14px;
        line-height: 16px;
        margin: 0 0 15px;
    }
}

.news-page__list {
    list-style-type: disc;

    li {
        margin-bottom: 10px;
    }

    li span:not(.font-light) {
        font-weight: 700;
    }

    li span.font-light {
        color: #ffffffc2;
        font-size: 18px;
    }
}

.news-select-wrapper {
    z-index: 999999999;
    .selected {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media (max-width: 750px) {

    .news-select-wrapper {
        top: 80px !important;
        border-left: 25px solid #23232e;
        border-right: 15px solid #23232e;
        border-top: 10px solid #23232e;
        border-bottom: 20px solid #23232e;
        box-sizing: border-box;
        padding: 0 20px;
        padding-top: 20px;
        padding-bottom: 10px;
        background: #2d2d3c;


        .custom-select {
            width: 100%;
            margin: 0;
        }

        .selected {
            padding: 10px;
            background: #2d2d3c !important;
            margin-right: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .items {
            background: #2d2d3c !important;
            margin-left: -20px;
            width: calc(100% - 20px) !important;
        }

        .ham3 {
            top: 50%;
            margin-top: -25px;
            right: -10px;
        }
    }

}
</style>